<template>
  <div
    class="to-top-button"
    @click="scrollTop"
  >
    <img
      loading="lazy"
      :src="require(`../assets/img/icons/arrow-down.svg`)"
      alt="data.name"
    />
  </div>
</template>

<script>
import { onMounted } from "vue";
export default {
  name: "ToTopButton",
  setup() {
    const scrollTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleScroll = () => {
      const triggerHeight = window.innerHeight * 1.5; // 150vh
      const fixedCartButton = document.querySelector(".fixed-cart-button.visible");
      const toTopButton = document.querySelector(".to-top-button");

      if (!triggerHeight || !toTopButton) return;

      if (window.scrollY > triggerHeight) {
        toTopButton.classList.add("visible");
      } else {
        toTopButton.classList.remove("visible");
      }

      if (fixedCartButton) {
        toTopButton.style.bottom = `${fixedCartButton.offsetHeight}px`;
      } else {
        toTopButton.style.bottom = "0";
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
      handleScroll();
    });
    return { scrollTop };
  },
};
</script>

<style lang="scss">
.to-top-button {
  @extend %box-shadow-small;

  cursor: pointer;
  position: fixed;
  z-index: 9;
  bottom: 0px;
  right: 10px;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: $grey;
  @media (max-width: 767px) {
    width: 35px;
    height: 35px;
  }
  @media (min-width: 768px) {
    width: 50px;
    height: 50px;
  }
  transform: translateY(200%);
  transition: transform 0.5s ease, bottom 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    transform: rotate(180deg);
  }
  &.visible {
    transform: translateY(0);
  }
}
</style>
