export default {
  // User
  SET_PRODUCTS_USER: (state, data) => {
    state.products_user = data.sort((a, b) => {
      if (a.productId == "1") return -1;
      if (b.productId == "1") return 1;
      if (a.productId == "6") return -1;
      if (b.productId == "6") return 1;
      return b.productId.localeCompare(a.productId);
    });
  },
  SET_CATEGORIES_USER: (state, data) => {
    // todo
    const uselessCategories = [6, 7];
    //  const uselessCategories = [4, 6, 7];
    state.categories_user = data.filter((el) => uselessCategories.every((item) => item != el.id));
  },

  // Admin
  SET_PRODUCTS_ADMIN: (state, data) => {
    const idsToShow = [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 35];
    data = data.filter((item) => idsToShow.some((id) => id == item.productId));
    state.products_admin = data;
    if (state.promocode?.bonus) {
      state.products_admin.forEach((product) => {
        if (+state.promocode.bonus > +product.discountAmount && product.category != 6) {
          if (product.category != 4) {
            product.discountAmount = +state.promocode.bonus;
          } else if (state.promocode_excecptions.some((el) => el == state.promocode.id)) {
            product.discountAmount = +state.promocode.bonus;
          }
        } else if (product.category == 6) {
          product.discountAmount = 0;
        }
      });
    }
  },
  SET_CATEGORIES_ADMIN: (state, data) => {
    const uselessCategories = [6, 7];
    state.categories_admin = data.filter((el) => uselessCategories.every((item) => item != el.id));
  },
  SET_PROMOCODES_ADMIN: (state, data) => {
    state.promocodes = data;
  },
  SET_PROMO_ADMIN: (state, data) => {
    state.promo = data;
  },
  SET_UTM_SOURCE_ADMIN: (state, data) => {
    state.utm_source = data;
  },
  SET_UTM_MEDIUM_ADMIN: (state, data) => {
    state.utm_medium = data;
  },
  SET_UTM_CAMPAIGN_ADMIN: (state, data) => {
    state.utm_campaign = data;
  },
  SET_UTM_CONTENT_ADMIN: (state, data) => {
    state.utm_content = data;
  },
  SET_UTM_TERM_ADMIN: (state, data) => {
    state.utm_term = data;
  },
  SET_ADSET_ADMIN: (state, data) => {
    state.adset = data;
  },
};
