<template>
  <li class="cart-item">
    <div class="cart-item__image-wrapper">
      <div class="cart-item__image">
        <img
          loading="lazy"
          :src="`/img/products/${props_data.productId}/1.webp`"
          :alt="props_data.title"
        />
        <!-- :src="require(`../assets/img/products/${props_data.productId}/compressed/1.webp`)" -->
      </div>
    </div>
    <div class="cart-item__body">
      <h3 class="cart-item__name">
        {{ props_data.title }}
        <template v-if="props_data.productId == 11"> ({{ $t("female") }}) </template>
        <template v-else-if="props_data.productId == '35'"> ({{ $t("male") }}) </template>
      </h3>
      <div class="cart-item__amount">
        <button
          type="button"
          @click.stop="decrement"
          class="cart-item__ic cart-item__ic_minus"
          :class="{ disabled: props_data.quantity == 1 }"
        ></button>
        <p class="cart-item__quantity">{{ props_data.quantity }}</p>
        <button
          type="button"
          @click.stop="increment"
          class="cart-item__ic cart-item__ic_plus"
        ></button>
      </div>
      <img
        loading="lazy"
        class="cart-item__close"
        @click.stop="deleteItem"
        :src="require(`../assets/img/icons/close.svg`)"
        alt="close"
      />
      <div class="cart-item__prices">
        <p
          class="cart-item__price cart-item__price_full"
          :class="{
            old:
              +props_data.discountAmount[props_data.quantity - 1] ||
              (props_data.quantity >= 3 && +props_data.discountAmount[2]),
          }"
        >
          {{ props_data.fullPrice * props_data.quantity }} ₴
        </p>

        <p
          class="cart-item__price cart-item__price_discount"
          v-if="
            +props_data.discountAmount[props_data.quantity - 1] ||
            (props_data.quantity >= 3 && +props_data.discountAmount[2])
          "
        >
          {{
            count(
              props_data.fullPrice * props_data.quantity,
              props_data.quantity >= 3
                ? +props_data.discountAmount[2]
                : +props_data.discountAmount[props_data.quantity - 1]
            )
          }}
          ₴
        </p>
      </div>
    </div>
  </li>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { ref } from "vue";
import { count } from "@/utils/utils";
export default {
  name: "CartItem",
  props: {
    props_data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const LOCALE = computed(() => store.getters.LOCALE);
    const deleteItem = () => {
      store.commit("DELETE_ITEM_FROM_CART", ref(props.props_data).value);
      emit("deleteItem");
    };
    const decrement = () => {
      store.commit("DECREMENT_CART_ITEM", ref(props.props_data).value);
    };
    const increment = () => {
      store.commit("INCREMENT_CART_ITEM", ref(props.props_data).value);
    };
    return {
      LOCALE,
      deleteItem,
      decrement,
      increment,
      count,
    };
  },
};
</script>
<style lang="scss">
.cart-item {
  &:not(:last-child) {
    margin-bottom: 50px;
  }
  display: grid;
  grid-template-columns: 70px 1fr;
  @media (min-width: 420px) and (max-width: 767px) {
    grid-template-columns: 90px 1fr;
  }
  @media (min-width: 768px) {
    grid-template-columns: 120px 1fr;
  }
  &.smaller {
    @media (min-width: 420px) and (max-width: 767px) {
      grid-template-columns: 90px 1fr;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  column-gap: 10px;
  @media (min-width: 420px) {
    column-gap: 20px;
  }
  //   align-items: start;
  //   align-items: center;
  // .cart-item__image-wrapper

  &__image-wrapper {
    align-self: start;
    width: 100%;
  }
  // .cart-item__image

  &__image {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      border-radius: 5px;
      @media (min-width: 420px) {
        border-radius: 10px;
      }
      @extend %box-shadow-small;
    }
  }

  // .cart-item__body

  &__body {
    align-self: center;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 10px;
    row-gap: 5px;
    @media (min-width: 420px) {
      column-gap: 20px;
      row-gap: 10px;
    }
  }

  // .cart-item__name

  &__name {
    grid-column: 1/2;
    grid-row: 1/2;
    align-self: start;
  }

  .male-buttons {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  // .cart-item__amount

  &__amount {
    grid-column: 1/2;
    grid-row: 3/4;
    align-self: end;
    display: flex;
    align-items: center;
    column-gap: 2px;
    @media (min-width: 420px) {
      column-gap: 5px;
    }
  }

  // .cart-item__ic

  &__ic {
    cursor: pointer;
    width: 22px;
    height: 22px;
    @media (min-width: 420px) {
      width: 30px;
      height: 30px;
    }
    background-color: $mainColor;
    border-radius: 50%;
    @extend %box-shadow-small;
    &.disabled {
      // visibility: hidden;
      user-select: none;
      pointer-events: none;
      background-color: $greyDarken;
      opacity: 0.3;
      &:after {
        background-color: #000;
      }
    }
    &_plus,
    &_minus {
      position: relative;
      &:after,
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40%;
        height: 2px;
        border-radius: 2px;
        background-color: #fff;
      }
      &:after {
        transform: translate(-50%, -50%);
      }
    }
    &_minus {
      &:before {
        display: none;
      }
    }
    &_plus {
      &:before {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }

  // .cart-item__quantity

  &__quantity {
    font-variation-settings: "wght" 700;
    min-width: 20px;
    @media (min-width: 420px) {
      min-width: 30px;
      font-size: 1.3rem;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }

  // .cart-item__close

  &__close {
    cursor: pointer;
    width: 14px;
    @media (min-width: 420px) {
      width: 14px;
    }
    grid-column: 2/3;
    grid-row: 1/2;
    align-self: start;
    justify-self: end;
  }

  // .cart-item__prices

  &__prices {
    display: flex;
    flex-direction: column;
    grid-column: 2/3;
    grid-row: 3/4;
    align-self: end;
    justify-self: end;
    font-size: 0.8rem;
    @media (min-width: 420px) {
      font-size: 1rem;
    }
  }

  // .cart-item__price

  &__price {
    white-space: nowrap;
    // .cart-item__price_full

    &_full {
      font-variation-settings: "wght" 700;
      &.old {
        font-variation-settings: "wght" 500;
        font-size: 0.8em;
        //   text-decoration: line-through;
        position: relative;
        max-width: max-content;
        margin-left: auto;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-5deg);
          width: 100%;
          height: 2px;
          background: $discount;
        }
      }
    }

    // .cart-item__price_discount

    &_discount {
      color: $discount;
      font-variation-settings: "wght" 700;
      font-size: 1.1em;
    }
  }
}
</style>
