<template>
  <nav
    class="mobile-menu"
    :style="{ height: menuHeight + 'px' }"
  >
    <div class="mobile-menu__wrapper">
      <div class="mobile-menu__body">
        <div
          class="mobile-menu__main mobile-menu-main"
          v-show="isMainMenuVisible"
        >
          <div class="mobile-menu-main__head">
            <div class="mobile-menu-main__langs">
              <router-link
                v-for="lang in SUPPORTED_LANGUAGES"
                :key="lang"
                :to="localeLink(lang)"
                @click.prevent="changeLocale(lang)"
                :class="{ selected: lang === LOCALE }"
              >
                {{ lang == "ru" ? "Рус" : lang == "en" ? "Eng" : "Укр" }}
              </router-link>
            </div>
            <router-link
              v-if="currentRouteName !== 'home'"
              :to="homeLink"
              class="mobile-menu-main__button mobile-menu-main__button_home"
            >
              {{ $t("mobileMenu.5") }}
            </router-link>
            <button
              @click="showCatalogMenu"
              class="mobile-menu-main__button mobile-menu-main__button_catalog"
            >
              <span>{{ $t("mobileMenu.1") }}</span>
              <img
                loading="lazy"
                :src="require(`../assets/img/icons/arrow-down.svg`)"
                alt="data.name"
              />
            </button>
            <button
              @click="showInfoMenu"
              class="mobile-menu-main__button mobile-menu-main__button_info"
            >
              <span>{{ $t("mobileMenu.2") }}</span>
              <img
                loading="lazy"
                :src="require(`../assets/img/icons/arrow-down.svg`)"
                alt="data.name"
              />
            </button>
          </div>
          <div class="mobile-menu-main__bottom">
            <a
              class="mobile-menu-main__mail"
              mailto="vitaisland.com.ua@gmail.com"
            >
              VitaIsland.com.ua@gmail.com
            </a>
            <p class="mobile-menu-main__copyright">© 2023 VitaIsland.com.ua / {{ $t("mobileMenu.3") }}</p>
          </div>
        </div>
        <div
          class="mobile-menu__catalog mobile-menu-catalog"
          v-show="isCatalogMenuVisible"
        >
          <div class="mobile-menu-catalog__head">
            <button
              class="mobile-menu-catalog__button"
              @click="showMainMenu"
            >
              <img
                loading="lazy"
                :src="require(`../assets/img/icons/arrow-down.svg`)"
                alt="data.name"
              />
            </button>
            <router-link
              class="mobile-menu-catalog__head-link"
              :to="allProductsLink"
            >
              {{ $t("mobileMenu.1") }}
            </router-link>
          </div>
          <div class="mobile-menu-catalog__links">
            <router-link
              :to="allProductsLink"
              :class="{ selected: currentCategory === 'all' }"
            >
              {{ $t("mobileMenu.4") }}
            </router-link>
            <router-link
              v-for="category in filteredCategories"
              :key="category.name_en"
              :to="categoryLink(category.name_en)"
              :class="{ selected: currentCategory === category.name_en }"
            >
              {{ category.name }}
            </router-link>
            <router-link
              v-if="isSaleLink"
              :to="saleLink"
              :class="{ selected: currentCategory === 'sale' }"
            >
              {{ $t("mobileMenu.10") }}
            </router-link>
          </div>
        </div>
        <div
          class="mobile-menu__info mobile-menu-info"
          v-show="isInfoMenuVisible"
        >
          <div class="mobile-menu-info__head">
            <button
              class="mobile-menu-info__button"
              @click="showMainMenu"
            >
              <img
                loading="lazy"
                :src="require(`../assets/img/icons/arrow-down.svg`)"
                alt="data.name"
              />
            </button>
            <p class="mobile-menu-info__head-text">{{ $t("mobileMenu.2") }}</p>
          </div>
          <div class="mobile-menu-info__links">
            <router-link
              v-for="(item, index) in menuList"
              :key="index"
              :to="item.to"
              :class="{ disabled: item.to.name == route.name }"
            >
              {{ item.label }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { useStore } from "vuex";
import { computed, onMounted, ref, onUpdated } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { queryParams } from "@/utils/utils";
export default {
  name: "MobileMenu",
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const { locale, t } = useI18n();

    const LOCALE = computed(() => store.getters.LOCALE);
    const PRODUCTS_USER = computed(() => store.getters.PRODUCTS_USER);
    const CATEGORIES_USER = computed(() => store.getters.CATEGORIES_USER);
    const SUPPORTED_LANGUAGES = computed(() => store.getters.SUPPORTED_LANGUAGES);
    const IS_LANDSCAPE = computed(() => store.getters.IS_LANDSCAPE);

    const currentRouteName = computed(() => route.name);
    const currentCategory = computed(() => route.query.category);

    const menuHeight = ref(0);
    const countHeight = () => {
      const headerHeight = document.querySelector(".header").getBoundingClientRect().height;
      menuHeight.value = window.screen.height - headerHeight;
      // menuHeight.value = window.innerHeight - headerHeight;
    };

    const localeLink = (lang) => ({
      name: route.name,
      query: {
        ...route.query,
      },
      params: { locale: lang },
    });

    const homeLink = computed(() => ({
      name: "home",
      query: {
        ...queryParams(route.query, ["gender", "category", "order_id", "affiliation", "page"]),
      },
      params: { locale: LOCALE.value },
    }));

    const allProductsLink = computed(() => ({
      name: "catalog",
      query: {
        ...queryParams(route.query, ["gender", "order_id", "affiliation"]),
        category: "all",
        page: 1,
      },
      params: { locale: LOCALE.value },
    }));
    const isSaleLink = computed(() => {
      return PRODUCTS_USER.value.some((el) => el.discountAmount[0] > 0);
    });
    const saleLink = computed(() => ({
      name: "catalog",
      query: {
        ...queryParams(route.query, ["gender", "order_id", "affiliation"]),
        category: "sale",
        page: 1,
      },
      params: { locale: LOCALE.value },
    }));
    const filteredCategories = computed(() => {
      return CATEGORIES_USER.value.filter((category) => category.id != 6 && category.id != 7);
    });
    const categoryLink = (categoryName) => ({
      name: "catalog",
      query: {
        ...queryParams(route.query, ["gender", "order_id", "affiliation"]),
        category: categoryName,
        page: 1,
      },
      params: { locale: LOCALE.value },
    });
    const menuList = [
      {
        label: t("mobileMenu.6"),
        to: {
          name: "policy",
          query: {
            ...queryParams(route.query, ["gender", "category", "order_id", "affiliation", "page"]),
          },
          params: { locale: LOCALE.value },
        },
      },
      {
        label: t("mobileMenu.7"),
        to: {
          name: "terms",
          query: {
            ...queryParams(route.query, ["gender", "category", "order_id", "affiliation", "page"]),
          },
          params: { locale: LOCALE.value },
        },
      },
      {
        label: t("mobileMenu.8"),
        to: {
          name: "refund",
          query: {
            ...queryParams(route.query, ["gender", "category", "order_id", "affiliation", "page"]),
          },
          params: { locale: LOCALE.value },
        },
      },
      {
        label: t("mobileMenu.9"),
        to: {
          name: "contacts",
          query: {
            ...queryParams(route.query, ["gender", "category", "order_id", "affiliation", "page"]),
          },
          params: { locale: LOCALE.value },
        },
      },
    ];

    const changeLocale = (lang) => {
      if (LOCALE.value !== lang) {
        store.commit("SET_LOCALE", lang);
        locale.value = lang;
      }
    };

    const isMainMenuVisible = ref(true);
    const isCatalogMenuVisible = ref(false);
    const isInfoMenuVisible = ref(false);
    const showMainMenu = () => {
      isMainMenuVisible.value = true;
      isCatalogMenuVisible.value = false;
      isInfoMenuVisible.value = false;
    };
    const showCatalogMenu = () => {
      isCatalogMenuVisible.value = true;
      isMainMenuVisible.value = false;
      isInfoMenuVisible.value = false;
    };
    const showInfoMenu = () => {
      isMainMenuVisible.value = false;
      isCatalogMenuVisible.value = false;
      isInfoMenuVisible.value = true;
    };
    onMounted(() => {
      countHeight();
    });
    onUpdated(() => {
      if (!props.isOpened) showMainMenu();
    });

    return {
      LOCALE,
      CATEGORIES_USER,
      SUPPORTED_LANGUAGES,
      IS_LANDSCAPE,
      menuHeight,
      localeLink,
      allProductsLink,
      saleLink,
      isSaleLink,
      changeLocale,
      showMainMenu,
      showCatalogMenu,
      showInfoMenu,
      isMainMenuVisible,
      isCatalogMenuVisible,
      isInfoMenuVisible,
      filteredCategories,
      categoryLink,
      menuList,
      currentRouteName,
      currentCategory,
      homeLink,
      route,
    };
  },
};
</script>
<style lang="scss">
.mobile-menu {
  position: absolute;
  z-index: 4;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  backdrop-filter: blur(2px);
  //   display: flex;
  //   flex-direction: column;

  // .mobile-menu__wrapper

  &__wrapper {
    //  flex: 1 1 auto;
    min-width: 200px;
    max-width: 375px;
    height: 100%;

    //  background-color: red;
  }

  // .mobile-menu__body

  &__body {
    height: 100%;
    padding: 15px 20px;
    background-color: #fff;
    width: 100%;
  }
}

.mobile-menu-main {
  // .mobile-menu-main__head

  &__head {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  // .mobile-menu-main__langs

  &__langs {
    display: flex;
    align-items: center;
    column-gap: 1px;
    margin-left: -8px;
    a {
      padding: 8px;
      font-variation-settings: "wght" 700;
      &.selected {
        color: $mainColor;
        text-transform: uppercase;
        font-variation-settings: "wght" 900;
        user-select: none;
        pointer-events: none;
      }
      &:hover {
        color: $mainColorDarken;
      }
    }
  }

  // .mobile-menu-main__button

  &__button {
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    @include fz(21);
    font-family: $ff1;
    font-variation-settings: "wght" 700;
    img {
      width: 16px;
      transform: rotate(-90deg);
      opacity: 0.5;
    }
    // .mobile-menu-main__button_catalog

    &_catalog {
    }

    // .mobile-menu-main__button_info

    &_info {
    }
  }

  // .mobile-menu-main__bottom

  &__bottom {
    padding-top: 31.5px;
    margin-top: 30px;
    border-top: 1.5px solid $greyDarken;
  }

  // .mobile-menu-main__mail

  &__mail {
    display: inline-block;
    margin-bottom: 15px;
    font-size: 0.8rem;
    opacity: 0.5;
    font-variation-settings: "wght" 700;
  }
  // .mobile-menu-main__copyright

  &__copyright {
    font-size: 0.75rem;
    font-variation-settings: "wght" 500;
    opacity: 0.3;
  }
}

.mobile-menu-catalog {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  // .mobile-menu-catalog__head

  &__head {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  // .mobile-menu-catalog__button

  &__button {
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    padding: 10px;
    img {
      width: 20px;
      transform: rotate(90deg);
    }
  }
  // .mobile-menu-catalog__head-link

  &__head-link {
    font-variation-settings: "wght" 500;
    @include fz(21);
  }

  // .mobile-menu-catalog__links

  &__links {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    a {
      @include fz(21);
      font-variation-settings: "wght" 700;
      padding-top: 5px;
      padding-bottom: 5px;
      &:last-child {
        color: $discount;
      }
    }
    a.selected {
      color: $mainColor;
    }
  }
}

.mobile-menu-info {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  // .mobile-menu-info__head

  &__head {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  // .mobile-menu-info__button

  &__button {
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    padding: 10px;
    img {
      width: 20px;
      transform: rotate(90deg);
    }
  }

  // .mobile-menu-info__head-text

  &__head-text {
    @include fz(21);
    font-variation-settings: "wght" 500;
  }
  // .mobile-menu-info__links

  &__links {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    a {
      @include fz(21);
      font-variation-settings: "wght" 700;
      padding-top: 5px;
      padding-bottom: 5px;
      &.disabled {
        pointer-events: none;
        user-select: none;
        color: $mainColor;
      }
    }
  }
}
</style>
