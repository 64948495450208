// import { event as myEvent } from "vue-gtag";
import supported_languages from "@/langs";

export function addPreloadImage(data) {
  let l = document.createElement("link");
  l.rel = "preload";
  l.as = "image";
  l.href = data;
  document.head.append(l);
}

export function isQueryUtm(item) {
  return item.includes("utm_") || item.toLowerCase().includes("adset");
}

export function addDataLayer(data, event) {
  //   console.log("addDataLayer", event, data);
  const items = data.map((product) => ({
    item_name: product.title,
    item_id: +product.productId,
    price: +product.fullPrice,
    item_brand: "Vitaisland",
    item_category: product.categoryName,
    quantity: product.quantity || 1,
  }));

  // pixel
  const fbData = {
    content_type: "product",
    currency: "UAH",
    value: [
      ...data.map((item) =>
        count(
          item.fullPrice * +item.quantity || 1,
          typeof (item.discountAmount || Number(item.discountAmount)) == "number"
            ? item.discountAmount
            : item.quantity >= 3
            ? item.discountAmount[2]
            : item.discountAmount[item.quantity - 1]
        )
      ),
    ].reduce((total, item) => total + item, 0),
  };
  if (event == "purchase" || event == "add_to_cart") {
    fbData.contents = items.map((item) => ({ id: item.item_id, quantity: item.quantity }));
    if (event == "purchase") {
      window._fbq("track", "Purchase", fbData);
    } else {
      window._fbq("track", "AddToCart", fbData);
    }
  }
  if (event == "begin_checkout" || event == "purchase") {
    delete fbData.contents;
    fbData.content_name = data.length > 1 ? "Вітаміни" : data[0].title;
    fbData.content_category = data.length > 1 ? "Вітаміни" : data[0].categoryName;
    fbData.content_ids = [...data.map((item) => item.productId)];
    fbData.num_items = [...data.map((item) => +item.quantity)].reduce((total, item) => total + item, 0);
    if (event == "purchase") {
      window._fbq("track", "Lead", fbData);
    } else {
      window._fbq("track", "InitiateCheckout", fbData);
    }
  }
}

// export function addDataLayer(data, event, obj) {
//   //   console.log("addDataLayer", event, data, obj);
//   const items = data.map((product) => ({
//     item_name: product.title,
//     item_id: +product.productId,
//     price: +product.fullPrice,
//     item_brand: "Vitaisland",
//     item_category: product.categoryName,
//     quantity: product.quantity || 1,
//   }));

//   // ecom
//   const eventData = {
//     event: event,
//     ecommerce: {
//       ...obj,
//       items: [...items],
//     },
//   };
//   window.dataLayer.push({ ecommerce: null });
//   window.dataLayer.push({ ...eventData });

//   // pixel
//   const fbData = {
//     content_type: "product",
//     currency: "UAH",
//     value: [
//       ...data.map((item) =>
//         count(
//           item.fullPrice * +item.quantity || 1,
//           typeof (item.discountAmount || Number(item.discountAmount)) == "number"
//             ? item.discountAmount
//             : item.quantity >= 3
//             ? item.discountAmount[2]
//             : item.discountAmount[item.quantity - 1]
//         )
//       ),
//     ].reduce((total, item) => total + item, 0),
//   };
//   if (event == "purchase" || event == "add_to_cart") {
//     fbData.contents = items.map((item) => ({ id: item.item_id, quantity: item.quantity }));

//     //  console.log(fbData);
//     if (event == "purchase") {
//       window._fbq("track", "Purchase", fbData);
//     } else {
//       window._fbq("track", "AddToCart", fbData);
//     }
//   }
//   if (event == "begin_checkout" || event == "purchase") {
//     delete fbData.contents;
//     fbData.content_name = data.length > 1 ? "Вітаміни" : data[0].title;
//     fbData.content_category = data.length > 1 ? "Вітаміни" : data[0].categoryName;
//     fbData.content_ids = [...data.map((item) => item.productId)];
//     fbData.num_items = [...data.map((item) => +item.quantity)].reduce((total, item) => total + item, 0);

//     //  console.log(fbData);
//     if (event == "purchase") {
//       window._fbq("track", "Lead", fbData);
//     } else {
//       window._fbq("track", "InitiateCheckout", fbData);
//     }
//   }
//   //   myEvent(event, eventData);
// }

export function queryParams(queries, array) {
  return Object.fromEntries(Object.entries(queries).filter(([key]) => !array.includes(key)));
}
export function getLocale() {
  let localePath = window.location.pathname.split("/")[1].length == 2 ? window.location.pathname.split("/")[1] : false;
  let locale = localePath || JSON.parse(localStorage.getItem("locale")) || "ua";
  return supported_languages.some((lang) => lang == locale) ? locale : "ua";
}
export function getDeviceInfo() {
  let device = {};
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    device.type = "mobile";
  } else {
    device.type = "desktop";
  }
  device.screenWidth = window.screen.width;
  device.screenHeight = window.screen.height;
  device.isLandscape = device.screenWidth > device.screenHeight ? true : false;
  if ("ontouchstart" in window || navigator.maxTouchPoints) {
    device.isTouch = true;
  } else {
    device.isTouch = false;
  }
  return device;
}
export function isSafari() {
  if (typeof window.safari !== "undefined") {
    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(window.safari && window.safari.pushNotification);
    return isSafari;
  }
  return false;
}
export function count(price, discount) {
  return Math.ceil(price - price * (discount / 100));
}
export const calculateTotalFullPrice = (data) => {
  return data.reduce((total, item) => {
    return total + item.fullPrice * item.quantity;
  }, 0);
};
export const calculateTotalDiscountPrice = (data) => {
  return data.reduce((total, item) => {
    //  if (item.category == 7) {
    if (item.quantity == 1) {
      return (
        total +
        count(
          item.fullPrice * item.quantity,
          typeof (item.discountAmount || Number(item.discountAmount)) == "number"
            ? item.discountAmount
            : item.discountAmount[0]
        )
      );
    } else if (item.quantity == 2) {
      return (
        total +
        count(
          item.fullPrice * item.quantity,
          typeof (item.discountAmount || Number(item.discountAmount)) == "number"
            ? item.discountAmount
            : item.discountAmount[1]
        )
      );
    } else {
      return (
        total +
        count(
          item.fullPrice * item.quantity,
          typeof (item.discountAmount || Number(item.discountAmount)) == "number"
            ? item.discountAmount
            : item.discountAmount[2]
        )
      );
    }
    //  if (item.quantity == 1) {
    //    return total + count(item.fullPrice * item.quantity, item.discountAmount[0]);
    //  } else if (item.quantity == 2) {
    //    return total + count(item.fullPrice * item.quantity, item.discountAmount[1]);
    //  } else {
    //    return total + count(item.fullPrice * item.quantity, item.discountAmount[2]);
    //  }
    //  } else {
    //    return total + count(item.fullPrice * item.quantity, item.discountAmount);
    //  }
  }, 0);
};

export function validatePhone(phone, t) {
  //   console.log("validatePhone");

  const phoneRegex = /^\+\d{2}0(39|67|68|96|97|98|50|66|95|99|63|73|93|91|92|94)\d{7}$/;
  const phoneRegex2 = /^\+\d{2}\s\(0(39|67|68|96|97|98|50|66|95|99|63|73|93|91|92|94)\)\s\d{3}-\d{2}-\d{2}$/;

  if (phoneRegex.test(phone) || phoneRegex2.test(phone)) {
    return true;
  } else {
    const errorBlock = [...document.querySelectorAll("input")].find((input) => input.value === phone);
    if (errorBlock) {
      listenInput(errorBlock, t, "phone");
    }
    return false;
  }
}
export function validateEmail(email, t) {
  const nonCyrillicRegex = /^[A-Za-z0-9_@.-]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!nonCyrillicRegex.test(email) || !emailRegex.test(email)) {
    const errorBlock = [...document.querySelectorAll("input")].find((input) => input.value === email);
    if (errorBlock) {
      listenInput(errorBlock, t, "email");
    }
    return false;
  } else {
    return true;
  }
}
function listenInput(el, t, field) {
  window.scrollTo({
    top:
      el.parentElement.getBoundingClientRect().top +
      window.scrollY -
      document.querySelector(".header").scrollHeight -
      20,
    behavior: "smooth",
  });
  el.classList.add("error");
  const errorParent = el.parentElement;
  errorParent.setAttribute("data-tooltip", t(`errors.${field}`, { dog: "@" }));
  el.addEventListener(
    "input",
    () => {
      el.classList.remove("error");
      errorParent.removeAttribute("data-tooltip");
    },
    { once: true }
  );
}
