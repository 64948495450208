<template>
  <div class="user-loader"><div class="lds-dual-ring"></div></div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "UserLoader",
  setup() {
    const store = useStore();

    const LOCALE = computed(() => store.getters.LOCALE);

    return {
      LOCALE,
    };
  },
};
</script>
<style lang="scss">
.user-loader {
  position: fixed;
  z-index: 150;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  // opacity: 0;
  // transition: all 0.3s ease;
  // &.active {
  //    opacity: 1;
  //    user-select: all;
  //    pointer-events: all;
  //    cursor: wait;
  // }
  cursor: progress;
  .lds-dual-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 6px solid $mainColor;
    border-color: $mainColor transparent $mainColor transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
