<template>
  <button class="user-button">
    <slot />
  </button>
</template>
<script>
export default {
  name: "UserButton",
  setup() {
    return {};
  },
};
</script>
<style lang="scss">
.user-button {
  white-space: nowrap;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 100px;
  font-family: $ff1;

  @include fz(16);
  @media (min-width: 768px) {
    @include fz(18);
  }
  @media (min-width: 990px) {
    @include fz(20);
  }
  @media (min-width: 1024px) {
    @include fz(22);
  }
  @media (min-width: 1400px) {
    @include fz(24);
  }
  line-height: 1;
  font-variation-settings: "wght" 700;
  @extend %box-shadow-small;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: $mainColor;
  transition: all 0.3s ease;
  &.blue {
    background-color: $blue;
  }
  &:hover {
    @media (min-width: 768px) {
      transform: scale(1.02);
    }
    background-color: $mainColorDarken;
    &.blue {
      background-color: $blueDarken;
    }
  }
  &:active {
    opacity: 0.8;
    @media (min-width: 768px) {
      transform: scale(1.01);
    }
  }
}
</style>
