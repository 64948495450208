<template>
  <div
    class="capcthca-popup"
    @click="handleClickOutside"
  >
    <div class="capcthca-popup__wrapper">
      <div class="capcthca-popup__body">
        <form
          class="capctha-message"
          @submit.prevent="validateCaptcha"
        >
          <p class="capcthca-popup__text">{{ $t("captcha.title") }}</p>
          <input
            type="text"
            v-model="inputVal"
            :placeholder="$t('captcha.1')"
            required
            minlength="4"
            maxlength="4"
          />
          <div class="captcha-timer-wrapper">
            <UserButton
              @click="generateCaptcha"
              type="button"
              :class="{ disabled: isTimerActive }"
            >
              {{ $t("captcha.2") }}
            </UserButton>
            <div
              class="captcha-timer"
              v-if="isTimerActive"
            >
              {{ timer }} {{ $t("captcha.3") }}
            </div>
          </div>
          <UserButton
            type="submit"
            class="blue"
            :class="{ disabled: String(inputVal).length < 4 }"
          >
            {{ $t("captcha.4") }}
          </UserButton>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import UserButton from "@/components/UI/UserButton.vue";
export default {
  name: "CaptchaValidator",
  components: {
    UserButton,
  },

  setup(_, { emit }) {
    const inputVal = ref("");
    const timer = ref(60);
    const isTimerActive = ref(false);

    const handleClickOutside = (e) => {
      if (!e.target.closest(".capcthca-popup__body")) {
        emit("closeCaptchaPopup");
      }
    };
    const generateCaptcha = () => {
      inputVal.value = "";
      startTimer();
      emit("generateCaptcha");
    };
    const validateCaptcha = () => {
      emit("validateCaptcha", inputVal.value);
    };
    const startTimer = () => {
      isTimerActive.value = true;
      let timerInterval = setInterval(() => {
        if (timer.value > 0) {
          timer.value--;
        } else {
          clearInterval(timerInterval);
          isTimerActive.value = false;
          timer.value = 60;
        }
      }, 1000);
    };
    onMounted(() => {
      generateCaptcha();
    });
    onBeforeUnmount(() => {
      inputVal.value = "";
    });
    return {
      inputVal,
      timer,
      isTimerActive,
      handleClickOutside,
      generateCaptcha,
      validateCaptcha,
    };
  },
};
</script>
<style lang="scss">
.capcthca-popup {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(1.5px);
  // .capcthca-popup__text
  &__text {
    text-align: center;
    margin-bottom: 8px;
  }
  // .capcthca-popup__wrapper

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  // .capcthca-popup__body

  &__body {
    position: relative;
    background-color: $grey;
    @extend %box-shadow-big;
    @extend %radius-big;
    position: relative;
    max-width: 500px;
    padding: 20px;
  }
}

.capctha-message {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  button.disabled {
    user-select: none;
    pointer-events: none;
    background-color: #dddddd;
  }
  input {
    width: 100% !important;
    padding: 15px 20px !important;
    border-radius: 20px !important;
    border: 0 !important;
    background-color: #fff !important;
    font-size: 1rem !important;
    text-align: center;
    @extend %box-shadow-small;

    &::placeholder {
      opacity: 0.7;
    }
  }
}
.captcha-timer-wrapper {
  @media (max-width: 567px) {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
    button {
      order: 2;
      white-space: wrap;
    }
  }
  @media (min-width: 568px) {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
  }
  .captcha-timer {
    @media (max-width: 567px) {
      order: 1;
    }
    @media (min-width: 568px) {
      margin-left: 15px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px;
    background-color: $mainColor;
    color: #fff;
    border-radius: 5px;
    min-width: 80px;
    text-align: center;
    font-variation-settings: "wght" 700;
    box-shadow: 0 0px 4px rgba(65, 65, 65, 0.5);
  }
}
</style>
