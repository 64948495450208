<template>
  <!-- @submit.prevent="preorder" -->
  <CaptchaValidator
    v-if="isPhoneValidatorOpened"
    @validateCaptcha="validateCaptcha"
    @generateCaptcha="generateCaptcha"
    @closeCaptchaPopup="closeCaptchaPopup"
  />
  <form
    @submit.prevent="submitForm"
    class="ordering-block"
  >
    <p class="ordering-block__title">
      {{ $t("orderingBlock.1") }}
    </p>
    <div class="ordering-block__input-wrapper">
      <label
        class="ordering-block__label"
        for="name"
      >
        {{ $t("orderingBlock.2") }}
      </label>
      <input
        class="ordering-block__input"
        id="name"
        name="name"
        type="text"
        required
        :placeholder="$t('orderingBlock.3')"
        v-model="name"
      />
    </div>
    <div class="ordering-block__input-wrapper">
      <label
        class="ordering-block__label"
        for="phone"
      >
        {{ $t("orderingBlock.4") }}
      </label>
      <input
        class="ordering-block__input"
        id="phone"
        name="phone"
        type="text"
        required
        :placeholder="$t('orderingBlock.5')"
        @focus="setPhone"
        @input="onPhoneInput"
        :value="templatePhone"
        maxlength="19"
      />
    </div>
    <div class="ordering-block__input-wrapper">
      <label
        class="ordering-block__label"
        for="promo"
      >
        {{ $t("orderingBlock.8") }}
      </label>
      <input
        class="ordering-block__input"
        id="promo"
        name="promo"
        type="text"
        :placeholder="$t('orderingBlock.9')"
        v-model="promo"
      />
    </div>
    <!-- <div>
      <VueRecaptcha
        class="order-form__captcha-wrapper"
        v-show="captcha"
        ref="recaptcha"
        @verify="submitForm"
        :sitekey="siteKey"
      ></VueRecaptcha>
    </div> -->
    <UserButton class="ordering-block__button blue">
      <!-- v-show="!captcha" -->
      {{ $t("orderingBlock.6") }}
    </UserButton>
    <router-link
      class="ordering-block__policy"
      :to="policyLink"
      target="_blank"
    >
      {{ $t("orderingBlock.7") }}
    </router-link>
  </form>
</template>
<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { validatePhone, queryParams } from "@/utils/utils";
import UserButton from "@/components/UI/UserButton.vue";
import CaptchaValidator from "@/components/popups/CaptchaValidator";
// import { VueRecaptcha } from "vue-recaptcha";
export default {
  name: "OrderingBlock",
  components: {
    UserButton,
    CaptchaValidator,
    // VueRecaptcha
  },
  props: {
    props_data: {
      type: Object,
      default() {
        return {};
      },
    },
    isPreorderPopup: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const IS_FEMALE = computed(() => store.getters.IS_FEMALE);
    const LOCALE = computed(() => store.getters.LOCALE);
    const CART = computed(() => store.getters.CART);
    const route = useRoute();
    const router = useRouter();
    const one_click_product = ref(props.props_data);
    const { t } = useI18n();

    const name = ref("");
    const phone = ref("");
    const promo = ref("");
    const promocodeId = ref(null);
    const policyLink = computed(() => ({
      name: "policy",
      query: {
        ...queryParams(route.query, ["gender", "category", "order_id", "affiliation", "page"]),
      },
      params: { locale: LOCALE.value },
    }));

    //  const siteKey = "6LcAkIApAAAAANHbEmTrZgSnRcaQW6tGnsnDPxC-";
    //  const captcha = ref(false);
    //  const preorder = () => {
    //    const data = [];
    //    if (props.isPreorderPopup) {
    //      data.push(...CART.value);
    //    } else if (one_click_product.value?.productId) {
    //      data.push(one_click_product.value);
    //    } else {
    //      data.push(...CART.value);
    //    }
    //    if (validatePhone(phone.value)) {
    //      addDataLayer(data, "begin_checkout");
    //      store
    //        .dispatch("SEND_USER", {
    //          src: "/product/pre_order",
    //          data: {
    //            lang: LOCALE.value,
    //            name: name.value,
    //            phone: phone.value,
    //            type: "capcha",
    //          },
    //        })
    //        .then((respnose) => {
    //          if (respnose.data.status == 0) captcha.value = true;
    //        });
    //    } else {
    //      console.log("wrong phone");
    //    }
    //  };

    const setPhone = () => {
      phone.value == "" ? (phone.value = "+38") : false;
    };
    const templatePhone = computed(() => {
      let tel;
      const x = phone.value.replace(/\D/g, "").match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
      tel =
        `+${x[1]}` +
        (x[3] ? " (" + x[2] + ") " : "" + x[2]) +
        x[3] +
        (x[4] ? "-" + x[4] : "") +
        (x[5] ? "-" + x[5] : "");
      if (phone.value.length > 1) {
        return tel;
      } else {
        return "";
      }
    });
    const onPhoneInput = (e) => {
      phone.value = e.target.value;
      if (phone.value.length >= 19) {
        phone.value = e.target.value.slice(0, 19);
      }
    };

    const submitForm = () => {
      if (promo.value) {
        store
          .dispatch("SEND_USER", {
            src: "/product/check_promocode",
            data: {
              code: promo.value,
            },
          })
          .then((response) => {
            if (response.data.status == 0) {
              promocodeId.value = response.data.data.id;
              preorder();
            } else {
              alert("incorrect promo");
            }
          });
      } else {
        preorder();
      }
    };
    const isPhoneValidatorOpened = ref(false);
    const data_capctha_id = ref(null);
    const closeCaptchaPopup = () => {
      isPhoneValidatorOpened.value = false;
    };
    const preorder = () => {
      if (validatePhone(phone.value, t)) {
        sendData();
        //   isPhoneValidatorOpened.value = true;
      }
    };
    const validateCaptcha = (value) => {
      const data = {
        verify_id: data_capctha_id.value,
      };
      if (value) data.code = value;
      store
        .dispatch("SEND_USER", {
          src: "/product/pre_order",
          data: {
            ...data,
          },
        })
        .then((response) => {
          if (response.data.status == 0) {
            sendData();
          } else {
            alert(t("captcha.5"));
            if (confirm(t("captcha.6"))) {
              generateCaptcha();
            } else {
              isPhoneValidatorOpened.value = false;
            }
          }
        });
    };
    const generateCaptcha = () => {
      const data = {
        type: "phone",
        name: name.value,
        phone: phone.value,
      };
      if (data_capctha_id.value) data.old_id = data_capctha_id.value;
      store
        .dispatch("SEND_USER", {
          src: "/product/pre_order",
          data: {
            ...data,
          },
        })
        .then((response) => {
          if (response.data.status == 0) {
            data_capctha_id.value = response.data.data;
          }
        });
    };
    const sendData = () => {
      const data = {
        user: {
          name: name.value,
          phone: phone.value,
          method: 1,
          call_back: 0,
        },
        one_click: 1,
      };
      if (one_click_product.value?.quantity) {
        if (!IS_FEMALE.value) one_click_product.value.productId = "35";
        data.cart = [{ ...one_click_product.value }];
      } else {
        data.cart = { ...CART.value };
      }
      if (promocodeId.value) data.promocodeId = promocodeId.value;
      const queryItems = JSON.parse(localStorage.getItem("queryItems"));
      if (queryItems) {
        for (let item in queryItems) {
          data[item] = queryItems[item];
        }
      }
      store
        .dispatch("SEND_USER", {
          src: "/product/create_order",
          data: {
            lang: LOCALE.value,
            ...data,
          },
        })
        .then((response) => {
          if (response.data.status == 0) {
            isPhoneValidatorOpened.value = false;
            promocodeId.value = null;
            data_capctha_id.value = null;
            name.value = "";
            phone.value = "";
            promo.value = "";
            router.push({
              name: "thanks",
              query: {
                ...queryParams(route.query, ["gender", "category", "page"]),
                order_id: response.data.data,
                affiliation: "fast order",
              },
              params: { locale: LOCALE.value },
            });
          }
        });
    };
    return {
      submitForm,
      name,
      phone,
      promo,
      setPhone,
      templatePhone,
      onPhoneInput,
      // preorder,
      //  siteKey,
      // captcha,
      LOCALE,
      policyLink,
      preorder,
      isPhoneValidatorOpened,
      validateCaptcha,
      generateCaptcha,
      closeCaptchaPopup,
    };
  },
};
</script>
<style lang="scss">
.ordering-block {
  background-color: $grey;
  @extend %radius-big;
  @extend %box-shadow-small;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-family: $ff2;
  text-align: center;
  // .ordering-block__title

  &__title {
  }

  // .ordering-block__input-wrapper

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
  }

  // .ordering-block__label

  &__label {
    cursor: pointer;
    padding: 3px 6px;
  }

  // .ordering-block__input

  &__input {
    width: 100%;
    background-color: #fff;
    padding: 10px 20px;
    @media (min-width: 768px) {
      padding: 15px 24px;
      font-size: 1.1em;
    }
    @extend %radius-medium;
    @extend %box-shadow-small;
    width: 100%;
    text-align: center;
    &::placeholder {
      opacity: 0.5;
    }
  }

  // .ordering-block__button

  &__button {
    max-width: max-content;
    margin-left: auto;
    margin-right: auto;
  }
  // .ordering-block__policy

  &__policy {
    text-decoration: underline;
  }
}
</style>
