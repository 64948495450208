<template>
  <div
    class="preorder-popup"
    @click="handleClickOutside"
  >
    <div class="preorder-popup__wrapper">
      <div class="preorder-popup__body">
        <div class="preorder-popup__content">
          <div
            class="preorder-popup__close-icon"
            @click="closePreorderPopup"
          >
            <img
              loading="lazy"
              :src="require(`../../assets/img/icons/close.svg`)"
              alt="close"
            />
          </div>
          <div class="preorder-popup__cart preorder-popup-cart">
            <p class="preorder-popup-cart__title">{{ $t("popups.1") }}</p>
            <ul
              class="preorder-popup-cart__list"
              :style="{ maxHeight: maxHeight + 'px' }"
            >
              <CartItem
                class="smaller"
                v-for="item in CART"
                :key="item.productId"
                :props_data="item"
              />
            </ul>
            <div class="preorder-popup-cart__buttons">
              <UserButton
                class="preorder-popup-cart__button"
                @click="closePreorderPopup"
              >
                <router-link :to="orderingLink">
                  {{ $t("popups.4") }}
                </router-link>
              </UserButton>
              <UserButton
                class="preorder-popup-cart__button"
                @click="closePreorderPopup"
              >
                {{ $t("popups.2") }}
              </UserButton>
            </div>
          </div>
          <div class="preorder-popup__ordering preorder-popup-ordering">
            <p class="preorder-popup-ordering__title">{{ $t("popups.3") }}</p>
            <OrderingBlock :isPreorderPopup="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { addDataLayer, queryParams } from "@/utils/utils";

import CartItem from "@/components/CartItem.vue";
import UserButton from "@/components/UI/UserButton.vue";
import OrderingBlock from "@/components/OrderingBlock.vue";
export default {
  name: "PreorderPopup",
  components: {
    CartItem,
    UserButton,
    OrderingBlock,
    //
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const LOCALE = computed(() => store.getters.LOCALE);
    const CART = computed(() => store.getters.CART);
    const maxHeight = ref(0);
    const countHeight = () => {
      const list = document.querySelector(".preorder-popup-cart__list");
      const itemHeight = list.firstElementChild.scrollHeight;
      maxHeight.value = itemHeight * 2 + 20 + 25;
    };
    onMounted(() => {
      countHeight();
      addDataLayer(CART.value, "begin_checkout");
    });
    const handleClickOutside = (e) => {
      if (!e.target.closest(".preorder-popup__body")) closePreorderPopup();
    };
    const closePreorderPopup = () => {
      store.commit("CLOSE_PREORDER_POPUP");
    };
    const orderingLink = computed(() => ({
      name: "ordering",
      query: {
        ...queryParams(route.query, ["gender", "category", "order_id", "affiliation", "page"]),
      },
      params: { locale: LOCALE.value },
    }));

    return {
      CART,
      handleClickOutside,
      closePreorderPopup,
      maxHeight,
      orderingLink,
    };
  },
};
</script>
<style lang="scss">
.preorder-popup {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(1.5px);
  // .preorder-popup__wrapper

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 10vh;
    padding-bottom: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    //  align-items: center;
  }

  // .preorder-popup__body

  &__body {
    position: relative;
    background-color: $grey;
    @extend %box-shadow-big;
    @extend %radius-big;
    min-width: 60vw;
    @media (min-width: 768px) {
      min-width: 40vw;
    }
    position: relative;
  }
  // .preorder-popup__body

  &__content {
    @extend %container;
    @extend %scroll;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-y: auto;
    max-height: 80vh;
  }

  // .preorder-popup__close-icon

  &__close-icon {
    @extend %box-shadow-small;
    cursor: pointer;
    width: 28px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    padding: 5px;
    background-color: $grey;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // .preorder-popup__cart

  &__cart {
  }

  // .preorder-popup__ordering

  &__ordering {
  }
}
.preorder-popup-cart {
  margin-bottom: 30px;
  // .preorder-popup-cart__title

  &__title {
    text-align: center;
    font-variation-settings: "wght" 500;
    font-size: 1.4rem;
    margin-bottom: 15px;
  }

  // .preorder-popup-cart__list

  &__list {
    //  max-height: 160px;
    overflow-y: auto;
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 20px;
    @extend %scroll;
  }

  // .preorder-popup-cart__buttons

  &__buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 8px;
    column-gap: 10px;
    @media (max-width: 767px) {
      justify-content: center;
      align-items: center;
    }
  }
  // .preorder-popup-cart__button

  &__button {
    //  margin-left: auto;
  }
}
.preorder-popup-ordering {
  // .preorder-popup-ordering__title

  &__title {
    text-align: center;
    font-variation-settings: "wght" 500;
    font-size: 1.4rem;
    margin-bottom: 15px;
  }
}
</style>
