import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import i18n from "./i18n/i18n";

async function initializeApp() {
  const app = createApp(App);
  app.use(store);
  app.use(router);
  app.use(i18n);

  await router.isReady();
  app.mount("#app");
}

initializeApp();
