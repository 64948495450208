<template>
  <footer class="user-footer">
    <div class="user-footer__container">
      <nav class="user-footer__nav user-footer__nav_catalog">
        <router-link
          :to="allProductsLink"
          class="user-footer__title"
        >
          {{ t("userFooter.catalog") }}
        </router-link>
        <router-link
          v-for="category in filteredCategories"
          :key="category.name_en"
          :to="categoryLink(category.name_en)"
          class="user-footer__link user-footer__link_category"
        >
          {{ category.name }}
        </router-link>
      </nav>
      <nav class="user-footer__nav user-footer__nav_info">
        <h4 class="user-footer__title">{{ t("userFooter.info") }}</h4>
        <router-link
          v-for="(item, index) in menuList"
          :key="index"
          :to="item.to"
          class="user-footer__link user-footer__link_info"
          :class="{ disabled: item.to.name == route.name }"
        >
          {{ item.label }}
        </router-link>
      </nav>
      <nav class="user-footer__nav user-footer__nav_contacts">
        <a
          class="user-footer__title"
          href="tel:0800315000"
        >
          <!-- 073 022 96 49 -->
          <!-- 073 029 64 18 -->
          0800 315 000
        </a>
        <a
          href="mailto:vitaisland.com.ua@gmail.com"
          class="user-footer__link user-footer__link_email"
        >
          VitaIsland.com.ua@gmail.com
        </a>
        <ul class="user-footer__dates">
          <li class="user-footer__date">{{ t("userFooter.date_1") }}</li>
        </ul>
        <div class="user-footer__socials">
          <a
            href="https://www.tiktok.com/@vitaisland"
            target="_blank"
            class="user-footer__social"
          >
            <img
              loading="lazy"
              src="/img/icons/tiktok.svg"
              alt="tiktok"
            />
            <!-- <img
              loading="lazy"
              :src="require(`../assets/img/icons/tiktok.svg`)"
              alt="tiktok"
            /> -->
          </a>
          <a
            href="https://m.facebook.com/people/Vita-Island/100095082574567/"
            target="_blank"
            class="user-footer__social"
          >
            <img
              loading="lazy"
              src="/img/icons/fb.svg"
              alt="facebook"
            />
            <!-- <img
              loading="lazy"
              :src="require(`../assets/img/icons/fb.svg`)"
              alt="facebook"
            /> -->
          </a>
          <a
            href="https://www.instagram.com/vitaisland/?igshid=Y2IzZGU1MTFhOQ%3D%3D"
            target="_blank"
            class="user-footer__social"
          >
            <img
              loading="lazy"
              src="/img/icons/inst.svg"
              alt="instagram"
            />
            <!-- <img
              loading="lazy"
              :src="require(`../assets/img/icons/inst.svg`)"
              alt="instagram"
            /> -->
          </a>
        </div>
        <div class="user-footer__method">
          <img
            loading="lazy"
            src="/img/icons/payment.webp"
            alt="visa/mastercard"
          />
          <!-- <img
            loading="lazy"
            :src="require(`../assets/img/payment.webp`)"
            alt="visa/mastercard"
          /> -->
        </div>
      </nav>
    </div>
    <div class="user-footer__copy">
      <p>{{ t("userFooter.copy") }}</p>
    </div>
  </footer>
</template>
<script>
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { queryParams } from "@/utils/utils";
export default {
  name: "UserFooter",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const CATEGORIES_USER = computed(() => store.getters.CATEGORIES_USER);
    const LOCALE = computed(() => store.getters.LOCALE);

    const menuList = [
      {
        label: t("mobileMenu.6"),
        to: {
          name: "policy",
          query: { ...queryParams(route.query, ["gender", "category", "order_id", "affiliation", "page"]) },
          params: { locale: LOCALE.value },
        },
      },
      {
        label: t("mobileMenu.7"),
        to: {
          name: "terms",
          query: { ...queryParams(route.query, ["gender", "category", "order_id", "affiliation", "page"]) },
          params: { locale: LOCALE.value },
        },
      },
      {
        label: t("mobileMenu.8"),
        to: {
          name: "refund",
          query: { ...queryParams(route.query, ["gender", "category", "order_id", "affiliation", "page"]) },
          params: { locale: LOCALE.value },
        },
      },
      {
        label: t("mobileMenu.9"),
        to: {
          name: "contacts",
          query: { ...queryParams(route.query, ["gender", "category", "order_id", "affiliation", "page"]) },
          params: { locale: LOCALE.value },
        },
      },
    ];
    const homeLink = computed(() => ({
      name: "home",
      query: {
        ...queryParams(route.query, ["gender", "category", "order_id", "affiliation", "page"]),
      },
      params: { locale: LOCALE.value },
    }));
    const filteredCategories = computed(() => {
      return CATEGORIES_USER.value.filter((category) => category.id != 6 && category.id != 7);
    });
    const categoryLink = (categoryName) => ({
      name: "catalog",
      query: {
        ...queryParams(route.query, ["gender", "order_id", "affiliation"]),
        category: categoryName,
        page: 1,
      },
      params: { locale: LOCALE.value },
    });

    const allProductsLink = computed(() => ({
      name: "catalog",
      query: {
        ...queryParams(route.query, ["gender", "order_id", "affiliation"]),
        category: "all",
        page: 1,
      },
      params: { locale: LOCALE.value },
    }));

    return {
      CATEGORIES_USER,
      LOCALE,
      t,
      menuList,
      route,
      router,
      homeLink,
      filteredCategories,
      categoryLink,
      allProductsLink,
      //
    };
  },
};
</script>
<style lang="scss">
.user-footer {
  background-color: $grey;
  // .user-footer__container

  &__container {
    font-size: 0.9rem;
    @extend %container;
    display: flex;
    padding-top: 60px;
    padding-bottom: 60px;
    @media (max-width: 567px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      row-gap: 40px;
    }
    @media (min-width: 568px) and (max-width: 767px) {
      flex-wrap: wrap;
      row-gap: 60px;
      column-gap: 5vw;
    }
    @media (min-width: 768px) {
      column-gap: 10vw;
    }
  }

  // .user-footer__nav

  &__nav {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    // .user-footer__nav_catalog

    &_catalog {
      flex: 1 1 auto;
    }

    // .user-footer__nav_info

    &_info {
    }

    // .user-footer__nav_contacts

    &_contacts {
      @media (max-width: 767px) {
        row-gap: 20px;
      }
    }
  }

  // .user-footer__title

  &__title {
    font-size: 1.25em;
    margin-bottom: 4px;
    color: #160c28;
    font-variation-settings: "wght" 600;
  }

  // .user-footer__link

  &__link {
    &.disabled {
      pointer-events: none;
      user-select: none;
      font-variation-settings: "wght" 700;
    }
    // .user-footer__link_category

    &_category {
      font-variation-settings: "wght" 300;
      color: #727272;
      text-decoration: underline;
    }

    // .user-footer__link_info

    &_info {
      color: #5c5569;
    }

    // .user-footer__link_email

    &_email {
      color: #5c5569;
      text-decoration: underline;
    }
  }

  // .user-footer__dates

  &__dates {
    line-height: 1.3;
  }

  // .user-footer__date

  &__date {
  }

  // .user-footer__socials

  &__socials {
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      align-self: center;
      column-gap: 20px;
    }
    @media (min-width: 768px) {
      column-gap: 12px;
    }
  }

  // .user-footer__social

  &__social {
    width: 40px;
    height: 40px;
    img {
      width: 100%;
    }
  }

  // .user-footer__method

  &__method {
    width: 100px;
    @media (max-width: 767px) {
      align-self: center;
    }
  }

  // .user-footer__copy

  &__copy {
    @extend %container;
    p {
      font-size: 0.9em;
      color: #545454;
      padding-top: 20px;
      padding-bottom: 40px;
      border-top: 1px solid $greyDarken;
      @media (max-width: 767px) {
        text-align: center;
      }
    }
  }
}
</style>
