export default {
  why: "Навіщо?",
  timer: `Знижка на перше замовлення`,
  fixed_cart_button: {
    get: `До кошика`,
  },
  land_advantages: {
    1: `Доставка з післяоплатою`,
    2: `Гарантія повернення коштів`,
    3: `Сертифікована продукція`,
  },
  userComments: {
    1: `Що кажуть наші клієнти?`,
  },
  buttons: {
    addToCart: `До кошика`,
    oneClickOrder: `Замовити в 1 клік`,
    orderForm: `Отримати`,
    hero_1: `Якщо ти хочеш`,
    hero_2: `Спосіб прийому`,
    hero_3: `Склад`,
  },
  // collagen
  1: {
    videoComments: {
      1: `Додай Collagen в свій щоденний раціон`,
    },
    orderForm: {
      1: `Відкрийте для себе новий секрет краси та здоров'я з Колагеном від `,
      2: `VitaIsland`,
    },
    fixed_cart_button: {
      1: `Collagen`,
      2: ` морський`,
    },
    hero_section: {
      tags: [
        "Пружна шкіра",
        "Нігті",
        "Кістки",
        "Еластичність",
        "Здорове волосся",
        "Робота мʼязів",
        //
      ],
      1: {
        1: `Відкрийте для себе новий`,
        2: `секрет краси та здоров'я з`,
        3: `Колагеном`,
        4: ` від `,
        5: `VitaIsland,`,
        6: `100% натуральним джерелом`,
        7: `молодості та енергії`,
      },
      3: `Сollagen VitaIsland`,
      6: `Покращує стан шкіри, волосся, нігтів і суглобів. Відчутне покращення вже у перші 7 днів `,
      14: `Сприяє збереженню`,
      15: `еластичності та гладкості шкіри, допомагаючи зменшити зморшки та лінії виразності.`,
      17: `Колаген відіграє важливу роль`,
      18: `у підтримці здоров'я суглобів, зміцнюючи хрящі та сприяючи їх гнучкості та міцності.`,
      19: `Регулярне споживання`,
      20: `колагену може покращити стан волосся та нігтів, зробивши їх більш сильними та здоровими.`,
      22: `Колаген сприяє зміцненню кісток`,
      23: `, підтримує здоров'я серця та регулює рівень цукру в крові, сприяючи загальному здоров'ю організму.`,

      31: `По 1 стік-пакету на добу незалежно від вживання їжі.`,
      32: `Не перевищувати рекомендовану добову дозу. `,
      33: `Рекомендований курс прийому — не менше 3 місяців з перервою на 1 місяць, надалі курс бажано повторювати 2-3 рази на рік або за рекомендацією лікаря.`,
      35: `Перед застосуванням необхідна консультація лікаря.`,

      36: `Що в складі Collagen позитивно впливає на організм?`,
      37: `Рибні колагенові пептиди 1,125 г`,
      38: `Складовий елемент надає основну будівельну блок-структуру для колагену в організмі.`,
      39: `Вітамін C 60 мг`,
      40: `Підвищує синтез колагену`,
      41: `Вітамін E 4,5 мг`,
      42: `Запобігає втраті вологи та зменшуючи запалення. Він також сприяє збереженню колагену в шкірі, збільшуючи її пружність і молодість.`,
      43: `Цинк (Zn) 4 г`,
      44: `Сприяє швидкому зціленню ран та подряпин на шкірі, а також підтримує здоров'я волосся та нігтів.`,
    },
    productTabs: {
      items_amount: "20",
      1: `Замовити Vitailsland Collagen`,
      3: `стіків`,
      4: `упаковка`,
      5: `упаковки`,
      6: `Знижка на перше замовлення`,
      7: `за курс`,
      8: `зі знижкою`,
      10: `по 15г`,
      11: `1 стік на день`,
    },
    land_1: {
      1: `Що таке колаген?`,
      2: `Collagen VitaIsland має великий вплив на організм, коли мова заходить про здоровʼя та стан нашої шкіри, волосся та нігтів.`,
      3: `Регулярне вживання колагену значно зменшує появу зморшок. Його велике значення проявляється в уповільненні процесів старіння та відновленні властивостей шкіри, волосся та нігтів, особливо за умов надмірних фізичних навантажень або в ситуаціях постійного виснаження.`,
    },
    land_2: {
      1: `Чому колаген від VitaIsland?`,
      2: `Переваги колагену від VitaIsland, над аналогами на ринку`,
      3: `Натуральність, висока якість та ефективність`,
      4: `Колаген VitaIsland виготовляється відповідно до найвищих стандартів якості і контролю, що гарантує його безпеку та ефективність для організму.`,
      5: `Морський колаген`,
      6: `Отримується з морських джерел, що має кілька переваг порівняно з колагеном тваринного походження. Морський колаген має більш маленькі молекули, що полегшує їх засвоєння організмом. Крім того, він містить в собі більше амінокислот, які сприяють підтримці здоров'я шкіри, волосся та суглобів.`,
      7: `Зручний формфактор та приємний смак`,
      8: `Вишневий смак колагену ідеально поєднується з будь-якими соками, а особливо з апельсиновим, бо вітамін С допомагає синтезувати колаген. Тому кожний, хто візьме кілька стіків з собою в дорогу, подорож або просто на прогулянку, матиме змогу швидко зробити смачний літній корисний коктейль, освіжитися і отримати дозу потрібного колагену в організмі.`,
      9: `Доказова медицина`,
      10: `Колаген ефективно впливає не тільки на якість шкіри, волосся та нігті, але також на суглоби. Доведено, що регулярне вживання колагену сприяє зміцненню суглобового хряща та покращенню їхньої мобільності, що робить колаген від VitaIsland надійним помічником у підтримці краси ззовні та всередині.`,
    },
    land_3: {
      1: `Натуральність, висока якість та ефективність`,
      3: `Натуральність:`,
      4: `Наш колаген - це продукт, що виготовлений з використанням лише натуральних інгредієнтів. Ми пильно стежимо за походженням і якістю кожного компоненту, щоб забезпечити максимальну природність нашого продукту.`,
      5: `Висока якість:`,
      6: `Для нас важливо, щоб кожен стік колагену мав найвищу якість. Ми використовуємо передові технології та дотримуємося строгих стандартів виробництва, щоб забезпечити стабільність та надійність у кожному продукті.`,
      7: `Ефективність:`,
      8: `Наш колаген - це не лише пусті обіцянки. Його ефективність підтверджена клінічними дослідженнями та відгуками задоволених клієнтів. Він підтримує здоров'я шкіри, волосся та нігтів, сприяє зміцненню суглобів і робить вас відчутно молодшими та енергійнішими.`,
    },
    land_4: {
      1: `Морський колаген`,
      2: `Морський колаген від VitaIsland`,
      3: `Це надійне джерело цілющих властивостей для вашого організму. Отриманий з морських джерел, він вирізняється більшою чистотою і ефективністю. Морський колаген має менші молекули, що полегшує їх засвоєння організмом. Також він містить більше амінокислот, які сприяють підтримці здоров'я шкіри, волосся та суглобів. Цей натуральний продукт відповідає найвищим стандартам якості та ефективності, надаючи вам надійну підтримку для вашого здоров'я та краси.`,
    },
    land_5: {
      1: `Зручний формфактор та приємний смак `,
      2: `Зручний формфактор та приємний смак - ось що робить колаген від VitaIsland першим вибором для активного життя. Колаген у формі стіків легко поміщається в сумку чи кишеню, тому готовий бути з вами в будь-який час і в будь-якому місці. Крім того, вишневий смак додає йому приємної нотки солодкості, яка ідеально поєднується з будь-яким напоєм. Зокрема, з апельсиновим соком - це чудове поєднання, адже вітамін C, що міститься у соку, стимулює синтез колагену в організмі. Таким чином, кожна порція колагену від VitaIsland - це не лише корисний крок для підтримки вашого здоров'я, але й смачний освіжаючий коктейль для вашого літнього настрою.`,
    },
    userComments: {
      name: {
        1: `Божена`,
        2: `Юлія`,
        3: `Лана`,
      },
      prof: {
        1: `Косметолог, засновниця косметологічної клініки - центру здорової шкіри «Kosmeya»`,
        2: `Нутріціолог, дієтолог`,
        3: `Візажист, бʼюті блогер`,
      },
      comment: {
        1: [
          `«Колаген спершу протестувала особисто, дуже сподобався смак та я була в захваті від результату. Зараз бренд Vita Island представлений у моїй клініці та ми залюбки рекомендуємо його нашим клієнтам»`,
        ],
        2: [
          `«Колаген Vita Island — справжнє джерело необхідних вітамінів для кожної жінки, яка прагне зберегти здоровʼя шкіри, волосся та нігтів. Ідеально для тих, хто не добирає білку в своєму організмі. Обираю його за відмінний склад та пептидну форму»`,
        ],
        3: [
          `«Ніщо так не допомагає моїй шкірі тримати тонус та бути зволоженою 24/7, як колаген від Vita Island. Процес замішування колагену дуже простий, а смак просто неймовірний. Доречі, щоразу дивуюсь цьому неймовірному упакуванню. Приваблює з першого погляду»`,
        ],
      },
    },
    land_6: {
      1: `Причини зниження вироблення колагену в організмі`,
      2: `Зниження рівня колагену в організмі може мати серйозні наслідки для здоров'я шкіри, суглобів та загального стану організму. Чимало факторів можуть призводити до зменшення вироблення цього важливого білка, який відповідає за структуру і еластичність нашого тіла. Розгляньмо основні причини зниження рівня колагену в організмі та їхні наслідки`,
      3: `Депресія:`,
      4: `Стан стресу та депресії може негативно впливати на рівень колагену в організмі. Підвищений рівень стресу сприяє виділенню гормону кортизолу, який може знизити синтез колагену та сприяти його руйнуванню.`,
      5: `Алкоголь:`,
      6: `Надмірне вживання алкоголю може призвести до зниження рівня колагену в шкірі. Алкоголь може сприяти втраті вологи та зменшенню еластичності шкіри, що призводить до втрати колагену.`,
      7: `Куріння:`,
      8: `Тютюнопаління має негативний вплив на шкіру та призводить до руйнування колагенових волокон. Токсичні речовини, що містяться в тютюновому димі, можуть знижувати синтез колагену та призводити до передчасного старіння шкіри.`,
      9: `Поганий сон:`,
      10: `Недостатній або порушений сон може впливати на процеси регенерації тканин, включаючи синтез колагену. Недостатній сон може призвести до зниження рівня колагену в організмі та сприяти передчасному старінню шкіри.`,
      11: `Екологія:`,
      12: `Вплив шкідливих факторів довкілля, таких як забруднене повітря та ультрафіолетове випромінювання, може призвести до пошкодження колагену в шкірі. Це може впливати на її еластичність та здатність до збереження вологи.`,
      13: `Авітаміноз:`,
      14: `Недостатність вітамінів та мінералів, таких як вітамін С, цинк та селен, необхідних для синтезу колагену, може призвести до його зниження в організмі. Недостатній прийом цих поживних речовин може впливати на здоров'я тканин та шкіри, що містять колаген.`,
    },
    land_7: {
      1: `Пʼять продуктів, які природним шляхом підвищують вироблення колагену`,
      2: `Риба.`,
      3: `Коли справа доходить до природного виробництва колагену, то риба - один із найкращих продуктів. Риб'ячий колаген є одним з найбільш ефективно засвоюваних джерел харчового колагену. Кращий варіант - лосось, тому що він багатий на жири омега-3 і містить високоякісний білок у вигляді амінокислот для підтримки вироблення колагену. Інші варіанти включають: анчоуси, сардини.`,
      4: `Цитрусові.`,
      5: `Деякі продукти містять більше вітаміну С, ніж цитрусові, такі як апельсин, лимон, грейпфрут і лайм. Вітамін С допомагає зберегти колаген в організмі та відіграє важливу роль у процесі вироблення організмом власного колагену.`,
      6: `Кістковий бульйон.`,
      7: `Це один із найвідоміших продуктів з високим вмістом колагену. Ви можете приготувати бульйон кісткового будинку, хоча цей процес може зайняти багато часу.`,
      8: `Боби.`,
      9: `Існує безліч веганських джерел білка, які забезпечують амінокислоти, необхідні підтримки вироблення колагену, включаючи ситні бобові, такі як квасоля. Багато сортів квасолі не тільки містять безліч амінокислот, що сприяють утворенню колагену, але і є відмінними джерелами міді.`,
      10: `Молюски.`,
      11: `Молюски, а особливо устриці, є дуже ефективними підсилювачами вироблення колагену. Ці солоні делікатеси не тільки сповнені різноманітних амінокислот для побудови колагену, а і є одними з найкращих харчових джерел міді та цинку.`,
      12: `Хоча включення цих продуктів до раціону може бути корисним для збільшення рівня колагену, однак, їхня концентрація не завжди є достатньою. Тому прийом колагену від VitaIsland може бути важливим для забезпечення нашого організму необхідними кількостями цього важливого білка.`,
    },
    faq: {
      answers: [
        "Колаген можна приймати будь-коли протягом дня, але багато людей віддають перевагу приймати його вранці або перед сном для максимального ефекту",
        "В деяких випадках люди можуть відчути розлади ШКТ при вживанні колагенових пептидів. Якщо це станеться, варто зменшити дозу або проконсультуватися з лікарем",
        "Хоча колаген міститься в деяких харчових продуктах, багато людей не отримують достатньо цього важливого білка в їхній дієті. Відтак, доповнення колагеном може бути корисним, особливо для людей 30+ років.",
        "Так, колаген можна приймати разом з більшістю інших вітамінів та доповнень",
        "Деякі дослідження показують, що колаген може покращити вологість шкіри та зменшити зморшки.",
        "Деякі дослідження показують, що колаген може підтримувати здоровʼя суглобів.",
      ],
      questions: [
        "Коли найкраще приймати колаген?",
        "Чи може колаген спричинити побічні ефекти?",
        "Чи потрібно мені приймати колаген, якщо я збалансовано харчуюсь?",
        "Чи можу я приймати колаген разом з іншими вітамінами?",
        "Чи покращить колаген зовнішній вигляд моєї шкіри?",
        "Чи може вживання колагену покращити здоровʼя моїх суглобів?",
      ],
    },
  },
  // D3K2
  4: {
    videoComments: {
      1: `Додай D3 + K2 в свій щоденний раціон`,
    },
    orderForm: {
      1: `Отримай вітамін сонця з `,
      2: `D3+K2 VitaIsland`,
    },
    fixed_cart_button: {
      1: `D3+K2`,
      2: ` 30 капсул по 700мг`,
    },
    hero_section: {
      tags: [
        "Імунна система",
        "Кістки",
        "Психіка",
        "Гормони",
        "Метаболізм",
        "Робота мʼязів",
        //
      ],
      1: {
        1: `Отримай вітамін сонця з`,
        2: `D3+K2 VitaIsland`,
      },
      3: `D3+K2 VitaIsland`,
      6: `Дует, що допоможе відчувати себе бадьоріше та збалансовано. Помітний результат вже у перші тижні прийому`,
      14: `Зміцнити кістки`,
      15: `Підтримати імунну систему`,
      16: `Зменшити ризик`,
      17: `розвитку депресії та інших психічних розладів.`,
      18: `Підвищити витривалість`,
      19: `та підтримати життєвий тонус`,
      20: `Знизити ризик`,
      21: `розвитку серцево-судинних захворювань`,
      22: `Підтримати`,
      23: `загальне здоровʼя та благополуччя`,
      24: `Показати більше`,
      30: `Згорнути`,
      31: `Рекомендовано приймати:`,
      32: `По 1 капсулі на добу під час вживання їжі, особам старше 60 років — по 1 капсулі 2 рази на добу під час їжі,запиваючи достатньою кількістю води. Не перевищувати рекомендовану добову дозу.`,
      33: `Курс прийому — до 1 місяця, в подальшому термін застосування та можливість повторного курсу узгоджувати з лікарем.`,
      34: `Перед застосуванням необхідна консультація лікаря.`,
      35: `На 1 капсулу масою 700 мг (mg):`,
      36: `олія соняшникова – до 72,0 %, холекальциферол (вітамін D3) (Китай) – 0,1429 % (1000 МО), вітамін К2 - 0,0071 % (50 мкг (µg), допоміжні компоненти (желатин, гліцерин, метилпарабен (ніпагін), барвник «Азарубін», вода підготовлена) – 28,57 %.`,
    },
    productTabs: {
      items_amount: "30",
      1: `Замовити D3+K2 VitaIsland`,
      3: `капсул`,
      4: `упаковка`,
      5: `упаковки`,
      6: `Знижка на перше замовлення`,
      7: `за курс`,
      8: `зі знижкою`,
      10: `по 700мг`,
      11: `1 капсула на день`,
    },
    land_1: {
      1: `Що таке вітамін D3?`,
      2: `Вітамін D3`,
      3: `, також відомий як вітамін сонця, є одним з двох основних типів вітаміну D, що сприяє правильному функціонуванню організму. Він грає важливу роль у забезпеченні здоров'я кісток і зубів, регулюванні імунної системи, підтримці здоров'я серця та мозку, а також сприяє підвищенню рівня енергії та покращенню настрою.`,
      4: `Головним джерелом вітаміну D3 для людини є сонячне випромінювання, а також деякі продукти харчування, такі як жирна риба, яйця і молочні продукти. У дефіцит вітаміну D3 може призвести до різних проблем зі здоров'ям, включаючи остеопороз, слабкість кісток, імуносупресію та інші захворювання.`,
    },
    land_2: {
      1: `Низький рівень вітаміну D3 в Східній Європі`,
      2: `У східній Європі, включаючи Україну, низький рівень вітаміну D3 у населення зумовлений обмеженим доступом до сонячної погоди. Зазвичай лише протягом трьох місяців на рік можна спостерігати сонячні дні, тоді як усі інші періоди переважно відзначаються холодом та зимовими умовами. Це призводить до недостатнього синтезу вітаміну D3 в шкірі через відсутність достатньої кількості сонячного світла. Тому важливо включати в раціон додатковий прийом вітаміну D3+К2 для забезпечення потреб організму у цьому вітаміні, що має ключове значення для здоров'я.
`,
    },
    land_3: {
      1: `Як низький індекс D3 негативно впливає на організм?`,
      2: "Як низький індекс D3 негативно впливає на організм?",
      3: `Натуральність:`,
      4: `Наш колаген - це продукт, що виготовлений з використанням лише натуральних інгредієнтів. Ми пильно стежимо за походженням і якістю кожного компоненту, щоб забезпечити максимальну природність нашого продукту.`,
      5: `Висока якість:`,
      6: `Для нас важливо, щоб кожен стік колагену мав найвищу якість. Ми використовуємо передові технології та дотримуємося строгих стандартів виробництва, щоб забезпечити стабільність та надійність у кожному продукті.`,
      7: `Ефективність:`,
      8: `Наш колаген - це не лише пусті обіцянки. Його ефективність підтверджена клінічними дослідженнями та відгуками задоволених клієнтів. Він підтримує здоров'я шкіри, волосся та нігтів, сприяє зміцненню суглобів і робить вас відчутно молодшими та енергійнішими.`,
    },
    land_4: {
      1: `Навіщо K2?`,
      2: `Вітаміни D і K краще працюють у парі`,
      3: `Вітамін D3 та вітамін K2 часто поєднуються через їх взаємодію в організмі. Д3 сприяє поглинанню кальцію з кишечника та його зберіганню в кістках, але без вітаміну K2 кальцій може відкладатися в артеріях та м'язових тканинах, що може призвести до серцево-судинних захворювань та інших проблем зі здоров'ям. Крім того, вітамін K2 допомагає активувати білки, які регулюють рух кальцію в організмі, що сприяє його правильному розподілу та запобігає відкладенню у небажаних місцях. Таким чином, комбінування вітамінів D3 і K2 сприяє забезпеченню оптимального здоров'я кісток та серцево-судинної системи.`,
    },
    land_5: {
      1: `Не чекай сонця. Отримай D3 вже зараз від VitaIsland`,
      2: `Діюча речовина у складі D3+K2 - Холекальциферол`,
      3: `Холекальциферол`,
      4: `це форма вітаміну D, яка виробляється в організмі під впливом сонячних променів і є важливою для здоров'я кісток, м'язів і імунної системи.`,
      5: `Обираючи VitaIsland ви отримуєте найвищий вміст холекальциферолу, який є основною діючою речовиною у складі вітаміну D.`,
    },
    userComments: {
      name: {
        1: `Валерія`,
        2: `Єлизавета`,
        3: `Ольга`,
      },
      prof: {
        1: `Блогер`,
        2: `Тренер`,
        3: `Керівниця відділу повідомлень`,
      },
      comment: {
        1: [
          `«Д3+К2 від — це саме те, що потребує мій організм. Також була приємно здивована тим, що до кожної пачки докладений сертифікат якості. Дякую Vita Island»`,
        ],
        2: [
          `«Мій щирий вибір — д3+к2. Я обирала вітаміни по складу та знайшла для себе ідеальний варіант. Дякую Vita Island за мою нову корисну звичку»`,
        ],
        3: [
          `«Дякую Vita Island за чудову якість! Обрала для себе Д3+К2 і дуже задоволена ефектом. Буквально через тиждень прийому вже почала відчувати себе набагато краще»`,
        ],
      },
    },
    land_6: {
      1: `Покращення мʼязового росту`,
      2: `Вітаміни D3 і K2 є ключовими компонентами для підтримки здоров'я кісток. Вітамін D3 допомагає збільшити рівень кальцію в крові, що сприяє його кращій всмоктуванню з кишечника і захопленню кістками, тим самим підтримуючи їхню міцність і структуру. Однак, без належного метаболізму кальцію, його може накопичуватися у м'язах і судинах, що може призвести до утворення відкладень. Тут на допомогу приходить вітамін K2, який допомагає кальцію правильно розподілятися в організмі, сприяючи його відкладенню в кістках та запобігаючи його накопиченню в інших тканинах. Таким чином, комбінація вітамінів D3 і K2 є важливою для збереження здоров'я кісток, забезпечуючи їхню міцність і запобігаючи розвитку різних захворювань, пов'язаних з недостатнім рівнем кальцію в організмі.`,
    },
    land_7: {
      1: `Захист серця`,
      3: `Вітамін D3`,
      4: `сприяє зниженню рівня запальних процесів, які можуть призвести до пошкодження судин та розвитку серцево-судинних захворювань. Крім того, він допомагає знижувати артеріальний тиск і покращує функцію ендотелію, що зменшує ризик виникнення серцевих проблем.`,
      5: `Вітамін K2,`,
      6: `у свою чергу, допомагає зменшити відкладення кальцію у стінках артерій, що може призвести до атеросклерозу та інших серцево-судинних захворювань. Він активує білки, які регулюють відкладення кальцію в кістках і віддаляють його від судин, що сприяє здоров'ю серця та запобігає розвитку серцево-судинних ускладнень.`,
    },
    land_8: {
      1: `Психічне здоровʼя`,
      2: `Вітаміни D3 і K2 грають важливу роль у підтримці психічного здоров'я, особливо у ситуаціях стресу та тривалих періодів психологічного напруження. Вітамін D3 має прямий вплив на функціонування нервової системи та вироблення нейротрансмітерів, які регулюють настрій та емоційний стан. Недостатність вітаміну D3 може призводити до розвитку депресії, тривожності та інших психічних розладів.`,
      3: `У контексті, коли люди переживають постійний стрес та тривогу, підтримка психічного здоров'я стає особливо важливою. Вітаміни D3 і K2 можуть допомогти зменшити вплив стресу на психічне здоров'я та підтримати стійкість до психологічних навантажень. Їхнє вживання може сприяти покращенню настрою, зниженню рівня тривоги та забезпеченню загального психічного благополуччя.`,
    },
    land_9: {
      1: `Позитивний вплив D3 на організм в короткотривалій перспективі`,
      2: `Вітамін D3`,
      3: ` в короткостроковій перспективі може позитивно впливати на енергетичний рівень та загальний стан організму. Його прийом вранці разом зі склянкою прохолодної води може стимулювати прокидання і підняття настрою. Під впливом вітаміну D3 активується синтез серотоніну, нейромедіатора, що відповідає за підвищення настрою та енергії. Такий прилив енергії може допомогти вам почуватися більш бадьоро і зосереджено протягом дня.`,
    },
    land_10: {
      1: `Методи підвищення D3 природнім шляхом`,
      2: `Окрім сонця, є кілька природних способів підвищення рівня вітаміну D3 в організмі`,
      3: `Включення до раціону продуктів, багатих на вітамін D3, таких як жирна риба (лосось, сардина, тріска), яйця, гриби та збагачені продукти (молоко, сік), є найкращим способом підвищення рівня вітаміну D3 в організмі.`,
      4: `Незважаючи на те, що включення цих продуктів до вашого раціону може допомогти підвищити рівень вітаміну D3, часто цього недостатньо для досягнення потрібного рівня в організмі.`,
      5: `Отже, для досягнення оптимального рівня вітаміну D3 часто рекомендується додатково приймати певні вітамінні комплекси. Наприклад, від VitaIsland, який може стати ефективним варіантом для забезпечення організму всіма необхідними поживними речовинами.`,
    },
    faq: {
      answers: [
        `Ці вітаміни краще приймати з їжею, що містить жири, для максимального вбирання, оскільки вони є жиророзчинними .`,
        `В деяких випадках люди можуть відчути побічні ефекти, такі як запаморочення, слабкість, нудота або розлади ШКТ. Якщо це станеться, варто зменшити дозу або проконсультуватися з лікарем.`,
        ` Так, D3+K2 можна приймати разом з більшістю інших вітамінів та доповнень.`,
        ` В деяких випадках, якщо Ви приймаєте протикровотворні ліки, Вам може знадобитися консультація у лікаря перед прийманням K2.`,
        //
      ],
      questions: [
        `Коли краще приймати капсули D3+K2?`,
        `Чи можуть вітаміни спричинити побічні ефекти?`,
        `Чи можу я приймати D3+K2 разом з іншими вітамінами?`,
        `Чи є якісь обмеження на приймання вітамінів D3+K2?`,
        //
      ],
    },
  },
  // D3K2 kids
  5: {
    orderForm: {
      1: `Вітамін сонця для вашої`,
      2: `Дитини`,
      3: `D3+K2 VitaIsland`,
    },
    fixed_cart_button: {
      1: `D3+K2 kids`,
      2: ` 700мг з DHA-формулою`,
    },
    userComments: {
      name: {
        1: `Лариса`,
        2: `Юлія`,
        3: `Катерина`,
      },
      prof: {
        1: `Мама`,
        2: `Мама`,
        3: `Мама`,
      },
      comment: {
        1: [`Обожнюємо дитячий Д3+К2. Справжнє джерело енергії та сил для дитини. Дякую Vita Island`],
        2: [
          `Д3+К2 дитячий — ідеальний вибір для підтримки росту та розвитку моєї дитини. Доречі, дуже стильне та зручне пакування та головне — гарна якість`,
        ],
        3: [
          `Дякую Vita Island за такі чудові вітаміни! Приємно вражена такою відмінною якістю. Обрала для своєї дитини Д3+К2`,
        ],
      },
    },
    hero_section: {
      tags: ["Імунна система", "Кістки", "Психіка", "Гормони", "Метаболізм", "Робота мʼязів"],
      title: {
        1: `Вітамін сонця для вашої`,
        2: `дитини.`,
        3: ` D3+K2 VitaIsland`,
      },
      subtitle: { 1: "VitaIsland D3+K2 kids" },
      label: {
        1: `Важливий вітамінний дует на всіх етапах росту та розвику дитини`,
      },
      method: {
        1: `Дітям з 6 років по 1 капсулі на день після вживання їжі, запиваючи достатньою кількістю рідини.`,
        2: `Не перевищувати рекомендовану добову дозу. Курс прийому — до 30 днів.`,
        3: `Перед застосуванням рекомендована консультація лікаря`,
        //
      },
      composition: {
        1: `Склад на 1 капсулу масою 700 мг (mg): `,
        2: `омега-3, поліненасичені жирні кислоти (Німеччина) – 71,43 % (500,0 мг (mg)), допоміжні компоненти (желатин, гліцерин, сорбітол, метилпарабен (ніпагін), вода підготовлена) – 28,57 %.`,
      },
    },
    productTabs: {
      items_amount: "30",
      1: `Замовити D3+K2 kids`,
      2: `у капсулах 700мг`,
      3: `капсул`,
      4: `упаковка`,
      5: `упаковки`,
      6: `Знижка на перше замовлення`,
      7: `за курс`,
      8: `зі знижкою`,
      // 9: `по`,
      10: `по 700мг`,
      11: `1 капсула на день`,
    },
    land_1: {
      1: `D3+K2 kids`,
      2: `Головним джерелом вітаміну D3 для дітей є сонячне світло, але D3+K2 kids VitaIsland допоможе вашій дитині отримати необхідну кількість цього вітаміну, навіть не виходячи на вулицю.`,
      3: `Вітамін D3 є надзвичайно важливим для здоров'я малюків, оскільки він допомагає їх кісткам і зубам рости сильними, підтримує міцність імунної системи, а також сприяє нормальному розвитку серця та мозку.`,
    },
    land_2: {
      1: `В чому користь D3+K2`,
      2: `Науково доведено, що D3 `,
      3: `позитивно впливає на імунітет дитини.`,
      4: `Особливо у наймолодших, коли імунна система ще на стадії активного розвитку.`,

      5: `D3+K2 активно`,
      6: `підтримують кістково-мʼязову систему`,
      7: `, особливо в період активного зростання дитини`,

      8: `D3+K2 Kids від VitaIsland допомагає`,
      9: `підтримувати здоров'я дитячих зубів`,
      10: `, забезпечуючи їх правильний ріст та розвиток.`,

      11: `D3 має позитивний`,
      12: ` вплив на дитячу нервову систему`,
    },
    land_3: {
      1: `Як заохотити дитину приймати вітаміни`,
      2: `Лагідний спосіб переконати дитину приймати вітаміни - це включити їх у розваги та щоденні ритуали. Наприклад, робити прийом вітамінів частиною ранкової рутини або асоціювати їх з чимось приємним, наприклад, з улюбленим смаком або виграшною формою (наприклад, желе). Також важливо пояснити дитині користь вітамінів для її здоров'я та розвитку, створюючи позитивне ставлення до них. Важливо також бути прикладом для дітей: якщо вони бачать, що батьки приймають вітаміни, вони також будуть більш схильні до цього.`,
    },
    land_4: {
      1: `Наслідки відсутності D3+K2 в дитячому організмі`,
      2: `Без достатнього рівня вітамінів D3 та K2 дитя може стикатися з різними проблемами, що впливають на його фізичний і психічний стан.`,
      3: `Недостатність цих вітамінів може призвести до порушень росту та розвитку кісток, погіршення стану зубів, а також вплинути на функціонування серцево-судинної та імунної систем. `,
      4: `Батькам варто пам'ятати про важливість забезпечення дітей необхідною кількістю вітаміну D3 та K2 для збереження їхнього здоров'я та добробуту.`,
    },
    land_5: {
      1: `ВПЛИВ ВІТАМІНУ D НА ІМУНІТЕТ ДИТИНИ`,
      2: `Вітамін D грає важливу роль у підтримці імунітету дитини, активуючи імунну систему, збільшуючи вироблення антибіотиків та знижуючи запальні процеси в організмі. Це дозволяє зменшити частоту захворювань дитини, яка коливається від 12 до 15 разів на рік. Важлива також не тільки частота, а й швидкість одужання. Чим кращий імунітет дитини, тим швидше вона справляється із захворюваннями. Тому важливо забезпечувати свою дитину достатньою кількістю вітаміну D, який може бути отриманий як з вітамінних добавок, таких як від VitaIsland, так і природнім шляхом, за допомогою сонячного світла або харчових продуктів.`,
    },
    land_6: {
      1: `ПІДТРИМКА КІСТКОВО-М’ЯЗОВОЇ СИСТЕМИ`,
      2: `В період інтенсивного росту та розвитку, важливу роль відіграє вітамін D3.  Він сприяє освоєнню кальцію, необхідного для формування і зміцнення кісток, а також розвитку м’язової маси. Недостатність вітаміну D3 може призвести до розладів в рості і розвитку кісток, а також до слабкості м’язів та ризику травм під час активного фізичного навантаження. Щоб забезпечити оптимальне здоров’я кістково-м’язової системи, важливо регулярно вживати продукти або добавки, які містять вітамін D3.`,
    },
    land_7: {
      1: `ПІДТРИМКА ЗДОРОВ’Я ДИТЯЧИХ ЗУБІВ`,
      2: `Здорові зуби - це не лише приваблива посмішка, а й важлива складова загального здоров’я дитини. Вітамін D3 разом з вітаміном K2 відіграють ключову роль у зміцненні зубної емалі та підтримці здоров’я ясен. Вони сприяють освоєнню кальцію та фосфатів, необхідних для зміцнення зубів та попередження карієсу. Недостатність цих вітамінів може призвести до порушень в розвитку зубної системи та збільшити ризик розвитку карієсу та інших захворювань ясен.`,
    },
    land_8: {
      1: `ВПЛИВ ВІТАМІНУ D НА НЕРВОВУ СИСТЕМУ`,
      2: `Під час інтенсивного розвитку, на дитину впливають різні зовнішні фактори.`,
      3: `Вітамін D відіграє ключову роль у підтримці нервової системи дитини. Цей вітамін допомагає забезпечити нормальне функціонування нервових клітин та передачу імпульсів в мозку. Недостатність вітаміну D може призвести до порушень в роботі нервової системи, включаючи розлади настрою, втомленість та роздратованість. Підтримка нормального рівня вітаміну D в організмі дитини є важливою для збереження її емоційного та психічного здоров’я. `,
    },
    land_9: {
      1: `Продукти, які обов'язково додати в раціон дитини `,
      2: `Для забезпечення достатнього синтезу вітаміну D3 у дитячому організмі, важливо включати до раціону такі продукти:`,
      3: `Жирна риба: лосось, скумбрія, сардина, макрель містять значну кількість вітаміну D3.`,
      4: `Яйця: жовток містить велику кількість вітаміну D3, тому важливо включати їх у раціон дитини.`,
      5: `Молочні продукти: молоко, йогурт, сир містять вітамін D3, який допомагає забезпечити його нормальний рівень у дитячому організмі.`,
      6: `Фортифіковані продукти: деякі продукти, такі як сніданки з додатковим додаванням вітаміну D3, можуть бути корисним додатком до раціону дитини.`,
    },
    videoComments: {
      1: `Додай D3+K2 в свій щоденний раціон`,
    },
    faq: {
      questions: [
        `Чи безпечно для моєї дитини приймати D3+K2?`,
        `Яка доза D3+K2 є безпечною для моєї дитини?`,
        `Якщо моя дитина збалансовано харчується, чи варто їй приймати додаткові вітаміни D3+K2?`,
        `Як довго моя дитина повинна приймати D3 та K2?`,
        `Чи може прийом D3 та K2 спричинити побічні ефекти у моєї дитини?`,
        `Чи може моя дитина приймати D3 та K2 разом з іншими вітамінами?`,
        `Яка найкраща пора дня для прийому D3 та K2?`,
      ],
      answers: [
        `Так, зазвичай це безпечно. Однак рекомендується консультація з лікарем перед початком будь-якої програми доповнення`,
        `Величина дози може залежати від віку та ваги дитини. Зазвичай лікар може допомогти визначити, яка доза буде найбільш ефективною та безпечною.`,
        `Попри збалансоване харчування, деякі діти можуть не отримувати достатньо вітамінів D3 та K2, особливо в зимовий час. Варто обговорити це з лікарем.`,
        `Це залежить від індивідуальних потреб дитини. Ви повинні обговорити це з лікарем або дієтологом.`,
        `Побічні ефекти від прийому вітамінів D3 та K2 рідко зустрічаються, але якщо Ви помітите будь-які зміни у здоровʼї дитини, Вам слід негайно звернутися до лікаря.`,
        `Так, вони зазвичай можуть бути прийняті разом з іншими вітамінами, але Ви повинні спочатку проконсультуватися з лікарем`,
        `Вітаміни D3 та K2 є жиророзчинними, тому їх краще приймати з їжею, що містить жири. Вони можуть бути прийняті в будь-який час дня, але Вам слід дотримуватися рекомендацій лікаря або виробника.`,
      ],
    },
  },
  // omega
  6: {
    orderForm: {
      1: `Спробуйте Омега-3`,
      2: `і відчуйте помітний результат вже через 7 днів`,
    },
    fixed_cart_button: {
      1: `Омега-3`,
      2: `із тріски`,
      3: `1400мг з DHA-формулою`,
    },
    hero_section: {
      tags: ["Стрес", "Втома", "Шкіра", "Серце", "Поганий сон", "Памʼять", "Вагітність"],
      title: { 1: "Поповни дефіцит жирних", 2: "кислот з ", 3: "Омега-3 преміум", 4: "якості із Тріски" },
      subtitle: { 1: `Омега-3 `, 2: `із Тріски `, 3: `для жінок` },
      desire: {
        show_more: "Показати більше",
        show_less: "Згорнути",
        1: `Покращити сон`,
        2: ` та загальне самопочуття`,

        3: `Відчувати `,
        4: `життєву енергію `,
        5: `протягом всього дня`,

        6: `Підтримати `,
        7: `здоровʼя серця`,

        8: `Покращити `,
        9: `памʼять`,
        10: ` та `,
        11: `роботу мозку`,

        12: `Зміцнити суглоби`,

        13: `Підтримка розвитку плоду під час `,
        14: `вагітності`,
      },
      method: {
        1: `Рекомендовано приймати: `,
        2: `дорослим по 1-2 капсули на день під час вживання їжі, запивати достатньою кількістю рідини. Не перевищувати рекомендовану добову дозу.`,
        3: `Курс прийому — від 30 до 90 днів.`,
        4: `Перед застосуванням рекомендована консультація лікаря.`,
      },
      composition: {
        1: `Склад на 1 капсулу масою 1400 мг (mg): `,
        2: `омега-3, поліненасичені жирні кислоти (Німеччина) – 71,43 % (1000,0 мг (mg)), допоміжні компоненти (желатин, гліцерин, сорбітол, метилпарабен (ніпагін), вода підготовлена) – 28,57%.`,
      },
      // 3: ``,
      // 4: ``,
      // 5: ``,
      label: { 1: `1 капсула на день ( 1400мг з DHA-формулою )` },
      9: `Стрес, втома, тривога, нестабільний емоційний стан — час прощатися з ними!`,
      14: `Покращити сон`,
      15: `та загальне самопочуття`,
      16: `Відчувати`,
      17: `життєву енергію`,
      18: `протягом всього дня`,
      19: `Підтримати`,
      20: `здоровʼя серця`,
      21: `Показати більше`,
      22: `Покращити`,
      23: `памʼять`,
      24: `та`,
      25: `роботу`,
      26: `мозку`,
      27: `Зміцнити суглоби`,
      28: `Підтримка розвитку плоду під час`,
      29: `вагітності`,
      30: `Згорнути`,
      31: `Рекомендовано приймати:`,
      32: `дорослим по 1-2 капсули на день під час вживання їжі, запивати достатньою кількістю рідини. Не перевищувати рекомендовану добову дозу.`,
      33: `Курс прийому — від 30 до 90 днів.`,
      34: `Перед застосуванням рекомендована консультація лікаря.`,
      35: `Склад на 1 капсулу масою 1400 мг (mg):`,
      36: `омега-3, поліненасичені жирні кислоти (Німеччина) – 71,43 % (1000,0 мг (mg)), допоміжні компоненти (желатин, гліцерин, сорбітол, метилпарабен (ніпагін), вода підготовлена) – 28,57%.`,
    },
    productTabs: {
      items_amount: "30",
      1: `Замовити Omega-3`,
      2: `у капсулах 1400мг`,
      3: `капсул`,
      4: `упаковка`,
      5: `упаковки`,
      6: `Знижка на перше замовлення`,
      7: `за курс`,
      8: `зі знижкою`,
      // 9: `по`,
      10: `по 1400мг`,
      11: `1 капсула на день`,
    },
    land_1: {
      1: `Що таке Омега-3?`,
      2: `Це незамінні корисні жири, які надходять в організм разом із їжею. Наш організм їх не виробляє і не накопичує, але активно використовує.`,
      3: `Vitaisland Омега-3`,
      4: `це органічний риб'ячий жир з тріски та з DHA- формулою який допоможе компенсувати дефіцит жирних кислот та вітамінів групи А, Д, яких тобі не вистачає з їжею`,
      5: `Низький Індекс`,
      6: `Омега-3 в Україні`,
      7: `Низький`,
      8: `Середній`,
      9: `Достатній`,
    },
    land_2: {
      1: `Як низький рівень жирних кислот Омега-3 негативно впливає на організм`,
      2: `Дефіцит Омега-3 може спричинити ряд неприємних симптомів і проблем зі здоров'ям`,
      3: `Депресія та сон`,
      4: `Емоційне напруження, таке як стрес та погану якість сну.`,
      5: `Память та концентрація`,
      6: `Погана концентрація і проблеми з пам'яттю. Жирні кислоти відіграють критичну роль у функціонуванні мозку.`,
      7: `Серце`,
      8: `Ризик серцево-судинних захворюваннь, раптову серцеву смерть, інфаркти і гіпертонія.`,
      9: `Очі та зір`,
      10: `Чутливість очей і погане зорове сприйняття, оскільки жирні кислоти Омега-3 є життєво важливими для здоров'я сітківки.`,
      11: `Вагітність`,
      12: `Ризик передчасних пологів або ускладнень.`,
      13: `М'язи`,
      14: `Слабкість м'язів і погана циркуляція крові - наслідки, які можуть призводити до зменшення фізичної активності.`,
    },
    land_3: {
      1: `Думка експертів щодо Омега-3 з морської риби`,
      2: `Лікар, екс-міністр`,
      3: `Доктор Комаровський`,
      4: `Найкраще джерело жирних кислот - це Омега-3 з морської риби`,
      5: `Дієтолог`,
      6: `Ольга Безугла`,
      7: `Найцінніші ПНЖК Омега 3 містяться в морській рибі жирних сортів`,
      8: `Нутріціолог-дієтолог`,
      9: `Юлія Балашова`,
      10: `Як досвідчений нутриціолог, я наполягаю на включенні Омега-3 в раціон кожної людини`,
      11: `Як досвідчений нутриціолог, я наполягаю на включенні Омега-3 в раціон кожної людини:`,
      12: `Забезпечує здоров'я серця, мозку та суглобів.`,
      13: `Володіє протизапальними властивостями`,
      14: `Сприяє покращенню здоров'я шкіри, допомагаючи зменшити сухість та запалення`,
      15: `Підтримує здоров'я вагітної жінки. Допомагає знизити ризик депресії та тривожності у мами`,
    },
    land_4: {
      1: `Переваги Омега-3 з риби дикого вилову та тріски`,
      2: `У складі — 100% риб'ячий жир із тріски з найбільшою кількістю DHA-кислоти у природі — 25%`,
      3: `У чому сила DHA?`,
      4: `DHA-кислота`,
      5: `сприяє гостроті зору, допомагає будувати клітини та нейрони, завдяки чому поліпшується загальна функція мозку і пам'ять.`,
      6: `Нестача DHA`,
      7: `призводить до погіршення пам'яті та концентрації уваги, підвищує ризик розвитку хвороби Альцгеймера.`,
    },
    land_5: {
      1: `Преміум якість та приємний смак морської риби`,
      2: `Відчуй приємний рибний смак Омега-3 від Vitaisland та переконайся у якості та натуральності продукту`,
      3: `Сировина - з Німеччини`,
      4: `виготовлена за вищими технологічними стандартами`,
      5: `Пройшла 6 етапів очищення`,
      6: `від ртуті та важких металів`,
      7: `Засвоюваність — понад 90%`,
      8: `завдяки натуральній (тригліцеридній) формі`,
      9: `Капсули 1400мг`,
      10: `це одна таблетка на добу замість 2х або 4х`,
    },
    userComments: {
      name: {
        1: `Валерія`,
        2: `Божена`,
        3: `Лана`,
        4: `Юлія Балашова`,
      },
      prof: {
        1: `Модель, керівник модельної школи «Unique Model project»`,
        2: `Косметолог, засновниця косметологічної клініки - центру здорової шкіри «Kosmeya»`,
        3: `Візажист, бʼюті блогер`,
        4: `Нутріціолог-дієтолог`,
      },
      comment: {
        1: [
          `«Омега 3 — моя улюблена корисна звичка зранку. Турбота та піклування про своє здоровʼя разом з VitaIsland — must have для мене»`,
        ],
        2: [
          `«Колаген та Омегу спершу протестувала особисто, дуже сподобався смак та я була в захваті від результату. Зараз бренд Vita Island представлений у моїй клініці та ми залюбки рекомендуємо його нашим клієнтам»`,
        ],
        3: [
          `«Ніщо так не допомагає моїй шкірі тримати тонус 24/7, як Омега від Vita Island. Процес вживання Омега-3 в капсулах дуже простий, а смак риби просто неймовірний. Доречі, щоразу дивуюсь цьому неймовірному упакуванню. Приваблює з першого погляду»`,
        ],
        4: [
          `«Омега-3 Vita Island — справжнє джерело необхідних вітамінів для кожної жінки, яка прагне зберегти здоровʼя шкіри, волосся та нігтів. Ідеально для тих, хто не добирає жирних кислот в своєму організмі».`,
        ],
      },
    },
    land_6: {
      1: `Омега-3 та шкіра`,
      2: `Омега-3 піклується про здоров'я твоєї шкіри:`,
      3: `Зволожує шкіру зсередини і робить її більш пружною`,
      4: `Зменшує кількість вугрів на 42%, що доведено дослідженнями SNU`,
      5: `Прискорює загоєння ран, перешкоджає розвитку шкірних алергій`,
      6: `Сприяє покращенню стану при псоріазі, екземі та дерматиті`,
    },
    land_7: {
      1: `Омега-3 для краси твого волосся`,
      2: `Прискорює ріст волосся`,
      3: `Робить волосся блискучим`,
      4: `Запобігаює випадінню`,
      5: `Знижуює запальні процеси`,
    },
    land_8: {
      1: `Корисить для мами і малюка`,
      2: `Прийом DHA особливо важливий у період вагітності й лактації`,
      3: `Користь DHA для мами:`,
      4: `Позитивно впливає на розвиток плоду`,
      5: `Знижує ризик передчасних пологів`,
      6: `Запобігає післяпологовій депресії`,
      7: `Користь DHA для малюка:`,
      8: `Допомагає у формуванні мозку, ЦНС і сітківки ока`,
      9: `Знижує ризик СДУГ`,
      10: `Покращує розумовий розвиток`,
      11: `Зменшує ризик харчової алергії`,
    },
    land_9: {
      1: `Сбалансоване відношення Омега-3, DHA та EPA`,
      2: `Щоденна порція (1 капсула) містить 1243 мг риб’ячого жиру, включаючи 686 мг омега-3 жирних кислот, 329 мг EPA і 228 мг DHA.`,
      3: `Ця комбінація забезпечує підтримку функцій організму та сприяє загальному здоров'ю.`,
      4: `DHA впливає на роботу мозку, зору і рівню ліпідів (тригліцеридів) в крові, а EPA підтримує протизапальні процеси, покращує настрій і полегшує симптоми депресії.`,
      5: `Разом DHA та EPA сприяють позитивній роботі серця та підтримці нормального артеріального тиску та рівня холестерину.`,
    },
    land_10: {
      1: `Чи споживаєте ви 1.4 кг риби на тиждень?`,
      2: `Жирна риба, така як тріска, є відмінним джерелом Омега-3 жирних кислот.`,
      3: `Необхідно споживати приблизно 1,4 кг такої риби, щоб задовільнити щоденну норму, що практично неможливо.`,
      4: `Щоб уникнути дефіциту Омега-3, рекомендована добова доза становить 250-500 мг DHA + EPA.`,
      5: `Цього можна легко досягти, приймаючи одну капсулу Омега-3 від Vitailand, оскільки вони містить загалом 1243 мг риб’ячого жиру, включаючи 686 мг омега-3 жирних кислот, 329 мг EPA і 228 мг DHA.`,
    },
    land_11: {
      1: `Омега-3 та спорт`,
      2: `Омега-3 відіграють ключову роль у відновленні, роботі м'язів і регуляції запальних процесів.`,
      3: `Омолодження м’язів і тканин`,
      4: `Скорочення часу відновлення`,
      5: `Зміцнення серцево-судинної системи`,
      6: `Підтримує здоров'я суглобів`,
      7: `Сприятливий вплив омега-3 поширюється на омолодження м’язів і тканин після фізичних навантажень, що потенційно призводить до скорочення часу відновлення між тренуваннями.`,
      8: `Крім того, він сприяє зміцненню серцево-судинної системи і підтримує здоров'я суглобів.`,
      9: `Таким чином, наші капсули Vitaisland Омега-3 є ідеальною добавкою для людей, які прагнуть покращити свої спортивні результати та загальний стан здоров'я.`,
    },
    fitnessUserComments: {
      1: `Відгуки наших клієнтів із фітнесу`,
      2: `Олександра`,
      3: `тренер-нутріціолог`,
      4: `«Обрала для себе Омегу-3, колаген та мультивітамін для жінок. Саме те, що потрібно для підтримки організму при заняттях спортом та в цілому для підтримки жіночого здоровʼя»`,
      5: `Єлизавета`,
      6: `тренер`,
      7: `«Мій щирий вибір — омега 3 та д3+к2. Я обирала вітаміни по складу та знайшла для себе ідеальний варіант. Дякую Vita Island за мою нову корисну звичку»`,
    },
    videoComments: {
      1: `Додай Омега-3 в свій щоденний раціон`,
    },
    faq: {
      answers: [
        `У деяких випадках люди можуть відчувати незначний дискомфорт в шлунку або "рибний" присмак. Якщо ці симптоми стають проблематичними, зверніться до лікаря.`,
        `Якщо Ви регулярно їсте жирну рибу Ви можете отримати достатнью кількість омега-3 з Вашої дієти. Однак, якщо Ви не включаєте достатньо риби в свою дієту, омега-3 доповнення можуть бути корисними.`,
        `Так, Ви можете приймати омега-3 разом з більшістю інших вітамінів або доповнень. Проте, якщо Ви прийматєте ліки, Вам слід проконсультуватися з лікарем або фармацевтом.`,
        `Омега-3 жирні кислоти важливі для розвитку мозку та нервової системи плода. Проте, вагітні або годуючі жінки повинні проконсультуватися з лікарем перед прийомом будь-яких доповнень.`,
        `Немає прямого доказу того, що омега-3 впливає на вагу. Однак омега-3 може сприяти загальному здоров'ю серця та обміну речовин, що може мати позитивний вплив на вагу в контексті здорого харчування та активного способу життя.`,
      ],
      questions: [
        "Чи може прийом Омега-3 спричинити якісь побічні ефекти?",
        "Чи потрібно мені приймати Омега-3, якщо я їм багато риби?",
        "Чи можу я приймати Омега-3 разом з іншими вітамінами або добавками?",
        "Чи безпечно приймати Омега-3 під час вагітності або годування груддю?",
        "Чи вплине прийом Омега-3 на мою вагу?",
      ],
    },
  },
  // omega kids
  7: {
    orderForm: {
      1: `VitaIsland`,
      2: `Omega-3 kids.`,
      3: `І ваша дитина в безпеці`,
    },
    fixed_cart_button: {
      1: `Омега-3 `,
      2: `для дітей`,
    },
    userComments: {
      name: {
        1: `Катерина`,
        2: `Ольга`,
        3: `Яна`,
      },
      prof: {
        1: `Мама`,
        2: `Мама`,
        3: `Мама`,
      },
      comment: {
        1: [
          `Дитяча Омега 3 — саме те, що потрібно моїй дитині для підтримки розвитку та здоровʼя. Дякую Vita Island за чудову якість`,
        ],
        2: [
          `Омега 3 дитяча чудовий помічник для покращення памʼяті, відчули ефект буквально після тижня прийому. Дякую`,
        ],
        3: [
          `Дуже задоволена якістю Омега 3 від Vita Island. Окрема подяка менеджеру, що відповів на усі питання та за швидкісну доставку`,
        ],
      },
    },
    hero_section: {
      tags: ["Імунна система", "Кістки", "Психіка", "Гормони", "Метаболізм", "Робота мʼязів"],
      title: {
        1: `VitaIsland Omega-3 kids.`,
        2: " І ваша дитина в безпеці",
      },
      subtitle: { 1: "VitaIsland Omega-3 kids" },
      method: {
        1: `Дітям з 6 років по 1 капсулі на день після вживання їжі, запиваючи достатньою кількістю рідини.`,
        2: `Не перевищувати рекомендовану добову дозу. Курс прийому — до 30 днів.`,
        3: `Перед застосуванням рекомендована консультація лікаря`,
        //
      },
      composition: {
        1: `Склад на 1 капсулу масою 700 мг (mg): `,
        2: `омега-3, поліненасичені жирні кислоти (Німеччина) – 71,43 % (500,0 мг (mg)), допоміжні компоненти (желатин, гліцерин, сорбітол, метилпарабен (ніпагін), вода підготовлена) – 28,57 %.`,
      },
      label: { 1: `Належне живлення для забезпечення здорового фізичного та психічного розвитку дитини.` },
    },
    productTabs: {
      items_amount: "30",
      1: `Замовити Omega-3`,
      2: `у капсулах 700мг`,
      3: `капсул`,
      4: `упаковка`,
      5: `упаковки`,
      6: `Знижка на перше замовлення`,
      7: `за курс`,
      8: `зі знижкою`,
      // 9: `по`,
      10: `по 700мг`,
      11: `1 капсула на день`,
    },
    land_1: {
      1: `Що таке Омега-3?`,
      2: `Це спеціально розроблені вітаміни для дітей, які містять корисні жирні кислоти, такі як`,
      3: `EPA`,
      4: `(еікозапентаєнова кислота) та`,
      5: `DHA`,
      6: `(докозагексаєнова кислота). Ці жирні кислоти є ключовими компонентами для розвитку мозку, зростання кісток та покращення зору у дітей.`,
      7: `Омега-3 kids`,
      8: `відповідають особливим потребам дитячого організму, забезпечуючи їх необхідними жирними кислотами для оптимального здоров'я та розвитку`,
    },
    land_2: {
      1: `В чому користь Омега-3`,
      2: `Для наймолодших:`,
      3: `Здорове харчування від самого народження є особливо важливим для розвитку дитини. Дитячий організм швидко росте, і Омега-3 допомагає забезпечити оптимальні умови для цього.`,
      4: `Забезпечує правильне формування мозкових клітин.`,
      5: `Підтримує здоров'я очей та зміцнює імунну систему.`,
      6: `Сприяє швидкому фізичному росту та розвитку організму.`,
      7: `Для школярів:`,
      8: `Омега-3 допомагає виконувати одну з найважливіших умов успішного дня для школяра,  бути активними та концентрованими протягом усього дня.`,
      9: `Забезпечує необхідний рівень енергії та стимулює активність.`,
      10: `Покращує когнітивні функції, такі як розуміння та аналіз інформації.`,
      11: `Підтримує емоційний стан та сприяє зниженню рівня стресу.`,
      12: `Для юнаків:`,
      13: `Для юнаків, які переживають період швидкого фізичного та емоційного росту, важливою стає підтримка організму жирними кислотами Омега-3.`,
      14: `Знижує ризик розвитку серцевих захворювань.`,
      15: `Покращує стан суглобів та зміцнює кісткову тканину.`,
      16: `Забезпечує підтримку серцево-судинної системи та здоров'я організму.`,
    },
    land_3: {
      1: `Омега-3 підтримує імунну систему дитини`,
    },
    land_4: {
      1: `Як заохотити дитину приймати вітаміни`,
      2: `Лагідний спосіб переконати дитину приймати вітаміни - це включити їх у розваги та щоденні ритуали. Наприклад, робити прийом вітамінів частиною ранкової рутини або асоціювати їх з чимось приємним, наприклад, з улюбленим смаком або виграшною формою (наприклад, желе). Також важливо пояснити дитині користь вітамінів для її здоров'я та розвитку, створюючи позитивне ставлення до них. Важливо також бути прикладом для дітей: якщо вони бачать, що батьки приймають вітаміни, вони також будуть більш схильні до цього.`,
    },
    land_5: {
      1: `Які результати можна очікувати від вживання Омега-3`,
      2: `Покращення функціонування мозку та нервової системи, що може проявитися в покращенні пам'яті, концентрації та кoгнітивних навичок.`,
      3: `Омега-3 сприяє зниженню запалення в організмі, що може позитивно впливати на стан шкіри, волосся та нігтів, роблячи їх більш здоровими та привабливими.`,
      4: `Покращення функціонування серцево-судинної системи, зниження ризику розвитку захворювань серця та судин, а також покращення зорової функції.`,
      5: `Дитина може відчути збільшення енергії та витривалості, що сприятиме активному способу життя та успішному заняттю фізичною активністю.`,
    },
    land_6: {
      1: `Продукти, які обов'язково додати в раціон дитини `,
      2: `Для забезпечення додаткового синтезу омега-3 у дитини варто включити до її раціону такі продукти:`,
      3: `Жирна риба, така як лосось, сардини, анчоуси та макрель, які є відмінним джерелом незамінних жирних кислот омега-3.`,
      4: `Лляне насіння та олія, які містять альфа-ліноленову кислоту, що перетворюється в омега-3 жирні кислоти в організмі.`,
      5: `Квасоля, соєві продукти та горіхи, які також містять альфа-ліноленову кислоту.`,
      6: `Яйця, особливо від курей, які харчуються кормом збагаченим омега-3.`,
      7: `Зелене листя, таке як шпинат, броколі та капуста, яке також може містити невелику кількість омега-3 жирних кислот.`,
      8: `Ці продукти можна легко включити в раціон дитини, щоб забезпечити їй необхідну кількість омега-3 для здоров'я та нормального розвитку.`,
    },
    videoComments: {
      1: `Додай Омега-3 в свій щоденний раціон`,
    },
    faq: {
      questions: [
        `Який найкращий час для прийому капсул?`,
        `Чи може прийом Омега-3 спричинити якісь побічні ефекти у дітей?`,
        `Чи можу я дати Омега-3 своїй дитині, якщо вона алергічна на рибу?`,
        `Чи допоможе прийом Омега-3 покращити навчальну успішність моєї дитини?`,
        `Чи допоможе Омега-3 моїй дитині під час спортивних занять?`,
        `Чи може Омега-3 допомогти моїй дитині, яка страждає від АДГД?`,
      ],
      answers: [
        `Рекомендовано приймати Омега-3 після їжі для кращого засвоєння.`,
        `У деяких випадках діти можуть відчувати незначний дискомфорт в шлунку або “рибний” присмак. Якщо ці симптоми стають проблематичними, варто звернутися до педіатра. `,
        `Деякі діти, які алергічні на рибу, можуть безпечно приймати Омега-3, але це варто обговорити з лікарем.`,
        `Омега-3 важлива для нормального розвитку мозку і може сприяти когнітивним функціям, але вона не гарантує покращення навчальної успішності. Регулярне споживання здорової їжі, достатній сон і фізична активність також важливі для навчальної успішності.`,
        `Омега-3 може сприяти загальному здоровʼю та благополуччю, але вона не є специфічним “спортивним” доповненням. Все ж, вона може підтримувати здоровʼя серця і судин, що є важливимдля фізичної активності.`,
        `Деякі дослідження показують, що Омега-3 може покращувати симптоми АДГД, але це потребує більше наукових досліджень. Завжди звертайтеся до лікаря, перед тим, як давати дитині будь-які доповнення.`,
      ],
    },
  },
  // multivitamin-men
  8: {
    orderForm: {
      1: `Обирай`,
      2: ` VitaIsland Multivitamin`,
      3: ` - Твоя потужна вітамінна підтримка для справжніх чоловіків`,
    },
    fixed_cart_button: {
      1: `Multivitamin `,
      2: `для чоловіків`,
    },
    hero_section: {
      tags: ["Енергія", "Імунітет", "Сила", "Серце", "Продуктивність", "Тестостерон", "Робота мʼязів"],
      1: {
        1: "Обирай ",
        2: "VitaIsland",
        3: "Multivitamin",
        4: " - твоя потужна",
        5: "вітамінна підтримка для",
        6: "справжніх чоловіків",
      },
      3: `Мультивітамін для справжніх чоловіків`,
      6: `Вітамінний комплекс для повноцінного забезпечення організму необхідними елементами`,
      14: `Підвищити витривалість`,
      15: ` під час тренувань`,
      17: `Покращити концентрацію та фокус`,
      18: `під час робочого дня`,
      19: `Швидше відновлюватися`,
      20: `після напруженого дня`,
      21: `Показати більше`,
      22: `Зміцнити кістки та суглоби`,
      23: ` для активного способу життя`,
      24: `Стабілізувати рівень тестостерону`,
      25: `Підвищити лібідо`,
      26: ` шляхом стимулювання синтезу тестостерону`,
      30: `Згорнути`,

      31: `Рекомендовано приймати:`,
      32: `чоловікам по 1 капсулі на добу після вживання їжі, запиваючи невеликою кількістю рідини. Не перевищувати рекомендовану добову дозу. `,
      33: `Курс прийому — 2-3 місяці.`,
      34: `При необхідності термін споживання можна повторювати кілька разів на рік. За призначенням лікаря добова доза і курс прийому можуть бути змінені.`,
      35: `Перед застосуванням необхідна консультація лікаря.`,

      36: `Склад на 1 капсулу масою 400 мг (mg): `,
      37: `емульгатор мальтодекстрин – 44,0 %, крохмаль картопляний – 30,0 %, вітамінна суміш (Франція) – 10,0 % (40,0 мг (mg)), мінеральна суміш (Франція) – 10,0 % (40,0 мг (mg)), цинку оксид (Перу) – 5,0 % (20,0 мг (mg)), наповнювач кальцію стеарат – 1,0 %.`,
    },
    productTabs: {
      items_amount: "30",
      1: `Замовити Multivitamin`,
      2: `для чоловіків`,
      3: `капсул`,
      4: `упаковка`,
      5: `упаковки`,
      6: `Знижка на перше замовлення`,
      7: `за курс`,
      8: `зі знижкою`,
      10: `по 400мг`,
      11: `1 капсула на день`,
    },
    land_1: {
      1: `Чоловічий мультивітамін від VitaIsland`,
      2: `Це ключ до твоєї маскулінності та активного життя. Завдяки унікальній формулі, він допоможе тобі досягти найкращих результатів у всіх сферах життя. Від підвищення енергетики та витривалості під час тренувань до підтримки здоров'я серця, кровоносної системи та суглобів. Цей мультивітамін підтримає твою імунну систему, покращить концентрацію та фокус, допоможе відчувати себе на висоті кожного дня. А ще, він сприятиме підвищенню лібідо та виробленню тестостерону - ключових факторів чоловічого здоров'я та витривалості. Обирай мультивітамін від VitaIsland і відчуй силу свого потенціалу!`,
    },
    land_2: {
      1: `Чи варто чоловікам обирати Multivitamin від VitaIsland?`,
      2: `Що відбувається з чоловічим організмом, коли йому не вистачає вітамінів`,
      3: `Зниження рівня енергії:`,
      4: `Недостатній приплив необхідних вітамінів може призвести до втоми та відчуття слабкості протягом дня.`,
      5: `Порушення функції імунної системи: `,
      6: `Недостатність вітамінів може призвести до ослаблення імунітету, збільшуючи ризик захворювань та інфекцій.`,
      7: `Загроза здоров'ю серця:`,
      8: `Недостатність певних вітамінів, таких як вітамін D та магній, може погіршити функцію серця та збільшити ризик серцевих захворювань.`,
      9: `Погіршення стану шкіри, волосся та нігтів:`,
      10: `Недостатність вітамінів у чоловіків також може призвести до сухості шкіри, ламкості волосся та нігтів, а також до інших проблем зі шкірою, таких як запалення та подразнення.`,
    },
    land_3: {
      1: `Що таке Multivitamin VitaIsland?`,
      2: `Що міститься в одній капсулі вітамінного комплексу`,
      3: `Вітамінна суміш з Франції`,
      4: `Спеціально розроблений для чоловіків, цей вітамінний комплекс поєднує в собі найкращі складові, які допомагають підтримувати здоров'я та енергію сучасного чоловіка.`,
      5: `Мінеральна суміш з Франції`,
      6: `Забезпечує високу якість та ефективність за допомогою використання натуральних інгредієнтів`,
      7: `100% натуральність`,
      8: `Немає шкідливих хімічних сполук, які можуть тимчасово ліквідувати прояви авітамінозу, не вирішуючи його основної проблеми`,
      9: `Не містить алергени`,
      10: `Сумісний з АД, антибіотиками, іншими вітамінними комплексами`,
    },
    land_4: {
      1: `Переваги мультивітамінного комплексу для чоловіків від VitaIsland, над аналогами на ринку `,
      2: `Збалансований склад:`,
      3: ` Мультивітамінний комплекс від VitaIsland містить оптимальне співвідношення вітамінів та мінералів, спеціально підібране для чоловіків, що допомагає забезпечити повноцінне функціонування організму.`,
      4: `Підтримка імунітету:`,
      5: `Даний комплекс містить вітаміни С та D, які сприяють підвищенню імунітету та захисту від різних інфекційних захворювань.`,
      6: `Підтримка рівня тестостерону:`,
      7: `Мультивітамінний комплекс містить складові, які сприяють підтримці оптимального рівня тестостерону в організмі чоловіка, що важливо для здоров'я та витривалості.`,
      8: `Підвищення лібідо:`,
      9: `Деякі складові вітамінно-мінерального комплексу можуть підтримувати здоров'я сексуальної функції та підвищувати рівень лібідо, що сприяє задоволенню та покращує якість інтимного життя.`,
      10: `Доступність та зручність:`,
      11: `Зручний формфактор робить вживання мультивітаміну простим і зручним, навіть у самих активних і напружених розкладах.`,
    },
    land_5: {
      1: `Підвищення рівня тестостерона разом з Multivitamin `,
      3: `Зміцнення фізичної витривалості:`,
      4: `Високий рівень тестостерону, підтримуваний Multivitamin, сприяє підвищенню енергії та стійкості під час фізичних навантажень.`,
      5: `Покращення м'язового зросту: `,
      6: `Високий рівень тестостерону сприяє швидшому відновленню м'язів після тренувань та підтримує процеси м'язового зросту.`,
      7: `Підтримка здорового стану шкіри та волосся: `,
      8: `Високий рівень тестостерону може сприяти збереженню здорової шкіри та волосся, зменшуючи втрату волосся та покращуючи їхній зовнішній вигляд.`,
      9: `Збільшення сексуального бажання:`,
      10: `Високий рівень тестостерону може підтримувати здорову сексуальну функцію та підвищувати сексуальне бажання та задоволення.`,
    },
    userComments: {
      name: {
        1: `Олександр`,
        2: `Максим`,
        3: `Дмитро`,
      },
      prof: {
        1: `Майстер спорту зі стронгмену, тренер`,
        2: `Тренер`,
        3: ``,
      },
      comment: {
        1: [
          `«Замовив Д3+К2 та мультивітамін для чоловіків. Саме те, що потрібно для того, щоб домогти своєму організму тримати себе у тонусі та відновлюватися після тренувань»`,
        ],
        2: [
          `«Те, що щиро раджу для підтримки чоловічого здоровʼя — це мутивітаміни. Особливо, коли ведете активний спосіб життя. Дякую Vita Island за допомогу»`,
        ],
        3: [
          `«Мій надійний помічник для підтримки чоловічого здоровʼя. Вже після тижня почав відчувати ефект, дякую Vita Island за чудову якість продукції»`,
        ],
      },
    },
    land_6: {
      1: `Збільшення лібідо`,
      2: `Multivitamin: Підтримка твого сексуального бажання на потрібному рівні`,
      3: `Збільшення лібідо - важлива мета для багатьох. Мультивітамінний комплекс від VitaIsland спеціально розроблений з урахуванням потреб чоловіків. Він містить в собі натуральні компоненти, які допомагають підтримувати здоров'я та підвищувати енергію. Його склад включає важливі вітаміни та мінерали, такі як вітаміни групи В, цинк і магній, які сприяють підвищенню лібідо та покращенню сексуального функціонування. Це важливо не лише для задоволення власних потреб, але й для збереження загального фізичного та психічного здоров'я, оскільки сексуальний потяг прямо пропорційно пов'язаний зі здоров'ям людини.`,
    },
    land_7: {
      1: `Топ вітамінів і мінералів, які найчастіше є дефіцитними в організмі людини`,
      2: `Щодня ми повинні отримувати певну кількість вітамінів та мінералів, і щоб вони справді засвоювалися, вони повинні надходити у наш організм з їжею. Але є певні корисні речовини, яких багато людей не отримують у достатній кількості. Розповідаємо, які вітаміни та поживні речовини у дефіциті у більшості з нас.`,
      3: `Калій`,
      4: `Недолік калію в організмі — вірний шлях до порушень роботи серця, нирок і навіть м'язів. Калію багато у броколі, помідорах, картоплі, цитрусових, бананах і сухофруктах. І пам'ятайте: якщо ви приймаєте препарати для здоров'я серця або нирок, вам у жодному разі не можна приймати добавки калію.`,
      5: `Жирні кислоти`,
      6: `Жирні кислоти омега-3 — це секретна зброя, яка допомагає зберігати молодість і здоров'я наших судин, але, на жаль, багатьом з нас їх не вистачає. Найбільше цих корисних кислот у горіхах, олії, рибі жирних сортів, а також у яйцях і морепродуктах.`,
      7: `Вітамін С`,
      8: `Цей вітамін, звичайно, не здатний повністю позбавити нас від застуд, але він зміцнює імунну систему, а також захищає серце. Як не дивно, деякі з нас не отримують необхідні 75 мг вітаміну С на день. Якщо ви вважаєте, що це ваш випадок, їжте солодкий перець, апельсини, ківі, броколі, полуницю і брюссельську капусту.`,
      9: `Вітамін D`,
      10: `Навіть якщо ви живете у місцевості, де багато сонця, у вас все одно може бути дефіцит вітаміну D. Якщо ж темна зима і сутінкова осінь — ваше звичне середовище існування, вам варто перевірити рівень "сонячного" вітаміну. Дефіцит можна заповнити, якщо їсти курячі яйця, лосось або тунець і сир.`,
      11: `Залізо`,
      12: `Якщо ви дотримуєтеся вегетаріанського раціону, у вас дуже високий ризик дефіциту заліза, яке необхідне для нормального зростання і розвитку, а також для крові. Заліза багато у яловичині, хлібі, крупах, а також бобових — бобах, нуті, сочевиці.`,
      13: `Вітамін Е`,
      14: `15 мг вітаміну Е на добу допомагають підтримати нашу імунну систему, а також впоратися з хронічними захворюваннями. Цього вітаміну багато у горіхах і насінні, а також у кукурудзяній, соняшниковій та соєвій олії`,
    },
    land_8: {
      1: `Неможливо поповнити вітамінний запас лише з їжі`,
      2: `Вітаміни та мінерали - це життєво важливі поживні речовини, необхідні для забезпечення правильної роботи організму. Багато з них ми отримуємо з їжі, особливо якщо дотримуємось збалансованої дієти, багатої на фрукти, овочі, м'ясо та інші корисні продукти.`,
      3: `Однак, часто важко забезпечити достатню кількість всіх необхідних вітамінів і мінералів лише за рахунок їжі. `,
      4: `Це може бути спричинено низьким вмістом поживних речовин у продуктах через обробку, недостатню їх різноманітність у дієті або інші фактори. Тому часто рекомендується вживати вітамінні комплекси, такі, як`,
      5: ` VitaIsland Multivitamin `,
      6: `або добавки, щоб забезпечити організм всіма необхідними поживними речовинами.`,
    },

    land_9: {
      1: `Покращення мʼязового росту`,
      2: `Покращення м'язового росту - це ключова мета для багатьох чоловіків, які займаються фізичними вправами або спортом. Мультивітамінний комплекс від VitaIsland допомагає досягти цієї мети, забезпечуючи організм всіма необхідними вітамінами та мінералами. Вітаміни групи В, що містяться в комплексі сприяють ефективному метаболізму білків, жирів і вуглеводів, що є важливим для росту м'язової маси. Крім того, наявність мінералів, таких як цинк і магній, сприяє оптимальному функціонуванню м'язів і сприяє їхньому швидшому відновленню після тренувань. Такий комплекс допомагає забезпечити організм всіма необхідними ресурсами для покращення м'язового росту, що робить його незамінним помічником у досягненні спортивних та фізичних цілей.`,
    },
    videoComments: {
      1: `Додай Multivitamin в свій щоденний раціон`,
    },
    faq: {
      answers: [
        `Рекомендовано приймати одну капсулу на день, враховуючи індивідуальну потребу організму`,
        `Зазвичай він добре переноситься, але в рідкісних випадках можуть виникнути незначні шлунково-кишечні розлади. Якщо ви відчуваєте будь-які симптоми, зверніться до лікаря.`,
        `Якщо у Вас єє алергія або непереносимість до будь-якого з інгредієнтів, Вам слід уникнути вживання цього продукту або звернутися за порадою до лікаря.`,
        `Вітамінно-мінеральний комплекс може покращити загальне здоровʼя та енергетику, але він не повинен розглядатися як прямий спосіб підвищення спортивної продуктивності.`,
        `Не існує доказів, що прийом цього комплексу безпосередньо впливає на якість сну, але збалансований внесок вітамінів і мінералів може поліпшити загальний стан здоровʼя і добробут.`,
        //
      ],
      questions: [
        `Як часто я повинен приймати цей комплекс?`,
        `Які можливі побічні ефекти від прийому цього вітамінно-мінерального комплексу?`,
        `Чи можу я приймати цей комплекс, якщо у мене є алергія на деякі вітаміни або мінерали?`,
        `Чи покращить цей комплекс мою спортивну продуктивність?`,
        `Чи вплине прийом цього комплексу на мій сон?`,
      ],
    },
  },
  // multivitamin-women
  9: {
    orderForm: {
      1: `Щоденний вітамінний коктейль для української жінки з`,
      2: ` VitaIsland Multivitamin`,
    },
    fixed_cart_button: {
      1: `Multivitamin `,
      2: `для жінок`,
    },
    hero_section: {
      tags: ["Енергія", "Імунітет", "Шкіра", "Серце", "Якісний сон", "Метаболізм", "Робота мʼязів"],
      1: {
        1: "Обирай ",
        2: "VitaIsland",
        3: "Multivitamin",
        4: " - твоє",
        5: "щоденне джерело вітамінів",
        6: "та енергії",
      },
      3: `Жіночий мультивітамін`,
      6: `комплексний підхід до нестачі вітамінів для жіночого організму`,
      14: `Полегшити прояви`,
      15: `передменструального синдрому`,
      17: `Підвищити стресостійкість`,
      18: `організму`,
      20: `Покращити працездатність`,
      21: `Показати більше`,
      23: `Зміцнити суглоби`,
      26: `Сповільнити процес`,
      27: `старіння шкіри`,
      28: `Підтримати імунну`,
      29: `і репродуктивну систему`,
      30: `Згорнути`,
      31: `Рекомендовано приймати:`,

      32: `жінкам по 1 капсулі на добу після вживання їжі, запиваючи невеликою кількістю рідини. Не перевищувати рекомендовану добову дозу. `,
      33: `Курс прийому — 2-3 місяці.`,
      34: `При необхідності термін споживання можна повторювати кілька разів на рік. За призначенням лікаря добова доза і курс прийому можуть бути змінені.`,
      35: `Перед застосуванням необхідна консультація лікаря.`,

      36: `Склад на 1 капсулу масою 400 мг (mg): `,
      37: `екстракт валеріани – 12,5 % (50,0 мг (mg)), екстракт шишок хмелю - 7,5 % (30,0 мг (mg)), екстракт циміцифуги - 6,25 % (25,0 мг (mg)), екстракт діоскореї – 6,25 % (25,0 мг (mg)), аскорбінова кислота  - 6,25 % (25,0 мг (mg)), вітамін В6 – 2,5 % (10,0 мг (mg)), ніацин  - 2,5 % (10,0 мг (mg)), наповнювач кальцію стеарат – 2,0 %, вітамін В1  – 1,25 % (5,0 мг (mg)), вітамін В2 – 1,25 % (5,0 мг (mg)), вітамін Е  – 1,25 % (5,0 мг (mg)), антиспікаючий агент аеросил – 1,0 %, вітамін А – 0,575 % (2,3 мг (mg)), вітамін D3 – 0,05 % (200,0 мкг (µg)), селенмітеонін – 0,031 % (124,0 мкг (µg)), фолієва кислота – 0,0125 % (50,0 мкг (µg)).`,
    },
    productTabs: {
      items_amount: "30",
      1: `Замовити Multivitamin`,
      2: `для жінок`,
      3: `капсул`,
      4: `упаковка`,
      5: `упаковки`,
      6: `Знижка на перше замовлення`,
      7: `за курс`,
      8: `зі знижкою`,
      10: `по 400мг`,
      11: `1 капсула на день`,
    },
    land_1: {
      1: `Multivitamin для жінок`,
      2: `Жіночий мультивітамін, що здатний розв'язати проблему нестачі вітамінів, яка характерна для сучасної жінки.`,
    },
    land_2: {
      1: `Що має відчувати сучасна українська жінка, коли організм потребує вітамінів?`,
      2: `Вітамінний дефіцит може спричинити низку серйозних проблем зі здоровʼям`,
      3: `Зниження імунітету: `,
      4: `Важливо забезпечити свій організм вітамінами, щоб він міг краще захищати вас від хвороб та інфекцій.`,
      5: `Погіршення здоров'я шкіри, волосся та нігтів:`,
      6: `Вітаміни допомагають зберігати природну красу вашої шкіри, волосся та нігтів, забезпечуючи їх зволоженість та міцність.`,
      7: `Проблеми із зором:`,
      8: `Вітаміни, зокрема вітамін А, важливі для здоров'я очей і можуть допомогти зберегти гостроту зору.`,
      9: `Проблеми з пам'яттю та концентрацією:`,
      10: `Вітаміни групи В сприяють збереженню здоров'я мозку, що може покращити вашу пам'ять та здатність до концентрації.`,
    },
    land_3: {
      1: `Що таке Multivitamin VitaIsland?`,
      2: `Що міститься в одній капсулі вітамінного комплексу`,
      3: `Вітамінна суміш з Франції`,
      4: `Комплекс вітамінної суміші для жінок, який був розроблений в Європі, з урахуванням всіх потреб української жінки`,
      5: `Мінеральна суміш з Франції`,
      6: `Необхідна для правильного виконання чисельних фізіологічних процесів жінки`,
      7: `100% натуральність`,
      8: `Немає шкідливих хімічних сполук, які можуть тимчасово ліквідувати прояви авітамінозу, не вирішуючи його основної проблеми`,
      9: `Не містить алергени`,
      10: `Сумісний з АД, протизапальними препаратами, антибіотиками, іншими вітамінними комплексами`,
    },
    land_4: {
      1: `Переваги мультивітамінного комплексу для жінок від VitaIsland, над аналогами на ринку`,
      2: `Комплексність складу: `,
      3: `Продукт включає широкий спектр вітамінів і мінералів, необхідних для здоров'я жінок, таких як вітаміни А, C, D, E, групи B, кальцій, залізо та інші. Це дозволяє задовольнити потреби організму в нутрієнтах і підтримує загальний стан здоров'я.`,
      4: `Дозування під жіночий організм: `,
      5: `Формула розроблена з урахуванням особливостей жіночого організму, забезпечуючи оптимальні дози вітамінів і мінералів для забезпечення здоров'я серця, кісток, шкіри, волосся та імунної системи.`,
      6: `Якість і безпека: `,
      7: `Продукт виготовлений з використанням високоякісних і безпечних інгредієнтів, які відповідають всім стандартам якості та сертифікації.`,
      8: `Підтримка здоров'я: `,
      9: `Регулярне приймання мультивітамінного комплексу сприяє зміцненню імунної системи, підвищенню енергетичного рівня, зменшенню ризику захворювань та покращенню загального самопочуття.`,
      10: `Доступність та зручність: `,
      11: `Зручний формфактор, що дозволяє легко брати необхідну дозу в будь-яку сумочку, в будь-який час.`,
    },
    land_5: {
      1: `Безболісний період менструації з Multivitamin`,
      // 2: `Відчуй приємний рибний смак Омега-3 від Vitaisland та переконайся у якості та натуральності продукту`,
      3: `Зниження більових симптомів: `,
      4: `Multivitamin від VitaIsland містить в собі комплекс вітамінів та мінералів, які сприяють зменшенню більових відчуттів під час менструації. Особливо важливі в цьому вітамін Е та магній, які відомі своїми здатностями зменшувати спазми та напругу м'язів.`,
      5: `Підтримка ендокринної системи: `,
      6: `Регулярне приймання Multivitamin сприяє балансуванню рівня гормонів в організмі, що може зменшити інтенсивність і тривалість менструальних симптомів, таких як біль, розлади настрою та головні болі.`,
      7: `Загальне покращення самопочуття: `,
      8: `Вітаміни групи В, які входять до складу Multivitamin, сприяють підвищенню рівня енергії та зниженню втоми, що допомагає зберегти активність та життєвий тонус протягом цього періоду.`,
      9: `Підтримка здоров'я крові: `,
      10: `Залізо та вітамін С, які містяться у Multivitamin, сприяють покращенню кровообігу та запобігають анемії, що може виникати внаслідок втрати крові під час менструації.`,
    },
    userComments: {
      name: {
        1: `Поліна`,
        2: `Дарʼя`,
        3: `Аліна`,
      },
      prof: {
        1: `SMM, спеціаліст`,
        2: ``,
        3: `Власниця фітнес студії Fitness by Grek, тренерка`,
      },
      comment: {
        1: [
          `«Мультивітамін жіночий — це справжня підтримка моєї енергії та здоровʼя. Відчула ефект вже в перші 7 днів»`,
        ],
        2: [`«Улюблені мультивітаміни. Приймаю регулярно та безмежно задоволена результатом»`],
        3: [
          `«Мультивітамінний комплекс – чудовий помічник для кожної жінки, яка прагне підтримувати своє здоровʼя та бути у формі. Раджу усім нашим клієнтам»`,
        ],
      },
    },
    land_6: {
      1: `Профілактика вірусних та інфекційних захворювань`,
      2: `Профілактика вірусних та інфекційних захворювань - ключовий аспект збереження здоров'я та благополуччя, особливо в періоди підвищеної захворюваності. Відомо, що різні віруси та інфекції можуть стати серйозними загрозами для організму, особливо для людей зі слабким імунітетом або у вразливих груп населення, таких як діти, літні люди та вагітні жінки.`,
      3: `Мультивітаміни та мінерали від VitaIsland можуть стати надійним помічником у підтримці імунітету та запобіганні захворювань. Збалансоване поєднання вітамінів, мінералів та антиоксидантів допомагає організму бути міцним та стійким до впливу різних інфекційних агентів.`,
    },
    land_7: {
      1: `Топ вітамінів і мінералів, які найчастіше є дефіцитними в організмі людини`,
      2: `Щодня ми повинні отримувати певну кількість вітамінів та мінералів, і щоб вони справді засвоювалися, вони повинні надходити у наш організм з їжею. Але є певні корисні речовини, яких багато людей не отримують у достатній кількості. Розповідаємо, які вітаміни та поживні речовини у дефіциті у більшості з нас.`,
      3: `Калій`,
      4: `Недолік калію в організмі — вірний шлях до порушень роботи серця, нирок і навіть м'язів. Калію багато у броколі, помідорах, картоплі, цитрусових, бананах і сухофруктах. І пам'ятайте: якщо ви приймаєте препарати для здоров'я серця або нирок, вам у жодному разі не можна приймати добавки калію.`,
      5: `Жирні кислоти`,
      6: `Жирні кислоти омега-3 — це секретна зброя, яка допомагає зберігати молодість і здоров'я наших судин, але, на жаль, багатьом з нас їх не вистачає. Найбільше цих корисних кислот у горіхах, олії, рибі жирних сортів, а також у яйцях і морепродуктах.`,
      7: `Вітамін С`,
      8: `Цей вітамін, звичайно, не здатний повністю позбавити нас від застуд, але він зміцнює імунну систему, а також захищає серце. Як не дивно, деякі з нас не отримують необхідні 75 мг вітаміну С на день. Якщо ви вважаєте, що це ваш випадок, їжте солодкий перець, апельсини, ківі, броколі, полуницю і брюссельську капусту.`,
      9: `Вітамін D`,
      10: `Навіть якщо ви живете у місцевості, де багато сонця, у вас все одно може бути дефіцит вітаміну D. Якщо ж темна зима і сутінкова осінь — ваше звичне середовище існування, вам варто перевірити рівень "сонячного" вітаміну. Дефіцит можна заповнити, якщо їсти курячі яйця, лосось або тунець і сир.`,
      11: `Залізо`,
      12: `Якщо ви дотримуєтеся вегетаріанського раціону, у вас дуже високий ризик дефіциту заліза, яке необхідне для нормального зростання і розвитку, а також для крові. Заліза багато у яловичині, хлібі, крупах, а також бобових — бобах, нуті, сочевиці.`,
      13: `Вітамін Е`,
      14: `15 мг вітаміну Е на добу допомагають підтримати нашу імунну систему, а також впоратися з хронічними захворюваннями. Цього вітаміну багато у горіхах і насінні, а також у кукурудзяній, соняшниковій та соєвій олії`,
    },
    land_8: {
      1: `Неможливо поповнити вітамінний запас лише з їжі`,
      2: `Вітаміни та мінерали - це життєво важливі поживні речовини, необхідні для забезпечення правильної роботи організму. Багато з них ми отримуємо з їжі, особливо якщо дотримуємось збалансованої дієти, багатої на фрукти, овочі, м'ясо та інші корисні продукти.`,
      3: `Однак, часто важко забезпечити достатню кількість всіх необхідних вітамінів і мінералів лише за рахунок їжі. `,
      4: `Це може бути спричинено низьким вмістом поживних речовин у продуктах через обробку, недостатню їх різноманітність у дієті або інші фактори. Тому часто рекомендується вживати вітамінні комплекси, такі, як`,
      5: ` VitaIsland Multivitamin `,
      6: `або добавки, щоб забезпечити організм всіма необхідними поживними речовинами.`,
    },
    videoComments: {
      1: `Додай Multivitamin в свій щоденний раціон`,
    },
    faq: {
      answers: [
        `Ми рекомендуємо приймати вітаміни вранці після сніданку, щоб мінімізувати можливі проблеми зі шлунком`,
        `У випадку вагітності або грудного вигодовування радимо спочатку проконсультуватися з лікарем.`,
        `Це залежить від конкретного лікарського засобу. Деякі вітаміни або ліки можуть взаємодіяти з компонентами цього комплексу, тому перед початком прийому краще проконсультуватися з лікарем.`,
        `Хоча алергічні реакції на вітаміни рідкі, якщо у Вас виникають симптоми алергії, зверніться до лікаря.`,
      ],
      questions: [
        `Яка пора краща для прийому вітамінів?`,
        `Чи можу я приймати цей комплекс, якщо я вагітна або годую грудьми?`,
        `Чи можна приймати цей комплекс разом з іншими вітамінами або ліками?`,
        `Чи може виникнути алергічна реакція на ці вітаміни?`,
      ],
    },
  },
  // complex_10
  10: {
    orderForm: {
      1: `Комлекс для волосся та шкіри`,
      2: ` від VitaIsland`,
    },
    fixed_cart_button: {
      1: `D3+K2 + COLLAGEN + OMEGA-3`,
      2: ` Вітамінний комплекс`,
    },
    hero_section: {
      title: { 1: `Комлекс для волосся та`, 2: ` шкіри`, 3: ` від VitaIsland` },
      subtitle: {
        1: `D3+K2 + Collagen + Omega-3`,
      },
      tabs: {
        1: "за комплекс",
        2: "зі знижкою",
      },
    },
    land_1: {
      1: `Комлекс для волосся та шкіри`,
      2: `Цей комплекс був ретельно зібраний з урахуванням потреб здоров'я волосся та шкіри. У складі комплексу міститься унікальне поєднання вітамінів та поживних речовин, які націлені на оптимальне підтримання їхньої відмінної форми. Вітаміни, які входять до складу, відіграють роль у зміцненні волосся та сприяють наданню шкірі більш живого і здорового вигляду.`,
      3: `D3+K2`,
      4: `Сприяє регенерації та зменшенню запалення`,
      5: `Collagen`,
      6: `Запобігаює появі зморшок та ламкості волосся`,
      7: `Omega-3`,
      8: `Забезпечує необхідними живильними речовинами`,
    },
    land_2: {
      1: `Чому погіршується стан шкіри та волосся?`,
      2: `D3+K2`,
      3: `Недостатність вітаміну D3 може призвести до сухості та лущення шкіри, а також до втрати блеску волосся. Брак цього вітаміну може призвести до зниження вироблення меланіну, що впливає на колір та здоров'я волосся. Тому важливо забезпечити організм необхідною кількістю вітаміну D3, щоб підтримувати здоров'я шкіри та волосся.`,
      4: `Collagen`,
      5: `З віком вироблення колагену в організмі зменшується, що може призвести до втрати пружності шкіри та появи зморшок. Також, недостатність колагену може призвести до слабкості та ламкості волосся. Приймання колагену у вигляді добавку може допомогти підтримати здоров'я шкіри та волосся, зменшуючи ознаки старіння та покращуючи їхній зовнішній вигляд.`,
      6: `Омега-3`,
      7: `Недостатність омега-3 може призвести до сухості та лущення шкіри, а також до ламкості та посіченості волосся. Крім того, вони можуть впливати на запалення шкіри, що може призвести до появи акне та інших проблем. Приймання омега-3 жирних кислот у вигляді добавку може покращити стан шкіри та волосся, зменшуючи запалення та забезпечуючи необхідні живильні речовини.`,
    },
    land_3: {
      why: "Навіщо?",
      1: `Що всередині комплексу`,
      3: `Морський колаген з риби дикого вилову`,
      4: `Морський колаген - це унікальний білок, який має доведені корисні властивості для зміцнення та омолодження шкіри, а також покращення структури та вигляду волосся.`,
      5: `Морський колаген, завдяки своїй специфічній структурі, допомагає зберегти зволоження та еластичність шкіри, що робить її більш м'якою, гладкою та пружною.`,
      6: `Крім того, він стимулює регенерацію тканин, сприяючи швидкому оновленню клітин шкіри та волосся. Це дозволяє підтримувати їхню здоровість та молодість протягом тривалого часу.`,
      7: `Вітамін сонця D3`,
      8: `Вітамін D3, відомий як вітамін сонця, має велике значення для здоров'я та краси шкіри та волосся. Цей вітамін відіграє ключову роль у підтримці здоров'я шкіри шляхом стимулювання вироблення природного колагену та еластину, які відповідають за її пружність та молодість.`,
      9: `Додатково, вітамін D3 має запальнозахисні властивості, що сприяють зменшенню запалення шкіри та підтримці її здорового стану. Це особливо важливо для людей з проблемами шкіри, такими як акне або розтяжки.`,
      10: `Жирні кислоти Омега-3`,
      11: `Жирні кислоти Омега-3, зокрема ейкозапентаєнова (ЕПК) та докозагексаєнова (ДГК), є важливими компонентами здорового харчування та підтримки краси та здоров'я шкіри та волосся. Ці жирні кислоти відіграють ключову роль у підтримці гідратації, захисті від запалення та збереженні молодість шкіри, а також у зміцненні волосся та покращенні його структури.`,
    },
    land_4: {
      1: `Якість VitaIsland`,
      2: `Кожна наша продукція підлягає строгому контролю якості від початкового етапу до готового продукту, щоб ви могли бути впевнені в їхній ефективності та безпеці.`,
      3: `100% натуральність`,
      4: `Немає шкідливих хімічних сполук, які можуть тимчасово ліквідувати прояви авітамінозу, не вирішуючи його основної проблеми`,
      5: `Сертифікована продукція`,
      6: `Всі наші продукти пройшли сертифікацію та відповідають найвищим стандартам якості та безпеки. Наша сертифікована продукція підтверджується відповідними органами і гарантує вам, що ви отримуєте лише найкращі продукти для підтримки вашого здоров'я та благополуччя.`,
      7: `Не містить алергени`,
      8: `Сумісний з АД, антибіотиками, іншими вітамінними комплексами`,
    },
    userComments: {
      name: {
        1: `Аліна`,
        2: `Поліна`,
        3: `Анна`,
      },
      prof: {
        1: `Власниця фітнес студії Fitness by Grek, тренерка`,
        2: `SMM, спеціаліст`,
        3: `Тренер`,
      },
      comment: {
        1: [
          `«Мені особисто дуже сподобався даний комплекс. Також Vita Island представлені у нашому спорт хабі і ми постійно отримуємо безліч задоволених відгуків від клієнтів, які придбали комплекси. Дякуємо Vita Island за чудову якість»`,
        ],
        2: [
          `«Турбуватися про своє здоровʼя комплексно — найкраще рішення. Відчуваю покращення самопочуття вже у перші тижні після прийому. Дякую за відмінний сервіс та преміальну якість»`,
        ],
        3: [
          `«Дуже сподобався комплекс, буду брати і надалі, а також рекомендувати своїм клієнтам. Дякую за гарну якість!»`,
        ],
      },
    },

    videoComments: {
      1: `Додай Комплекс для волосся та шкіри в свій щоденний раціон`,
    },
  },
  // complex_11
  11: {
    orderForm: {
      1: `Комплекс для підтримки імунітету`,
      2: ` від VitaIsland`,
    },
    fixed_cart_button: {
      1: `Multivitamin + Omega`,
      2: ` Вітамінний комплекс`,
    },
    hero_section: {
      title: { 1: `Комплекс для підтримки `, 2: ` імунітету`, 3: ` від VitaIsland` },
      subtitle: {
        1: `Omega-3 + Multivitamin`,
      },
      tabs: {
        1: "за комплекс",
        2: "зі знижкою",
      },
    },
    land_1: {
      1: `Комлекс для підтримки імунної системи`,
      2: `Цей комплекс був спеціально зібраний для підтримки та посилення імунної системи організму. Вітаміни, що входять до складу, можуть допомогти зміцнити оборонні механізми тіла та забезпечити йому кращу стійкість проти хвороб і вірусів.`,
      3: `Підтримка імунітету - ключовий аспект здоров'я, тому цей вітамінний комплекс може бути важливим допоміжним засобом для збереження вашого організму здоровим та стійким до захворювань.`,
      4: `Omega-3`,
      5: `Забезпечує необхідними живильними речовинами`,
      6: `Multivitamin for women`,
      7: `Забезпечує необхідними елементами, з урахуванням особливостей жіночого організму`,
      8: `Multivitamin for men`,
      9: `Забезпечує необхідними елементами, з урахуванням особливостей чоловічого організму`,
    },
    land_2: {
      1: `Навіщо підтримувати імунну систему?`,
      2: `Підтримка імунної системи вітамінами Омега-3`,
      3: `та мультивітамінним комплексом має кілька важливих переваг.`,
      4: `Multivitamin`,
      5: `Мультивітамінний комплекс забезпечує організм необхідними вітамінами та мінералами, які підтримують здоров'я імунної системи, таких як вітамін C, D та цинк. Регулярне приймання цих вітамінів сприяє збільшенню стійкості організму до інфекцій та інших шкідливих факторів, що допомагає підтримувати загальне здоров'я та благополуччя.`,
      6: `Омега-3`,
      7: `Омега-3 жирні кислоти допомагають зменшити запалення в організмі, що сприяє підтримці оптимального функціонування імунної системи. Вони також регулюють вироблення протизапальних речовин, які можуть бути важливими для боротьби з інфекціями та хворобами.`,
    },
    land_3: {
      why: "Навіщо?",
      1: `Що всередині комплексу`,
      2: `Жирні кислоти Омега-3`,
      3: `Омега-3 жирні кислоти є важливою складовою здорового харчування, зокрема у контексті підтримки імунної системи. Ці жирні кислоти, такі як EPA (еікозапентаєнова кислота) та DHA (докозагексаєнова кислота), відомі своїми протизапальними властивостями, які впливають на різні аспекти імунної cистеми організму. Омега-3 сприяють зниженню запалення та підтримують баланс між запальними та протизапальними процесами, що є ключовим для оптимального функціонування імунної системи.`,
      4: `Мультивітамінний комплекс`,
      5: `Multivitamin for women відіграє критичну роль у забезпеченні необхідних поживних речовин для здоров'я та оптимального функціонування жіночого організму. Цей комплекс містить різноманітні вітаміни, мінерали та антиоксиданти, які необхідні для підтримки імунної системи та здоров'я в цілому. Вітаміни групи В, вітамін C та вітамін E допомагають зміцнювати імунну відповідь організму, знижуючи ризик вірусних та бактеріальних захворювань.`,
      6: `Multivitamin for men відіграє ключову роль у забезпеченні необхідних поживних речовин для здоров'я та оптимального функціонування організму чоловіків. Цей комплекс містить широкий спектр вітамінів, мінералів та антиоксидантів, які сприяють підтримці сильного імунітету та загального здоров'я. Вітаміни групи В, вітамін C та вітамін E відіграють важливу роль у підтримці імунної відповіді організму, допомагаючи зменшити ризик захворювань і забезпечуючи оптимальне функціонування імунної системи.`,
    },
    land_4: {
      1: `Якість VitaIsland`,
      2: `Кожна наша продукція підлягає строгому контролю якості від початкового етапу до готового продукту, щоб ви могли бути впевнені в їхній ефективності та безпеці.`,
      3: `100% натуральність`,
      4: `Немає шкідливих хімічних сполук, які можуть тимчасово ліквідувати прояви авітамінозу, не вирішуючи його основної проблеми`,
      5: `Сертифікована продукція`,
      6: `Всі наші продукти пройшли сертифікацію та відповідають найвищим стандартам якості та безпеки. Наша сертифікована продукція підтверджується відповідними органами і гарантує вам, що ви отримуєте лише найкращі продукти для підтримки вашого здоров'я та благополуччя.`,
      7: `Не містить алергени`,
      8: `Сумісний з АД, антибіотиками, іншими вітамінними комплексами`,
    },
    userComments: {
      name: {
        1: `Ольга`,
        2: `Лана`,
        3: `Катерина`,
      },
      prof: {
        1: `Керівниця відділу повідомлень`,
        2: `Візажист, бʼюті блогер`,
        3: `Мама`,
      },
      comment: {
        1: [
          `«Придбала комплекс для підтримки імунітету і залишилася дуже задоволеною! Дякую менеджеру за допомогу у виборі та за швидку доставку»`,
        ],
        2: [`«Дуже дякую за неймовірний комплекс! Швидко відчула ефект та окрема подяка за таке естетичне пакування»`],
        3: [
          `«Обрала для себе комплекс від Vita Island і залишилася неймовірно задоволена! Відмінна якість, зручне пакування, всім рекомендую»`,
        ],
      },
    },

    videoComments: {
      1: `Додай Комплекс для підтримки імунітету в свій щоденний раціон`,
    },
  },
  // complex_12
  12: {
    orderForm: {
      1: `Набір “Сімейний”`,
      2: ` від VitaIsland`,
    },
    fixed_cart_button: {
      1: `Family complex`,
      2: ` d3+k2 kids + omega-3 + multivitamin m + multivitamin w`,
    },
    hero_section: {
      title: { 1: `Набір “Сімейний”`, 2: ` від VitaIsland` },
      subtitle: {
        1: `D3+K2 + Omega-3 (for kids) Multivitamin Man + Multivitamin Women`,
      },
      tabs: {
        1: "за комплекс",
        2: "зі знижкою",
      },
    },
    land_1: {
      1: `Один набір для всієї сімʼї`,
      2: `Сімейний комплекс вітамінів може бути особливо корисним, коли кожен член сім'ї має власні дієтичні потреби і стилі життя. Він може сприяти підтримці енергії, імунітету, здоров'я шкіри, волосся та нігтів у всіх членів сім'ї.`,
      3: `Враховуючи активний ритм життя сімей, цей вітамінний набір може бути важливим кроком до підтримки загального здоров'я та відчуття добробуту всієї родини.`,
      4: `D3+K2`,
      5: `Сприяє регенерації та зменшенню запалення`,
      6: `Omega-3 `,
      7: `Запобігаює появі зморшок та ламкості волосся`,
      8: `Multivitamin for MEN`,
      9: `Забезпечує необхідними елементами, з урахуванням особливостей чоловічого організму`,
      10: `Multivitamin for WOMEN`,
      11: `Забезпечує необхідними елементами, з урахуванням особливостей жіночого організму`,
    },
    land_2: {
      1: `Важливість спільного прийому вітамінів`,
      2: `Важливо приймати вітаміни всій сім'ї одночасно, оскільки це сприяє створенню здорового середовища для всіх членів сім'ї та забезпечує оптимальний рівень поживних речовин для кожного.`,
      3: `Одночасне прийняття вітамінів всією сім'єю сприяє утворенню ритуалу, що спонукає до здорового способу життя, а також допомагає уникнути пропускання прийому.`,
      4: `Крім того, взаємне підтримка та мотивація всіх членів сім'ї може стимулювати більшу дисципліну і послідовність у прийомі вітамінів, що в свою чергу сприяє покращенню загального здоров'я і благополуччя всієї родини.`,
    },
    land_3: {
      why: "Навіщо?",
      1: `Що всередині комплексу`,
      2: `D3+K2 (kids)`,
      3: `Відіграє важливу роль у зміцненні кісток та імунної системи. Цей дует вітамінів сприяє правильному розвитку кісток та зубів, а також підтримує оптимальний рівень імунітету, допомагаючи боротися з інфекціями та захворюваннями.`,
      4: `Omega-3 (kids)`,
      5: `Омега-3 жирні кислоти - це важлива частина здорового харчування, особливо для малечі. ЕПА (еікозапентаєнова кислота) та ДГК (докозагексаєнова кислота), які входять до складу омега-3, мають протизапальні властивості. Вони допомагають знизити запалення в організмі та підтримують його імунну систему, захищаючи від захворювань та інфекцій.`,
      6: `Мультивітамінний комплекс М`,
      7: `Multivitamin for men відіграє ключову роль у забезпеченні необхідних поживних речовин для здоров'я та оптимального функціонування організму чоловіків. Цей комплекс містить широкий спектр вітамінів, мінералів та антиоксидантів, які сприяють підтримці сильного імунітету та загального здоров'я. Вітаміни групи В, вітамін C та вітамін E відіграють важливу роль у підтримці імунної відповіді організму, допомагаючи зменшити ризик захворювань і забезпечуючи оптимальне функціонування імунної системи.`,
      8: `Мультивітамінний комплекс Ж`,
      9: `Multivitamin for women відіграє критичну роль у забезпеченні необхідних поживних речовин для здоров'я та оптимального функціонування жіночого організму. Цей комплекс містить різноманітні вітаміни, мінерали та антиоксиданти, які необхідні для підтримки імунної системи та здоров'я в цілому. Вітаміни групи В, вітамін C та вітамін E допомагають зміцнювати імунну відповідь організму, знижуючи ризик вірусних та бактеріальних захворювань.`,
    },
    land_4: {
      1: `Якість VitaIsland`,
      2: `Кожна наша продукція підлягає строгому контролю якості від початкового етапу до готового продукту, щоб ви могли бути впевнені в їхній ефективності та безпеці.`,
      3: `100% натуральність`,
      4: `Немає шкідливих хімічних сполук, які можуть тимчасово ліквідувати прояви авітамінозу, не вирішуючи його основної проблеми`,
      5: `Сертифікована продукція`,
      6: `Всі наші продукти пройшли сертифікацію та відповідають найвищим стандартам якості та безпеки. Наша сертифікована продукція підтверджується відповідними органами і гарантує вам, що ви отримуєте лише найкращі продукти для підтримки вашого здоров'я та благополуччя.`,
      7: `Не містить алергени`,
      8: `Сумісний з АД, антибіотиками, іншими вітамінними комплексами`,
    },
    userComments: {
      name: {
        1: `Яна`,
        2: `Катерина`,
        3: `Ольга`,
      },
      prof: {
        1: `Мама`,
        2: `Мама`,
        3: `Мама`,
      },
      comment: {
        1: [
          `«Обрала для себе комплекс щоб підтримувати здоровʼя та відповідний рівень енергії. Через декілька тижнів вже помітно відчувала зниження стресу та втоми. Тож щиро рекомендую та дякую за гарну якість!»`,
        ],
        2: [
          `«Дякую за класні вітаміни та неймовірно гарне та зручне пакування. Окремо відмічу менеджера, який допоміг підібрати необхідні вітаміни під мій запит та чітко відповів на всі мої питання»`,
        ],
        3: [
          `«Дуже раджу приймати саме цей комплекс. Мабуть, наразі найкращий, який я купувала. Відмінне поєднання вітамінів для підтримки здоровʼя. Окремо дякую за те, що поклали сертифікацію якості та за приємний подарунок до замовлення»`,
        ],
      },
    },

    videoComments: {
      1: `Додай Комплекс для всієї родини в щоденний раціон`,
    },
  },
};
// complex_10
// Аліна, власниця спорт залу Fitness by Grek
// «Мені особисто дуже сподобався даний комплекс. Також Vita Island представлені у нашому спорт хабі і ми постійно отримуємо безліч задоволених відгуків від клієнтів, які придбали комплекси. Дякуємо Vita Island за чудову якість»

// complex_10
// Поліна, SMM-спеціаліст
// «Турбуватися про своє здоровʼя комплексно — найкраще рішення. Відчуваю покращення самопочуття вже у перші тижні після прийому. Дякую за відмінний сервіс та преміальну якість»

// complex_10
// Анна, тренер
// «Дуже сподобався комплекс, буду брати і надалі, а також рекомендувати своїм клієнтам. Дякую за гарну якість!»

// complex_11
// Ольга, керівниця відділу повідомлень
// «Придбала комплекс для підтримки імунітету і залишилася дуже задоволеною! Дякую менеджеру за допомогу у виборі та за швидку доставку»

// complex_11
// Лана, візажист, бʼюті блогер
// «Дуже дякую за неймовірний комплекс! Швидко відчула ефект та окрема подяка за таке естетичне пакування»

// complex_11
// Катерина, мама
// «Обрала для себе комплекс від Vita Island і залишилася неймовірно задоволена! Відмінна якість, зручне пакування, всім рекомендую»

// complex_12

// complex_12

// complex_12
