import axios from "axios";
import router from "@/router/router";
import store from "@/store/store";
import { getLocale } from "@/utils/utils";
let active_requests = 0;
export default {
  async GET_FROM_API_USER({ commit }, { ...args }) {
    if (active_requests === 0) {
      // console.log("start load");
      commit("SHOW_LOADER");
    }
    active_requests++;
    try {
      const response = await axios(`${this.getters.API}${args.src}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: {
          lang: this.getters.LOCALE,
          ...args.data,
        },
      });
      if (response.data.status !== 0) {
        console.log(`Oops!`);
        console.log(response);
      } else {
        if (args.mutation) commit(args.mutation, response.data.data);
      }
      active_requests--;
      if (active_requests === 0) {
        //   console.log("end load");
        commit("HIDE_LOADER");
      }
      return response;
    } catch (error) {
      console.log(error);
      active_requests--;
      if (active_requests === 0) {
        //   console.log("end load");
        commit("HIDE_LOADER");
      }
      return error;
    }
  },
  async SEND_USER({ commit }, { ...args }) {
    if (active_requests === 0) {
      // console.log("start load");
      commit("SHOW_LOADER");
    }
    active_requests++;
    try {
      const response = await axios(`${this.getters.API}${args.src}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: {
          lang: this.getters.LOCALE,
          ...args.data,
        },
      });
      if (response.data.status !== 0) {
        console.log(`Oops!`);
        console.log(response);
      } else {
        if (args.mutation) commit(args.mutation, response.data.data);
      }
      active_requests--;
      if (active_requests === 0) {
        //   console.log("end load");
        commit("HIDE_LOADER");
      }
      return response;
    } catch (error) {
      console.log(error);
      active_requests--;
      if (active_requests === 0) {
        //   console.log("end load");
        commit("HIDE_LOADER");
      }
      return error;
    }
  },
  // Admin
  async GET_FROM_API_ADMIN({ commit }, { ...args }) {
    if (active_requests === 0) {
      // console.log("start load");
      commit("SHOW_LOADER");
    }
    active_requests++;
    try {
      const response = await axios(`${this.getters.API}${args.src}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: {
          token_a: this.getters.TOKEN_A,
          lang: this.getters.LOCALE,
          ...args.data,
        },
      });

      if (response.data.status !== 0) {
        commit("LOGOUT");
        alert("Необходима аторизация");
        router.push({ name: "login", params: { locale: store.state.locale || getLocale() } });
        console.log(`Oops!`);
        console.log(response);
      } else {
        if (args.mutation) commit(args.mutation, response.data.data);
      }
      active_requests--;
      if (active_requests === 0) {
        //   console.log("end load");
        commit("HIDE_LOADER");
      }
      return response;
    } catch (error) {
      console.log(error);
      active_requests--;
      if (active_requests === 0) {
        //   console.log("end load");
        commit("HIDE_LOADER");
      }
      return error;
    }
  },
  async SEND_ADMIN({ commit }, { ...args }) {
    if (active_requests === 0) {
      // console.log("start load");
      commit("SHOW_LOADER");
    }
    active_requests++;
    try {
      const response = await axios(`${this.getters.API}${args.src}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: {
          token_a: this.getters.TOKEN_A,
          lang: this.getters.LOCALE,
          ...args.data,
        },
      });
      if (response.data.status !== 0) {
        commit("LOGOUT");
        alert("Необходима аторизация");
        router.push({ name: "login", params: { locale: store.state.locale || getLocale() } });
        console.log(`Oops!`);
        console.log(response);
      } else {
        if (args.mutation) commit(args.mutation, response.data.data);
      }
      active_requests--;
      if (active_requests === 0) {
        //   console.log("end load");
        commit("HIDE_LOADER");
      }
      return response;
    } catch (error) {
      console.log(error);
      active_requests--;
      if (active_requests === 0) {
        //   console.log("end load");
        commit("HIDE_LOADER");
      }
      return error;
    }
  },
};
