<template>
  <!-- <router-link :to="{ name: 'login' }">LOGIN</router-link> -->
  <UserHeader v-if="!route.meta.hideUserComponents" />
  <AdminHeader v-if="route.meta.hideUserComponents && route.meta.isNeedAuth" />
  <UserCart v-if="IS_CART_VISIBLE" />
  <PreorderPopup v-if="IS_PREORDER_POPUP_VISIBLE && CART.length" />
  <!-- <router-view /> -->
  <router-view v-slot="{ Component }">
    <transition
      name="fade"
      @after-enter="handlePageLoad"
    >
      <component :is="Component" />
    </transition>
  </router-view>
  <UserFooter v-if="!route.meta.hideUserComponents && !isInitialLoad" />
  <ToTopButton />
  <UserLoader v-show="IS_LOADER_ACTIVE || isPageLoading" />
</template>
<script>
import { computed, onMounted, watch, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import AdminHeader from "@/components/admin/AdminHeader";
import UserHeader from "@/components/UserHeader";
import UserFooter from "@/components/UserFooter";
import UserCart from "@/components/UserCart";
import PreorderPopup from "@/components/popups/PreorderPopup";
import UserLoader from "@/components/UserLoader";
import ToTopButton from "@/components/ToTopButton";

import { isQueryUtm } from "@/utils/utils";
export default {
  name: "App",
  components: {
    AdminHeader,
    UserHeader,
    UserFooter,
    UserCart,
    PreorderPopup,
    UserLoader,
    ToTopButton,
    //
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const LOCALE = computed(() => store.getters.LOCALE);
    const IS_LOADER_ACTIVE = computed(() => store.getters.IS_LOADER_ACTIVE);
    const CART = computed(() => store.getters.CART);
    const IS_CART_VISIBLE = computed(() => store.getters.IS_CART_VISIBLE);
    const IS_PREORDER_POPUP_VISIBLE = computed(() => store.getters.IS_PREORDER_POPUP_VISIBLE);

    const getData = () => {
      store
        .dispatch("GET_FROM_API_USER", {
          src: "/product/get",
          data: { ids: ["1", "4", "5", "6", "7", "8", "9", "10", "11", "12"] },
          mutation: "SET_PRODUCTS_USER",
        })
        .then((response) => {
          if (response.data.status == 0) {
            const cart = JSON.parse(localStorage.getItem("cart"));
            if (cart?.length) {
              cart.forEach((item) => {
                let responseItem = response.data.data.find((responseItem) => responseItem.productId == item.productId);
                if (responseItem) {
                  item.fullPrice = responseItem.fullPrice;
                  item.discountAmount = responseItem.discountAmount;
                }
              });
              store.commit("ADD_TO_CART_FROM_STORAGE", cart);
            }
          }
        });
      store.dispatch("GET_FROM_API_USER", {
        src: "/product/get_categories",
        mutation: "SET_CATEGORIES_USER",
      });
    };
    const getLocalStorageWithExpiration = (key) => {
      const dataWithExpiration = JSON.parse(localStorage.getItem(key));
      if (dataWithExpiration?.expirationTime) {
        const now = new Date().getTime();
        if (now < dataWithExpiration.expirationTime) {
          return dataWithExpiration.data;
        } else {
          // Если время истекло, удаляем данные из localStorage
          localStorage.removeItem(key);
          return null;
        }
      }
      return null; // Возвращаем null, если данные не найдены или срок действия истек
    };
    const setLocalStorageWithExpiration = (key, data, expirationInMinutes) => {
      const now = new Date();
      const expirationTime = now.getTime() + expirationInMinutes * 60 * 1000;
      const dataWithExpiration = { data, expirationTime };
      localStorage.setItem(key, JSON.stringify(dataWithExpiration));
    };
    const sendSellerItemData = (key, val) => {
      store.dispatch("SEND_USER", {
        src: "/product/seller",
        data: { [key]: val },
      });
    };

    const isPageLoading = ref(true);
    const isInitialLoad = ref(true);
    const handlePageLoad = () => {
      isPageLoading.value = false;
      if (isInitialLoad.value) isInitialLoad.value = false;
    };
    const router = useRouter();
    router.beforeEach((to, from, next) => {
      isPageLoading.value = true;
      next();
    });
    router.afterEach(() => {
      isPageLoading.value = false;
    });
    const addPreloadImage = (data) => {
      let l = document.createElement("link");
      l.rel = "preload";
      l.as = "image";
      l.href = data;
      document.head.append(l);
    };
    const footerImages = [
      `/img/icons/tiktok.svg`,
      `/img/icons/fb.svg`,
      `/img/icons/inst.svg`,
      `/img/icons/payment.webp`,
    ];
    const homeImages = [
      //
      `/img/home/hero.webp`,
      `/img/products/1/1.webp`,
      `/img/products/6/1.webp`,
    ];
    const catalogImages = [
      `/img/promo-materials/catalog-banners/15/10_mob.webp`,
      // `/img/promo-materials/catalog-banners/15/11_mob.webp`,
      // `/img/promo-materials/catalog-banners/15/12_mob.webp`,
      `/img/products/1/1.webp`,
      `/img/products/6/1.webp`,
      `/img/products/9/1.webp`,
    ];

    const collagenImages = [
      `/img/landing/1/1_1.webp`,
      `/img/products/1/1.webp`,
      `/img/landing/1/cnt_0.webp`,
      // `/img/landing/1/cnt_12.webp`,
      //
    ];
    const omegaImages = [
      `/img/landing/6/1_1.webp`,
      `/img/products/6/1.webp`,
      `/img/landing/6/cnt_0.webp`,
      // `/img/landing/6/cnt_12.webp`,
      //
    ];
    const omega_kidsImages = [
      `/img/landing/7/1_1.webp`,
      `/img/products/7/1.webp`,
      `/img/landing/7/cnt_0.webp`,
      // `/img/landing/7/cnt_12.webp`,
      //
    ];
    const d3k2Images = [
      `/img/landing/4/1_1.webp`,
      `/img/products/4/1.webp`,
      `/img/landing/4/cnt_0.webp`,
      // `/img/landing/4/cnt_12.webp`,
      //
    ];
    const d3k2_kidsImages = [
      `/img/landing/5/1_1.webp`,
      `/img/products/5/1.webp`,
      `/img/landing/5/cnt_0.webp`,
      // `/img/landing/5/cnt_12.webp`,
      //
    ];
    const multivitamin_womenImages = [
      `/img/landing/9/1_1.webp`,
      `/img/products/9/1.webp`,
      `/img/landing/9/cnt_0.webp`,
      // `/img/landing/9/cnt_12.webp`,
      //
    ];
    const multivitamin_menImages = [
      `/img/landing/8/1_1.webp`,
      `/img/products/8/1.webp`,
      `/img/landing/8/cnt_0.webp`,
      // `/img/landing/8/cnt_12.webp`,
      //
    ];
    const complex_10Images = [
      `/img/landing/10/1_1.webp`,
      `/img/products/10/1.webp`,
      `/img/landing/10/cnt_0.webp`,
      // `/img/landing/10/cnt_12.webp`,
      //
    ];
    const complex_11Images = [
      `/img/landing/11/1_1_female.webp`,
      `/img/products/11/1.webp`,
      `/img/landing/11/cnt_0.webp`,
      // `/img/landing/11/cnt_12.webp`,
      //
    ];
    const complex_12Images = [
      `/img/landing/12/1_1.webp`,
      `/img/products/12/1.webp`,
      `/img/landing/12/cnt_0.webp`,
      // `/img/landing/12/cnt_12.webp`,
      //
    ];
    onBeforeMount(() => {
      const { name } = currentRoute.value;
      console.log(name);
      if (name == "home") {
        homeImages.forEach((image) => {
          addPreloadImage(image);
        });
      } else if (name == "catalog") {
        catalogImages.forEach((image) => {
          addPreloadImage(image);
        });
      } else if (name == "collagen") {
        collagenImages.forEach((image) => {
          addPreloadImage(image);
        });
      } else if (name == "omega") {
        omegaImages.forEach((image) => {
          addPreloadImage(image);
        });
      } else if (name == "omega-kids") {
        omega_kidsImages.forEach((image) => {
          addPreloadImage(image);
        });
      } else if (name == "d3k2") {
        d3k2Images.forEach((image) => {
          addPreloadImage(image);
        });
      } else if (name == "d3k2-kids") {
        d3k2_kidsImages.forEach((image) => {
          addPreloadImage(image);
        });
      } else if (name == "multivitamin-women") {
        multivitamin_womenImages.forEach((image) => {
          addPreloadImage(image);
        });
      } else if (name == "multivitamin-men") {
        multivitamin_menImages.forEach((image) => {
          addPreloadImage(image);
        });
      } else if (name == "complex_10") {
        complex_10Images.forEach((image) => {
          addPreloadImage(image);
        });
      } else if (name == "complex_11") {
        complex_11Images.forEach((image) => {
          addPreloadImage(image);
        });
      } else if (name == "complex_12") {
        complex_12Images.forEach((image) => {
          addPreloadImage(image);
        });
      }
      if (JSON.parse(localStorage.getItem("token_a"))) {
        store.commit("LOGIN", JSON.parse(localStorage.getItem("token_a")));
      }
    });
    const currentRoute = computed(() => router.currentRoute.value);
    onMounted(() => {
      // eslint-disable-next-line no-undef
      loadMetrics();
      setTimeout(() => {
        footerImages.forEach((image) => {
          addPreloadImage(image);
        });
        handlePageLoad();
      }, 2000);
      getData();
      const queryItems = {};
      const parsedQuery = JSON.parse(localStorage.getItem("queryItems", queryItems));
      for (let item in parsedQuery) {
        queryItems[item] = parsedQuery[item];
      }
      if (Object.keys(route.query).length) {
        for (let item in route.query) {
          if (isQueryUtm(item)) {
            if (!getLocalStorageWithExpiration(item + "-timestamp")) {
              // Записываем новое значение
              queryItems[item] = route.query[item];
              setLocalStorageWithExpiration(item + "-timestamp", { [item]: route.query[item] }, 60 * 24);
              sendSellerItemData(item, route.query[item]);
            } else if (
              getLocalStorageWithExpiration(item + "-timestamp")[item] != route.query[item] &&
              !!getLocalStorageWithExpiration(item + "-timestamp")
            ) {
              // Перезаписываем сусществующее значение на новое
              queryItems[item] = route.query[item];
              setLocalStorageWithExpiration(item + "-timestamp", { [item]: route.query[item] }, 60 * 24);
              sendSellerItemData(item, route.query[item]);
            }
          }
        }
        localStorage.setItem("queryItems", JSON.stringify(queryItems));
      }
      if (route.query.gender) {
        store.commit("SET_GENDER", route.query.gender == "female" ? true : false);
      }
    });

    watch(LOCALE, () => {
      getData();
    });
    watch(route, () => {
      // console.log("App route changed");
    });
    const setDeviceInfo = () => {
      store.commit("SET_DEVICE_INFO");
    };
    window.addEventListener("resize", setDeviceInfo);
    window.addEventListener("orientationchange", setDeviceInfo);

    //  onBeforeUnmount(() => {
    //    window.removeEventListener("resize", getDeviceInfo);
    //    window.removeEventListener("orientationchange", getDeviceInfo);
    //  });

    return {
      IS_CART_VISIBLE,
      CART,
      IS_PREORDER_POPUP_VISIBLE,
      IS_LOADER_ACTIVE,
      route,
      store,
      handlePageLoad,
      isPageLoading,
      isInitialLoad,
      //
    };
  },
};
</script>
<style lang="scss">
#app {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  main {
    flex: 1 1 auto;
  }
  img {
    &.no-shadow {
      box-shadow: none;
    }
  }
  [data-tooltip] {
    position: relative;
    input {
      outline: 1px solid $discount;
      box-shadow: -2px 2px 5px $discount;

      animation: shake 0.6s linear 0s 1;
      @keyframes shake {
        0%,
        10%,
        20%,
        30%,
        40%,
        50%,
        60%,
        70%,
        80%,
        90%,
        100% {
          transform: translateX(0px);
        }
        5%,
        25%,
        45%,
        65%,
        85% {
          transform: translateX(-4px);
        }
        15%,
        35%,
        55%,
        75%,
        95% {
          transform: translateX(4px);
        }
      }
    }
  }

  [data-tooltip]::before {
    content: attr(data-tooltip);
    display: inline-block;
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    z-index: 9;
    background-color: $discount;
    color: #fff;
    @extend %box-shadow-small;
    padding: 6px 10px;
    border-radius: 6px;
    @include fz(14);
  }
}
img {
  width: 100%;
  max-width: 100%;
}
[data-sticky] {
  @media (min-width: 768px) {
    position: sticky;
    top: 100px;
    left: 0;
  }
}
picture {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
