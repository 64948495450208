<template>
  <header
    class="header"
    @click="handleClickOutside"
  >
    <!-- <div class="header__extra">{{ $t("first_order_discount") }}</div> -->
    <div class="header__container">
      <div
        class="header__burger"
        @click="isMobileMenuOpened = !isMobileMenuOpened"
        :class="{ opened: isMobileMenuOpened }"
      >
        <span></span><span></span><span></span>
      </div>
      <router-link
        class="header__logo"
        :to="homeLink"
      >
        <!-- <img
          loading="lazy"
          :src="require(`../assets/img/icons/logo.svg`)"
          alt="VitaIsland"
        />
        <img
          loading="lazy"
          :src="require(`../assets/img/icons/name.svg`)"
          alt="VitaIsland"
        /> -->
        <img
          loading="lazy"
          src="/img/icons/logo.svg"
          alt="VitaIsland"
        />
        <img
          loading="lazy"
          src="/img/icons/name.svg"
          alt="VitaIsland"
        />
      </router-link>

      <nav class="header__menu">
        <router-link
          v-if="currentRouteName !== 'home'"
          :to="homeLink"
        >
          {{ $t("userHeader.1") }}
        </router-link>
        <router-link
          v-for="category in filteredCategories"
          :key="category.name_en"
          :to="categoryLink(category.name_en)"
          :class="{ selected: currentCategory === category.name_en }"
        >
          {{ category.name }}
        </router-link>
        <router-link
          :to="allProductsLink"
          :class="{ selected: currentCategory === 'all' }"
        >
          {{ $t("userHeader.2") }}
        </router-link>
      </nav>
      <div class="header__action">
        <!-- <nav class="header__langs"> -->
        <LangSelect />
        <!-- </nav> -->
        <!-- <a
          href="tel:0800315000"
          class="header__phone"
        >
          <svg
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              d="M3.68367 1.91648C3.73581 2.68994 3.86617 3.44603 4.07475 4.16736L3.03187 5.21024C2.67555 4.16736 2.4496 3.06364 2.37138 1.91648H3.68367ZM12.2527 12.3626C12.9914 12.5712 13.7475 12.7016 14.5122 12.7537V14.0486C13.3651 13.9704 12.2614 13.7445 11.2098 13.3968L12.2527 12.3626ZM4.51797 0.178345H1.47624C0.998257 0.178345 0.607178 0.569424 0.607178 1.04741C0.607178 9.20794 7.22077 15.8215 15.3813 15.8215C15.8593 15.8215 16.2504 15.4304 16.2504 14.9525V11.9194C16.2504 11.4414 15.8593 11.0504 15.3813 11.0504C14.3037 11.0504 13.2521 10.8765 12.2787 10.555C12.1918 10.5202 12.0962 10.5115 12.0093 10.5115C11.7834 10.5115 11.5661 10.5984 11.3923 10.7636L9.48034 12.6755C7.02088 11.4154 5.00465 9.40782 3.7532 6.94837L5.66514 5.03642C5.90848 4.79308 5.978 4.45415 5.88241 4.14998C5.56085 3.17662 5.38704 2.13374 5.38704 1.04741C5.38704 0.569424 4.99596 0.178345 4.51797 0.178345Z"
              fill="black"
            />
          </svg>
          <span>073 022 96 49</span>
          <span>073 029 64 18</span>
          <span>0800 315 000</span>
        </a> -->
        <a
          href="https://www.instagram.com/vitaisland/?igshid=Y2IzZGU1MTFhOQ%3D%3D"
          target="_blank"
          class="user-footer__social"
        >
          <img
            loading="lazy"
            src="/img/icons/__inst.svg"
            alt="instagram"
          />
          <!-- <img
              loading="lazy"
              :src="require(`../assets/img/icons/inst.svg`)"
              alt="instagram"
            /> -->
        </a>
        <button
          class="header__cart"
          @click="showCart"
        >
          <span v-if="CART.length">{{ CART.length }}</span>
          <svg viewBox="0 0 22 26">
            <path
              d="M21.9973 24.116V24.1008L20.5989 7.22617C20.5374 6.2207 19.6974 5.43359 18.6781 5.43359H16.8033C16.6292 2.40703 14.0937 0 10.9947 0C7.9009 0 5.36027 2.40703 5.19124 5.43359H3.3165C2.29717 5.43359 1.45713 6.2207 1.39566 7.22617L0.00241242 24.0906V24.116C-0.0231987 24.6035 0.156079 25.0859 0.494147 25.4414C0.832214 25.7969 1.3137 26 1.80544 26H20.1943C20.686 26 21.1624 25.7969 21.5056 25.4414C21.8385 25.0859 22.0229 24.6035 21.9973 24.116ZM10.9947 1.82812C13.0744 1.82812 14.7801 3.41758 14.9491 5.43359H7.04036C7.2094 3.41758 8.92022 1.82812 10.9947 1.82812ZM1.84642 24.1719L3.23966 7.36328V7.33789C3.23966 7.29727 3.27552 7.26172 3.32162 7.26172H18.6832C18.7242 7.26172 18.76 7.29219 18.7652 7.33789V7.35313L20.1482 24.1719H1.84642Z"
              fill="black"
            />
          </svg>
        </button>
      </div>
    </div>
    <MobileMenu
      v-show="isMobileMenuOpened"
      :isOpened="isMobileMenuOpened"
    />
  </header>
</template>

<script>
// import { defineComponent } from "vue";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
// import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
// import { useRouter } from "vue-router";
import { queryParams } from "@/utils/utils";
import MobileMenu from "@/components/MobileMenu";
import LangSelect from "@/components/UI/LangSelect";
export default {
  name: "UserHeader",
  components: { MobileMenu, LangSelect },
  setup() {
    const store = useStore();
    const route = useRoute();
    //  const { locale } = useI18n();
    const isMobileMenuOpened = ref(false);
    const LOCALE = computed(() => store.getters.LOCALE);
    const CART = computed(() => store.getters.CART);
    const CATEGORIES_USER = computed(() => store.getters.CATEGORIES_USER);
    //  const SUPPORTED_LANGUAGES = computed(() => store.getters.SUPPORTED_LANGUAGES);
    const IS_LANDSCAPE = computed(() => store.getters.IS_LANDSCAPE);

    const currentRouteName = computed(() => route.name);
    const currentCategory = computed(() => route.query.category);

    const handleClickOutside = (e) => {
      if (isMobileMenuOpened.value && !e.target.closest(".mobile-menu__body") && !e.target.closest(".header__burger")) {
        isMobileMenuOpened.value = false;
      }
    };

    const homeLink = computed(() => ({
      name: "home",
      query: {
        ...queryParams(route.query, ["gender", "category", "order_id", "affiliation", "page"]),
      },
      params: { locale: LOCALE.value },
    }));
    const filteredCategories = computed(() => {
      return CATEGORIES_USER.value.filter((category) => category.id != 6 && category.id != 7);
    });
    const categoryLink = (categoryName) => ({
      name: "catalog",
      query: {
        ...queryParams(route.query, ["gender", "order_id", "affiliation"]),
        category: categoryName,
        page: 1,
      },
      params: { locale: LOCALE.value },
    });

    const allProductsLink = computed(() => ({
      name: "catalog",
      query: {
        ...queryParams(route.query, ["gender", "order_id", "affiliation"]),
        category: "all",
        page: 1,
      },
      params: { locale: LOCALE.value },
    }));

    //  const localeLink = (lang) => ({
    //    name: route.name,
    //    query: {
    //      ...route.query,
    //    },
    //    params: { locale: lang },
    //  });
    //  const changeLocale = (lang) => {
    //    if (LOCALE.value !== lang) {
    //      store.commit("SET_LOCALE", lang);
    //      locale.value = lang;
    //    }
    //  };

    const showCart = () => {
      store.commit("SHOW_CART");
    };
    watch(route, (newValue, oldValue) => {
      oldValue ? oldValue : newValue;
      isMobileMenuOpened.value = false;
    });
    window.addEventListener("resize", () => {
      if (isMobileMenuOpened.value) isMobileMenuOpened.value = false;
    });
    return {
      CART,
      IS_LANDSCAPE,
      LOCALE,
      currentRouteName,
      currentCategory,
      homeLink,
      filteredCategories,
      categoryLink,
      allProductsLink,
      // SUPPORTED_LANGUAGES,
      // localeLink,
      // changeLocale,
      showCart,
      isMobileMenuOpened,
      handleClickOutside,
    };
  },
};
</script>
<style lang="scss">
.header {
  position: sticky;
  z-index: 5;
  top: 0;
  left: 0;
  background-color: #fff;
  border-bottom: 1px solid $grey;
  // .header__extra

  //   &__extra {
  //     padding-left: 20px;
  //     padding-right: 20px;
  //     padding-top: 8px;
  //     padding-bottom: 8px;
  //     @media (min-width: 768px) {
  //       padding-top: 10px;
  //       padding-bottom: 10px;
  //     }
  //     text-align: center;
  //     background-color: #00c4b3;
  //     color: #fff;
  //     @include fz(13);
  //     line-height: 1;
  //     font-variation-settings: "wght" 700;
  //     letter-spacing: 0.1em;
  //     font-family: $ff2;
  //     @media (max-width: 349px) {
  //       line-height: 1.2;
  //     }
  //     @media (min-width: 350px) {
  //       @include fz(14);
  //     }
  //     @media (min-width: 375px) {
  //       @include fz(15);
  //     }
  //     @media (min-width: 768px) {
  //       @include fz(17);
  //     }
  //   }

  // .header__container

  &__container {
    padding-top: 15px;
    padding-bottom: 15px;
    @media (min-width: 768px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    @extend %container;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    column-gap: 20px;
  }

  // .header__burger

  &__burger {
    @media (max-width: 767px) {
      position: relative;
      width: 24px;
      height: 18px;
      span {
        background-color: #000;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        position: absolute;
        left: 0;
        transition: all 0.5s ease;

        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
        }
        &:nth-child(3) {
          bottom: 0;
        }
      }
      &.opened {
        span {
          &:nth-child(1) {
            top: 50%;
            transform: translate(0, -50%) rotate(45deg);
          }
          &:nth-child(2) {
            transform: translateY(-50%) scale(0);
          }
          &:nth-child(3) {
            transform: translate(0, 50%) rotate(-45deg);
            bottom: 50%;
          }
        }
      }
    }
    @media (min-width: 768px) {
      display: none;
    }
  }

  // .header__logo

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    width: 140px;
    img {
      &:first-child {
        @media (min-width: 768px) {
          margin-left: -4px;
        }
        @media (max-width: 767px) {
          margin-left: -2px;
        }
      }
      max-height: 40px;
    }
    @media (max-width: 767px) {
      justify-self: center;
    }
  }

  // .header__menu

  &__menu {
    @media (max-width: 767px) {
      display: none;
    }
    //  justify-content: center;
    @media (min-width: 768px) {
      justify-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
      row-gap: 5px;
      flex-wrap: wrap;
      a {
        padding: 5px;
        font-variation-settings: "wght" 700;
        text-align: center;
        &.selected {
          color: $mainColor;
          user-select: none;
          pointer-events: none;
        }
        &:hover {
          color: $mainColorDarken;
        }
      }
    }
  }

  // .header__action

  &__action {
    display: flex;
    align-items: center;
    column-gap: 20px;
    .user-footer__social {
      transform: scale(0.8);
    }
  }

  // .header__langs

  &__langs,
  .lang-select {
    display: flex;
    align-items: center;
    column-gap: 1px;
    a {
      padding: 5px;
      font-variation-settings: "wght" 700;
      &.selected {
        color: $mainColor;
        text-transform: uppercase;
        font-variation-settings: "wght" 900;
        user-select: none;
        pointer-events: none;
      }
      &:hover {
        color: $mainColorDarken;
      }
    }
    @media (max-width: 767px) {
      display: none;
    }
  }

  // .header__phone

  &__phone {
    display: flex;
    align-items: center;
    column-gap: 5px;

    svg {
      @media (max-width: 767px) {
        min-width: 18px;
        min-height: 20px;
      }
      @media (min-width: 768px) {
        min-width: 24px;
        min-height: 28px;
        max-width: 24px;
        max-height: 28px;
      }
      height: auto;
      width: auto;
    }
    span {
      font-variation-settings: "wght" 700;
    }
    @media (min-width: 768px) {
      &:hover {
        path {
          fill: $mainColor;
        }
        span {
          color: $mainColor;
        }
      }
    }
    @media (max-width: 1023px) {
      span {
        display: none;
      }
    }
  }
  // .header__cart

  &__cart {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
    &:hover {
      path {
        fill: $mainColorDarken;
      }
    }
    svg {
      @media (max-width: 767px) {
        min-width: 20px;
        min-height: 20px;
      }
      @media (min-width: 768px) {
        min-width: 24px;
        min-height: 28px;
        max-width: 24px;
        max-height: 28px;
      }
      height: auto;
      width: auto;
    }
    span {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 50%;
      background-color: $mainColor;
      font-size: 0.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18px;
      height: 18px;
      color: #fff;
      font-family: $ff1;
      font-variation-settings: "wght" 700;
      transform: translate(30%, -30%);
      border: 2px solid #fff;
    }
  }
}
</style>
