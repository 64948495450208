import { getLocale } from "@/utils/utils";
import store from "@/store/store";

function generateRedirects(catalogRoutes) {
  const redirectRoutes = [];
  store.state.supported_languages.forEach((locale) => {
    catalogRoutes.forEach((route) => {
      redirectRoutes.push({
        path: `/${locale}/catalog/${route.old}`,
        redirect: `/${locale}/catalog/${route.new}`,
      });
    });
  });

  return redirectRoutes;
}
const catalogRoutes = [
  { old: 1301, new: 1001 },
  { old: 2301, new: 2001 },
  { old: 2302, new: 2002 },
  { old: 3091, new: 3001 },
  { old: 3301, new: 3001 },
  { old: 3302, new: 3002 },
  { old: 4301, new: 4001 },
  { old: 4302, new: 4002 },
];
const redirectRoutes = generateRedirects(catalogRoutes);
const locale = store.state.locale || getLocale();

export const routes = [
  ...redirectRoutes,
  {
    path: "/",
    redirect: `/${locale}`,
  },
  {
    path: "/:locale",
    props: true,
    children: [
      {
        path: "",
        name: "home",
        component: () => import("@/views/HomeView"),
      },
      {
        path: "catalog",
        name: "catalog",
        component: () => import("@/views/CatalogView"),
      },
      {
        path: "catalog",
        children: [
          {
            // path: "collagen",
            // path: ":article",
            path: "1001",
            name: "collagen",
            component: () => import("@/views/product-page/CollagenView"),
          },
          {
            // path: "d3k2",
            // path: ":article",
            path: "2001",
            name: "d3k2",
            component: () => import("@/views/product-page/D3K2View"),
          },
          {
            // path: "d3k2-kids",
            // path: ":article",
            path: "2002",
            name: "d3k2-kids",
            component: () => import("@/views/product-page/D3K2KidsView"),
          },
          {
            // path: "omega",
            // path: ":article",
            path: "3001",
            name: "omega",
            component: () => import("@/views/product-page/OmegaView"),
          },
          {
            // path: "omega-kids",
            // path: ":article",
            path: "3002",
            name: "omega-kids",
            component: () => import("@/views/product-page/OmegaKidsView"),
          },
          {
            // path: "multivitamin-men",
            // path: ":article",
            path: "4001",
            name: "multivitamin-men",
            component: () => import("@/views/product-page/Multivitamin-menView"),
          },
          {
            // path: "multivitamin-women",
            // path: ":article",
            path: "4002",
            name: "multivitamin-women",
            component: () => import("@/views/product-page/Multivitamin-womenView"),
          },
          {
            path: "5001",
            name: "complex_10",
            component: () => import("@/views/product-page/Complex10View"),
          },
          {
            path: "5002",
            name: "complex_11",
            component: () => import("@/views/product-page/Complex11View"),
          },
          {
            path: "5003",
            name: "complex_12",
            component: () => import("@/views/product-page/Complex12View"),
          },
        ],
      },
      {
        path: "ordering",
        name: "ordering",
        component: () => import("@/views/OrderingView"),
      },
      {
        path: "thanks",
        name: "thanks",
        component: () => import("@/views/ThanksView"),
      },
      {
        path: "contacts",
        name: "contacts",
        component: () => import("@/views/ContactsView"),
      },
      {
        path: "refund",
        name: "refund",
        component: () => import("@/views/RefundView"),
      },
      {
        path: "terms",
        name: "terms",
        component: () => import("@/views/TermsView"),
      },
      {
        path: "policy",
        name: "policy",
        component: () => import("@/views/PolicyView"),
      },
      {
        path: "404",
        name: "404",
        component: () => import("@/views/404View"),
      },
      {
        meta: { hideUserComponents: true },
        path: "login",
        name: "login",
        component: () => import("@/views/admin/LoginView"),
      },
      {
        meta: { hideUserComponents: true, isNeedAuth: true },
        path: "admin",
        redirect: store.state.token_a ? `/${locale}/admin/catalog` : `/${locale}/login`,
        children: [
          {
            path: "catalog",
            name: "admin-catalog",
            component: () => import("@/views/CatalogView"),
          },
          {
            path: "catalog/:id",
            name: "admin-product-page",
            component: () => import("@/views/admin/ProductPage"),
          },
          {
            path: "categories",
            name: "admin-categories",
            component: () => import("@/views/admin/CategoriesView"),
          },
          {
            path: "promocodes",
            name: "admin-promocodes",
            component: () => import("@/views/admin/PromocodesView"),
          },
          {
            path: "statistics",
            children: [
              {
                path: "utm",
                name: "admin-utm",
                component: () => import("@/views/admin/statistics/UTMView"),
              },
              {
                path: "promocodes",
                name: "admin-promo",
                component: () => import("@/views/admin/statistics/PromoView"),
              },
            ],
          },
        ],
        beforeEnter: (to) => {
          if (JSON.parse(localStorage.getItem("token_a"))) {
            store.commit("LOGIN", JSON.parse(localStorage.getItem("token_a")));
          }
          if (!(to.meta.isNeedAuth && (store.state.is_auth || store.state.token_a))) {
            alert("Необходима аторизация");
            return { name: "login", params: { locale: store.state.locale || getLocale() } };
          }
        },
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    redirect: `/${store.state.locale}/404`,
  },
];
