const metaTags = {
  ua: {
    description: `Vitaisland – вітаміни від українського виробника. Інтернет-магазин Віта Айленд. Товари за вигідними цінами. Доставка по всій Україні. Докладніше - на сайті Vitaisland.com.ua`,
    title: `Vitaisland - інтернет-магазин вітамінів від українского бренду`,

    collagen: {
      description: `Колаген - купити вітаміни для дорослих та дітей від бренду Vitaisland - надійний спосіб підтримувати здоров'я та гарне самопочуття. У нашому каталозі ви знайдете ассортимент вітамінів для дорослих різного віку та потреб. Читайте інформацію на сайті: Vitaisland.com.ua`,
      title: `Vitaisland | Колаген - купити Collagen для підтримки здоров'я та доброго самопочуття.`,
    },
    d3k2: {
      description: `Vitaisland | Купити вітаміни K2 та D3 від українського виробника. У нашому каталозі ви знайдете асортименти вітамінів, які допоможуть Вам. Докладніше - на сайті Vitaisland.com.ua`,
      title: `Вітаміни K2 і D3 - купити на Vitaisland.com.ua`,
    },
    "d3k2-kids": {
      description: `VitaIsland D3+K2 kids. Важливий вітамінний дует на всіх етапах росту та розвику дитини`,
      title: `Вітамін сонця для вашої дитини. D3+K2 VitaIsland`,
    },
    omega: {
      description: `Омега 3 – купити вітаміни для дітей від Vitaisland – це якісні та безпечні добавки, які допоможуть забезпечити дитину необхідними поживними речовинами. У нашому каталозі ви знайдете широкий асортимент вітамінів для дітей різного віку - на сайті Vitaisland.com.ua`,
      title: `Омега 3 від Vitaisland | Купити Omega 3 - вітаміни для серця та суглобів`,
    },
    "omega-kids": {
      description: `VitaIsland Omega-3 kids. Належне живлення для забезпечення здорового фізичного та психічного розвитку дитини.`,
      title: `VitaIsland Omega-3 kids. І ваша дитина в безпеці`,
    },
    "multivitamin-men": {
      description: `Мультивітамін для справжніх чоловіків. Вітамінний комплекс для повноцінного забезпечення організму необхідними елементами`,
      title: `Обирай VitaIsland Multivitamin - твоя потужна вітамінна підтримка для справжніх чоловіків`,
    },
    "multivitamin-women": {
      description: `Жіночий мультивітамін. Комплексний підхід до нестачі вітамінів для жіночого організму`,
      title: `Обирай VitaIsland Multivitamin - твоє щоденне джерело вітамінів та енергії`,
    },
  },
  en: {
    description: `Vitaisland – вітаміни від українського виробника. Інтернет-магазин Віта Айленд. Товари за вигідними цінами. Доставка по всій Україні. Докладніше - на сайті Vitaisland.com.ua`,
    title: `Vitaisland - інтернет-магазин вітамінів від українского бренду`,

    collagen: {
      description: `Колаген - купити вітаміни для дорослих та дітей від бренду Vitaisland - надійний спосіб підтримувати здоров'я та гарне самопочуття. У нашому каталозі ви знайдете ассортимент вітамінів для дорослих різного віку та потреб. Читайте інформацію на сайті: Vitaisland.com.ua`,
      title: `Vitaisland | Колаген - купити Collagen для підтримки здоров'я та доброго самопочуття.`,
    },
    d3k2: {
      description: `Vitaisland | Купити вітаміни K2 та D3 від українського виробника. У нашому каталозі ви знайдете асортименти вітамінів, які допоможуть Вам. Докладніше - на сайті Vitaisland.com.ua`,
      title: `Вітаміни K2 і D3 - купити на Vitaisland.com.ua`,
    },
    "d3k2-kids": {
      description: `VitaIsland D3+K2 kids. Важливий вітамінний дует на всіх етапах росту та розвику дитини`,
      title: `Вітамін сонця для вашої дитини. D3+K2 VitaIsland`,
    },
    omega: {
      description: `Омега 3 – купити вітаміни для дітей від Vitaisland – це якісні та безпечні добавки, які допоможуть забезпечити дитину необхідними поживними речовинами. У нашому каталозі ви знайдете широкий асортимент вітамінів для дітей різного віку - на сайті Vitaisland.com.ua`,
      title: `Омега 3 від Vitaisland | Купити Omega 3 - вітаміни для серця та суглобів`,
    },
    "omega-kids": {
      description: `VitaIsland Omega-3 kids. Належне живлення для забезпечення здорового фізичного та психічного розвитку дитини.`,
      title: `VitaIsland Omega-3 kids. І ваша дитина в безпеці`,
    },
    "multivitamin-men": {
      description: `Мультивітамін для справжніх чоловіків. Вітамінний комплекс для повноцінного забезпечення організму необхідними елементами`,
      title: `Обирай VitaIsland Multivitamin - твоя потужна вітамінна підтримка для справжніх чоловіків`,
    },
    "multivitamin-women": {
      description: `Жіночий мультивітамін. Комплексний підхід до нестачі вітамінів для жіночого організму`,
      title: `Обирай VitaIsland Multivitamin - твоє щоденне джерело вітамінів та енергії`,
    },
  },
  ru: {
    description: `Vitaisland – вітаміни від українського виробника. Інтернет-магазин Віта Айленд. Товари за вигідними цінами. Доставка по всій Україні. Докладніше - на сайті Vitaisland.com.ua`,
    title: `Vitaisland - інтернет-магазин вітамінів від українского бренду`,

    collagen: {
      description: `Колаген - купити вітаміни для дорослих та дітей від бренду Vitaisland - надійний спосіб підтримувати здоров'я та гарне самопочуття. У нашому каталозі ви знайдете ассортимент вітамінів для дорослих різного віку та потреб. Читайте інформацію на сайті: Vitaisland.com.ua`,
      title: `Vitaisland | Колаген - купити Collagen для підтримки здоров'я та доброго самопочуття.`,
    },
    d3k2: {
      description: `Vitaisland | Купити вітаміни K2 та D3 від українського виробника. У нашому каталозі ви знайдете асортименти вітамінів, які допоможуть Вам. Докладніше - на сайті Vitaisland.com.ua`,
      title: `Вітаміни K2 і D3 - купити на Vitaisland.com.ua`,
    },
    "d3k2-kids": {
      description: `VitaIsland D3+K2 kids. Важливий вітамінний дует на всіх етапах росту та розвику дитини`,
      title: `Вітамін сонця для вашої дитини. D3+K2 VitaIsland`,
    },
    omega: {
      description: `Омега 3 – купити вітаміни для дітей від Vitaisland – це якісні та безпечні добавки, які допоможуть забезпечити дитину необхідними поживними речовинами. У нашому каталозі ви знайдете широкий асортимент вітамінів для дітей різного віку - на сайті Vitaisland.com.ua`,
      title: `Омега 3 від Vitaisland | Купити Omega 3 - вітаміни для серця та суглобів`,
    },
    "omega-kids": {
      description: `VitaIsland Omega-3 kids. Належне живлення для забезпечення здорового фізичного та психічного розвитку дитини.`,
      title: `VitaIsland Omega-3 kids. І ваша дитина в безпеці`,
    },
    "multivitamin-men": {
      description: `Мультивітамін для справжніх чоловіків. Вітамінний комплекс для повноцінного забезпечення організму необхідними елементами`,
      title: `Обирай VitaIsland Multivitamin - твоя потужна вітамінна підтримка для справжніх чоловіків`,
    },
    "multivitamin-women": {
      description: `Жіночий мультивітамін. Комплексний підхід до нестачі вітамінів для жіночого організму`,
      title: `Обирай VitaIsland Multivitamin - твоє щоденне джерело вітамінів та енергії`,
    },
  },
};
export default metaTags;
