<template>
  <header class="header">
    <div class="header__container">
      <router-link
        class="header__logo"
        :to="homeLink"
      >
        <!-- <img
          loading="lazy"
          :src="require(`../../assets/img/icons/logo.svg`)"
          alt="VitaIsland"
        />
        <img
          loading="lazy"
          :src="require(`../../assets/img/icons/name.svg`)"
          alt="VitaIsland"
        /> -->
        <img
          loading="lazy"
          src="/img/icons/logo.svg"
          alt="VitaIsland"
        />
        <img
          loading="lazy"
          src="/img/icons/name.svg"
          alt="VitaIsland"
        />
      </router-link>

      <nav class="header__menu header__menu_admin">
        <router-link :to="catalogLink"> Вітаміни </router-link>
        <router-link :to="categoriesLink"> Категорії </router-link>
        <router-link :to="promocodesLink"> Промокоди </router-link>
        <router-link :to="utmLink"> UTM </router-link>
        <router-link :to="promoLink"> Промо </router-link>
      </nav>
      <div class="header__menu header__menu_admin">
        <router-link :to="homeLink">
          {{ $t("userHeader.1") }}
        </router-link>
        <router-link
          :to="loginLink"
          @click="logout"
        >
          LogOut
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
// import { defineComponent } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "AdminHeader",
  setup() {
    const store = useStore();
    const LOCALE = computed(() => store.getters.LOCALE);
    const homeLink = computed(() => ({
      name: "home",
      params: { locale: LOCALE.value },
    }));
    const catalogLink = computed(() => ({
      name: "admin-catalog",
      query: { page: 1, category: "all" },
      params: { locale: LOCALE.value },
    }));
    const categoriesLink = computed(() => ({
      name: "admin-categories",
      params: { locale: LOCALE.value },
    }));
    const promocodesLink = computed(() => ({
      name: "admin-promocodes",
      params: { locale: LOCALE.value },
    }));
    const utmLink = computed(() => ({
      name: "admin-utm",
      params: { locale: LOCALE.value },
    }));
    const promoLink = computed(() => ({
      name: "admin-promo",
      params: { locale: LOCALE.value },
    }));
    const loginLink = computed(() => ({
      name: "login",
      params: { locale: LOCALE.value },
    }));
    const logout = () => {
      store.commit("LOGOUT");
    };
    return {
      LOCALE,
      homeLink,
      loginLink,
      logout,
      catalogLink,
      categoriesLink,
      promocodesLink,
      utmLink,
      promoLink,
    };
  },
};
</script>
<style lang="scss">
.header {
  position: sticky;
  z-index: 5;
  top: 0;
  left: 0;
  background-color: #fff;
  border-bottom: 1px solid $grey;
  // .header__container

  &__container {
    padding-top: 15px;
    padding-bottom: 15px;
    @media (min-width: 768px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    @extend %container;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    column-gap: 20px;
  }

  // .header__logo

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    width: 140px;
    img {
      &:first-child {
        @media (min-width: 768px) {
          margin-left: -4px;
        }
        @media (max-width: 767px) {
          margin-left: -2px;
        }
      }
      max-height: 40px;
    }
    @media (max-width: 767px) {
      justify-self: center;
    }
  }

  // .header__menu

  &__menu {
    @media (max-width: 767px) {
      display: none;
    }
    //  justify-content: center;
    @media (min-width: 768px) {
      justify-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
      row-gap: 5px;
      flex-wrap: wrap;
      a {
        padding: 5px;
        font-variation-settings: "wght" 700;
        text-align: center;
        &:hover {
          color: $mainColorDarken;
        }
      }
      &_admin {
        a {
          &.router-link-exact-active {
            color: $mainColor;
            user-select: none;
            pointer-events: none;
          }
        }
      }
    }
  }
}
</style>
