import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import store from "@/store/store";
import { getLocale } from "@/utils/utils";
import metaTags from "./metaTags";
const locale = store.state.locale || getLocale();
const router = createRouter({
  history: createWebHistory(),
  routes,
});
document.documentElement.lang = locale !== "ua" ? locale : "uk";
function writeMeta(oldVal, prop, newVal) {
  if (oldVal[prop] !== newVal) oldVal[prop] = newVal;
}
router.beforeEach((to, _, next) => {
  const pages = ["collagen", "d3k2", "d3k2-kids", "omega", "omega-kids", "multivitamin-men", "multivitamin-women"];
  if (pages.some((page) => page == to.name)) {
    writeMeta(document.querySelector("head #description"), "content", metaTags[locale][to.name].description);
    writeMeta(document.querySelector("head #title"), "textContent", metaTags[locale][to.name].title);
    writeMeta(document.querySelector("head #og_title"), "content", metaTags[locale][to.name].title);
  } else {
    writeMeta(document.querySelector("head #description"), "content", metaTags[locale].description);
    writeMeta(document.querySelector("head #title"), "textContent", metaTags[locale].title);
    writeMeta(document.querySelector("head #og_title"), "content", metaTags[locale].title);
  }
  const currentUrl = window.location.origin + to.path;
  writeMeta(document.querySelector("head #og_url"), "content", currentUrl);
  writeMeta(document.querySelector("head #canonical"), "href", currentUrl);

  if (to.params.locale == "feed.xml" || to.path == "/feed.xml") {
    console.log("feed.xml");
  } else if (store.state.supported_languages.some((el) => el == to.params.locale.toLowerCase())) {
    next();
  } else {
    const path = `/${locale}${to.fullPath}`;
    next({ path: path });
  }
});
router.afterEach(() => {
  window.scrollTo({ top: 0, behavior: "instant" });
});
export default router;
