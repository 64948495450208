const url = "vitaisland.com.ua";

export default {
  contacts: {
    title: "Контактна інформація",
    text: [
      `ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ "ВІТА АЙЛЕНД"`,
      `Код ЄДРПОУ 45136835`,
      `61045, ХАРКІВСЬКА ОБЛ., ХАРКІВ, ВУЛ.КЛОЧКІВСЬКА, БУДИНОК 244 (юридична адреса співпадає з фактичною)`,
      `vitaisland.com.ua{dog}gmail.com`,
      `+380 (95) 927 11 63`,
    ],
  },
  policy: {
    title: "Політика конфіденційності",
    subtitle: [
      `Політика щодо обробки персональних даних сайту ${url}`,
      `Збір та використання персональних даних`,
      `Зберігання, зміна та видалення даних`,
      `Використання технічних даних під час відвідування сайту`,
      `Надання інформації дітьми`,
      `Використання LocalStorage`,
      `Використання персональних даних іншими сервісами`,
      `Посилання на інші сайти`,
      `Зміни у політиці конфіденційності`,
      `Зворотній зв'язок, заключні положення`,
    ],
    text: [
      `Адміністрація сайту ${url} зобов'язується зберігати вашу конфіденційність в Інтернеті. Ми приділяємо велике значення охороні наданих вами даних. Наша політика конфіденційності ґрунтується на вимогах Загального регламенту захисту персональних даних Європейського Союзу (GDPR). З якими цілями ми збираємо персональні данні: покращення роботи нашого сервісу, здійснення контакту з відвідувачами даного сайту, надання інформації, яку запитував користувач, а також для наведених нижче дій.`,

      `Ми збираємо та використовуємо ваші персональні дані лише у випадку вашої добровільної згоди. За згодою з цим ви дозволяєте нам збирати та використовувати такі дані: ім'я та прізвище, електронна пошта, номер телефону. Збір та обробка ваших даних проводиться відповідно до законів, що діють на території Європейського Союзу та в Україні.`,

      `Користувач, який надав свої персональні дані сайту ${url}, має право на їх зміну та видалення, а також на відкликання своєї згоди з їх використанням. Термін, протягом якого зберігатимуться ваші персональні дані: час, необхідний для використання даних для основної діяльності сайту. При завершенні використання даних адміністрація сайту видаляє їх. Для доступу до ваших персональних даних ви можете зв'язатися з адміністрацією сайту ${url}. Ми можемо передавати ваші особисті дані третій стороні тільки з вашої добровільної згоди, якщо вони були передані, то зміни даних в інших організаціях, не пов'язаних з нами, не здійснюється.`,

      `При відвідуванні вами сайту ${url} в базі даних зберігаються записи про вашу IP адресу, час відвідування, налаштування браузера, операційну систему, а також інша технічна інформація необхідна для коректного відображення вмісту сайту. За цими даними нам неможливо ідентифікувати особу відвідувача.`,

      `Якщо ви є батьком або опікуном, і ви знаєте, що ваші діти надали нам свої особисті дані без вашої згоди, зв'яжіться з нами: ${url}{dog}gmail.com. На нашому сервісі заборонено залишати особисті дані неповнолітніх без згоди батьків чи опікунів.`,

      `Для коректного відображення вмісту та зручності використання сайту ${url} ми використовуємо LocalStorage файли. Це невеликі файли, які зберігаються на вашому пристрої. Вони допомагають сайту запам'ятати інформацію про вас, наприклад, якою мовою ви переглядаєте сайт і які сторінки ви вже відкривали, ця інформація буде корисна при наступному відвідуванні. Завдяки файлам LocalStorage перегляд сайту стає значно зручнішим. Ви можете налаштувати прийом або блокування LocalStorage у браузері самостійно. Неможливість приймати LocalStorage може обмежити працездатність сайту.`,

      `На цьому сайті використовуються сторонні інтернет-сервіси, які здійснюють незалежний від нас збір інформації. Зібрані ними дані можуть надаватися іншим службам усередині цих організацій, вони можуть використовувати дані для персоналізації реклами своєї рекламної мережі. Ви можете прочитати угоди цих організацій на їхніх сайтах. Там же ви можете відмовитися від збору ними персональних даних. Ми не передаємо персональні дані іншим організаціям та службам, не зазначеним у цій політиці конфіденційності. Виняток становить лише передача інформації за законних вимог державних органів уповноважених здійснювати дані дії.`,

      `Сайт ${url} може містити посилання на інші сайти, які не керуються нами. Ми не відповідаємо за їх зміст. Ми рекомендуємо вам ознайомитися з політикою конфіденційності кожного сайту, який ви відвідуєте, якщо вона є.`,

      `Наш сайт ${url} може оновлювати нашу політику конфіденційності час від часу. Ми повідомляємо про будь-які зміни, розмістивши нову політику конфіденційності на цій сторінці. Ми відстежуємо зміни законодавства щодо персональних даних у Європейському Союзі та в державі Україна. Якщо ви залишили персональні дані у нас, то ми повідомимо вас про зміну політики конфіденційності. Якщо ваші персональні дані були введені не коректно, ми не зможемо з вами зв'язатися.`,

      `Зв'язатися з адміністрацією сайту ${url} з питань, пов'язаних із політикою конфіденційності, можна за адресою: ${url}{dog}gmail.com, або за допомогою контактної форми, зазначеної у відповідному розділі даного сайту. Якщо ви не погоджуєтесь з даною політикою конфіденційності, ви не можете користуватися послугами сайту ${url}, у цьому випадку ви повинні утриматися від відвідування нашого сайту.`,
    ],
  },
  refund: {
    title: "Політика повернення грошових коштів",

    text: [
      `Якщо ви незадоволені своїм замовленням, ви маєте право на повернення товару протягом 14 днів з дня отримання товару, згідно з Законом України "Про захист прав споживачів". Зверніть увагу, що витрати на повернення товару не компенсуються.`,
      `Щоб отримати повернення, товар має бути у незмінному стані, тобто не бути відкритим, не мати ознак використання та зберігати свою торговельну й споживчу цінність.`,
      `У разі повернення товару, ми проводимо повернення грошей протягом 14 днів з дати, коли ми отримали товар назад. Кошти повертаються таким самим способом, яким були оплачені товари.`,
      `Якщо ви виявили дефект товару, ви маєте право подати претензію. Для цього вам необхідно зв'язатися з нами протягом 2-х місяців з моменту виявлення дефекту, але не пізніше 2-х років з дати придбання товару.`,
      `Всі претензії будуть розглянуті відповідно до Закону України "Про захист прав споживачів".`,
    ],
  },
  terms: {
    title: `Порядок і терміни надання товару`,
    subtitle: [
      `Порядок і терміни надання товару`,
      `Способи оплати`,
      `Онлайн оплата через систему Way for Pay`,
      `Умови доставки`,
    ],
    text: [
      `Після отримання Вашого замовлення, ми розпочинаємо процес упаковки та доставки. Всі замовлення обробляються та відправляються протягом 1-3 робочих днів.`,
      `Накладений платіж: При виборі цього способу оплати, Ви матимете можливість сплатити вартість товару та доставку безпосередньо при отриманні в відділенні Нової пошти.`,
      `Ви також можете використовувати Way for Pay для онлайн-оплати замовлення. Way for Pay дозволяє використовувати різні методи оплати, включаючи банківські картки і інтернет-банкінг. Оплата через Way for Pay захищена передовими методами шифрування.`,
      `Для доставки товару ми користуємося послугами Нової пошти. Вартість доставки розраховується індивідуально для кожного замовлення і залежить від ваги та розмірів пакунка, а також від відстані доставки. Після відправки товару ми надаємо Вам номер накладної, за допомогою якого Ви можете відстежувати стан Вашого замовлення.`,
    ],
  },
};
