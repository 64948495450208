import { getDeviceInfo, addDataLayer } from "@/utils/utils";
export default {
  SHOW_LOADER: (state) => {
    state.is_loader_active = true;
  },
  HIDE_LOADER: (state) => {
    state.is_loader_active = false;
  },
  SET_LOCALE: (state, lang) => {
    state.locale = lang;
    document.documentElement.lang = lang !== "ua" ? lang : "uk";
  },
  SET_DEVICE_INFO: (state) => {
    state.device_info = getDeviceInfo();
  },
  SET_INST_VIDEOS: (state, media) => {
    state.inst_videos = media;
  },
  SET_GENDER: (state, boolean) => {
    state.isFemale = boolean;
  },
  // ! Cart
  ADD_TO_CART_FROM_STORAGE: (state, data) => {
    state.cart = data;
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },
  ADD_TO_CART: (state, data) => {
    if (data.productId == 11 && !state.isFemale) data.productId = "35";
    let index = state.cart.findIndex((item) => item.productId == data.productId);
    data.quantity = data.quantity || 1;
    if (index == -1) {
      state.cart.push(data);
    } else {
      state.cart[index].quantity += data.quantity;
    }
    state.is_preorder_popup_visible = true;
    addDataLayer([data], "add_to_cart");
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },
  DELETE_ITEM_FROM_CART: (state, data) => {
    let index = state.cart.findIndex((item) => item.productId == data.productId);
    if (index != -1) {
      state.cart.splice(index, 1);
    }
    if (!state.cart.length) state.is_preorder_popup_visible = false;
    addDataLayer([data], "remove_from_cart");
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },
  DECREMENT_CART_ITEM: (state, data) => {
    let index = state.cart.findIndex((item) => item.productId == data.productId);
    if (index != -1) {
      state.cart[index].quantity == 1 ? false : state.cart[index].quantity--;
    }
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },
  INCREMENT_CART_ITEM: (state, data) => {
    let index = state.cart.findIndex((item) => item.productId == data.productId);
    if (index != -1) {
      state.cart[index].quantity++;
    }
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },
  SHOW_CART: (state) => {
    state.is_cart_visible = true;
  },
  CLOSE_CART: (state) => {
    state.is_cart_visible = false;
  },
  RESET_CART: (state) => {
    state.cart = [];
    localStorage.removeItem("cart");
  },
  // ! Cart

  SHOW_PREORDER_POPUP: (state) => {
    state.is_preorder_popup_visible = true;
  },
  CLOSE_PREORDER_POPUP: (state) => {
    state.is_preorder_popup_visible = false;
  },
  SHOW_ORDER_POPUP: (state) => {
    state.is_order_popup_visible = true;
  },
  CLOSE_ORDER_POPUP: (state) => {
    state.is_order_popup_visible = false;
  },
  SHOW_ONE_CLICK_ORDER_POPUP: (state) => {
    state.is_one_click_order_popup_visible = true;
  },
  CLOSE_ONE_CLICK_ORDER_POPUP: (state) => {
    state.is_one_click_order_popup_visible = false;
  },

  LOGIN: (state, token) => {
    state.token_a = token;
    state.is_auth = true;
    localStorage.setItem("token_a", JSON.stringify(token));
  },
  LOGOUT: (state) => {
    state.token_a = "";
    state.is_auth = false;
    localStorage.removeItem("token_a");
  },
};
