export default {
  // Служебные данные
  API(state) {
    return state.api;
  },
  LOCALE(state) {
    return state.locale;
  },
  DEVICE_INFO(state) {
    return state.device_info;
  },
  IS_SAFARI(state) {
    return state.is_safari;
  },
  IS_LOADER_ACTIVE(state) {
    return state.is_loader_active;
  },
  IS_HEADER_ACTIVE(state) {
    return state.is_header_active;
  },
  IS_CART_ACTIVE(state) {
    return state.is_cart_active;
  },
  SUPPORTED_LANGUAGES(state) {
    return state.supported_languages;
  },

  // Пользователь
  IS_FEMALE(state) {
    return state.isFemale;
  },
  PRODUCTS_USER(state) {
    return state.products_user;
  },
  CATEGORIES_USER(state) {
    return state.categories_user;
  },
  PROMOCODE(state) {
    return state.promocode;
  },
  CART(state) {
    return state.cart;
  },
  IS_CART_VISIBLE(state) {
    return state.is_cart_visible;
  },
  IS_PREORDER_POPUP_VISIBLE(state) {
    return state.is_preorder_popup_visible;
  },
  IS_ORDER_POPUP_VISIBLE(state) {
    return state.is_order_popup_visible;
  },
  IS_ONE_CLICK_ORDER_POPUP_VISIBLE(state) {
    return state.is_one_click_order_popup_visible;
  },
  INST_VIDEOS(state) {
    return state.inst_videos;
  },

  // Админка
  IS_AUTH(state) {
    return state.is_auth;
  },
  TOKEN_A(state) {
    return state.token_a;
  },
  PRODUCTS_ADMIN(state) {
    return state.products_admin;
  },
  CATEGORIES_ADMIN(state) {
    return state.categories_admin;
  },
  PROMOCODES(state) {
    return state.promocodes;
  },

  // Admin statistics
  SELLERS(state) {
    return state.sellers;
  },
  ID_NN(state) {
    return state.id_nn;
  },
  PROMO(state) {
    return state.promo;
  },
  UTM_SOURCE(state) {
    return state.utm_source;
  },
  UTM_MEDIUM(state) {
    return state.utm_medium;
  },
  UTM_CAMPAIGN(state) {
    return state.utm_campaign;
  },
  UTM_CONTENT(state) {
    return state.utm_content;
  },
  UTM_TERM(state) {
    return state.utm_term;
  },
  ADSET(state) {
    return state.adset;
  },
};
