import info_text from "./info/ua.js";
import pagesContent from "./pages/ua.js";

export default {
  first_order_discount: `Знижка на перше замовлення -20%`,
  ...info_text,
  male: "для чоловіків",
  female: "для жінок",
  page: {
    ...pagesContent,
  },
  inst: {
    id18062962834539278: `Готуємо разом з Vita Island: рецепт хелсі йогурта з колагеном `,
    id18068459143463032: `Нарешті обрала для себе колаген: чому саме Vita Island?`,
    id17952628628786742: `Як турбуватися про стан шкіри, волосся та нігтів зсередини?`,
    id17914321199825176: `Моя шкіра почала сяяти: у чому секрет?`,
    id18086747317432131: `Морський колаген Vita Island: варіант вживання`,
    id18069853006477972: `Ранок моделі: корисні звички для ідеального початку дня`,
  },
  captcha: {
    title: `Введіть останні 4 цифри номеру телефону з якого Вам телефонують`,
    1: `***-***-__-__`,
    2: `Зателефонувати ще раз`,
    3: `сек`,
    4: `Замовити`,
    5: `Помилка верифікації номеру.`,
    6: `Спробувати ще раз?`,
  },
  errors: {
    phone: "Введіть номер у вказаному форматі: +38 (063) 000-00-00",
    email: "Введіть імейл у вказаному форматі: name{dog}email.com",
  },
  productPreview: {
    enabled: `В наявності`,
    discount: `Акція`,
    packs: `пакування`,
    kit: `набір`,
    kits: `набори`,
    order: `Замовити`,
    capsules: {
      amount: "30",
      text: "капсул",
      by: "по",
      mg: "мг",
      4: "700",
      5: "700",
      6: "1400",
      7: "700",
      8: "400",
      9: "400",
    },
    sticks: {
      amount: "20",
      text: "стіків",
      by: "по",
      mg: "г",
      1: "15",
    },
  },
  orderingView: {
    1: `Усього`,
    2: `Сума`,
    3: `Знижка`,
    4: `Усього`,
    5: `Інформація по доставці`,
    6: `Ім'я`,
    7: `Ваше ім'я`,
    8: `Прізвище`,
    9: `Ваше прізвище`,
    10: `Телефон`,
    11: `Ваш номер телефону`,
    12: `Імейл`,
    13: `Ваш імейл`,
    14: `Область`,
    15: `Оберіть область`,
    16: `Місто`,
    17: `Оберіть місто`,
    18: `Відділення`,
    19: `Оберіть відділення`,
    20: `Сплатити зараз`,
    21: `Накладений платіж`,
    22: `Зробити замовлення`,
  },
  popups: {
    1: `Товар успішно додано до кошика`,
    2: `Продовжити покупки`,
    3: `Або замовте зараз`,
    4: `Оформити замовлення`,
  },
  orderingBlock: {
    1: `Введіть будь ласка, своє ім'я та номер телефону, і ми зв'яжемося з Вами за декілька хвилин`,
    2: `Ім'я`,
    3: `Ваше ім'я`,
    4: `Телефон`,
    5: `Ваш номер телефону`,
    6: `Замовити`,
    7: `Натискаючи кнопку, я погоджуюся з політикою обробки персональних даних`,
    8: `Промокод (за наявності)`,
    9: `Номер промокоду`,
  },
  mobileMenu: {
    1: `Каталог`,
    2: `Інформація`,
    3: `Всі права захищені`,
    4: `Усі товари`,
    5: `Головна`,
    6: `Політика конфіденційності`,
    7: `Правила та умови`,
    8: `Політика повернення грошових коштів`,
    9: `Контакти`,
    10: `Обмежена пропозиція %`,
    kids: `Для дітей`,
    beauty: `Краса`,
  },
  userCart: {
    1: `Кошик`,
    2: `Ваш кошик порожній`,
    3: `Оформити`,
  },
  userFooter: {
    catalog: `Каталог товарів`,
    info: `Інформація`,
    date_1: `Пн-Нд: 09:00 - 18:00`,
    copy: `© 2023 VitaIsland.com.ua / Всі права захищені`,
    //  4: `Контакти`,
    //  5: `Політика конфіденційності`,
    //  6: `Правила та умови`,
    //  7: `Політика повернення грошових коштів`,
  },
  userHeader: {
    1: `Головна`,
    2: `Усі товари`,
  },
  customNavigation: {
    1: `Показати більше`,
    2: `Показати менше`,
  },
  error: {
    1: `Сторінка не знайдена`,
    2: `На жаль, сторінка, яку ви запитували, застаріла або не існує`,
    3: `На головну`,
  },
  thanks: {
    1: `Дякуємо`,
    2: `Ваше замовлення успішно оформлено.`,
    3: `Наш менеджер зв'яжеться з Вами найближчим часом.`,
    4: `До каталогу`,
  },
  main: {
    1: `джерело твоєї`,
    2: `бездоганності`,
    3: `До каталогу`,
    advantages: {
      1: `Безкоштовна доставка від 2000 UAH`,
      2: `Різні способи оплати`,
      3: `Швидка доставка`,
      4: `Знижки постійним клієнтам`,
    },
    8: `Хіт продажу`,
    9: `ТОП-5`,
    10: `Популярні товари`,
    11: `Категорії`,
    categories: {
      1: `Мультивітаміни`,
      2: `Колаген`,
      3: `Риб'ячий жир`,
      4: `Для дітей`,
      5: `Краса`,
      6: `Комплекси`,
    },
    12: `Наші партнери`,
    13: `Дізнайтеся, що кажуть задоволені клієнти VitaIsland`,
    14: `Розгорнути`,
    15: `Згорнути`,
    16: `asd`,
    17: `asd`,
    18: `asd`,
    19: `asd`,
    20: `asd`,
    21: `asd`,
  },
  userReviews: {
    1: {
      name: `Данил`,
      text: [
        `"Вітаміни від Vita Island стали невід'ємною частиною мого щоденного режиму. Після кількох тижнів вживання відчув справжнє покращення енергії і загального самопочуття. Раджу їх усім, хто прагне підтримувати своє здоров'я на високому рівні!"`,
        //
      ],
    },
    2: {
      name: `Юра`,
      text: [
        `"Завдяки вітамінам від Vita Island мій імунітет став сильнішим, і я рідше хворію. Це дійсно ефективний продукт, який я рекомендую всім своїм друзям і родичам. Дякую, Vita Island, за такі чудові вітаміни!"`,
        //
      ],
    },
    3: {
      name: `Таня`,
      text: [
        `"Неодноразово перевірив ефективність вітамінів від Vita Island і залишилася задоволеною кожного разу. Вони допомагають мені відчувати себе більш енергійною протягом дня. Це відмінний спосіб підтримувати своє здоров'я!"`,
        //
      ],
    },
    4: {
      name: `Володимир`,
      text: [
        `"Вітаміни від Vita Island - це дійсно якісний продукт. Я вживаю їх протягом декількох місяців і помітив значне поліпшення у своєму здоров'ї. За таку доступну ціну - це справжня знахідка!"`,
        //
      ],
    },
    5: {
      name: `Саша`,
      text: [
        `"Після рекомендації від друга, я вирішив спробувати вітаміни від Vita Island, і не пошкодував про свій вибір. Вони дійсно допомагають мені відчувати себе краще і підтримувати енергію протягом усього дня. Планую продовжувати їх вживати і надалі!"`,
        //
      ],
    },
    6: {
      name: `Даша`,
      text: [
        `"Після початку вживання вітамінів від Vita Island, моє здоров'я стало краще, а енергія - більш стійкою. Це дійсно продукт високої якості, який рекомендую всім, хто прагне підтримувати своє здоров'я."`,
        //
      ],
    },
    7: {
      name: `Яна`,
      text: [
        `"Вітаміни від Vita Island стали для мене справжньою знахідкою. Після їх вживання я відчуваю себе більш енергійною і зосередженою, а також помітила покращення у своєму здоров'ї. Це точно те, що мені потрібно!"`,
        //
      ],
    },
    8: {
      name: `Анна`,
      text: [
        `"Мої діти і я вживаємо вітаміни від Vita Island вже протягом кількох місяців, і ми дуже задоволені результатом. Вони допомагають нам залишатися здоровими і енергійними. Дякуємо за такий чудовий продукт!"`,
        //
      ],
    },
    9: {
      name: `Віка`,
      text: [
        `"Після вживання вітамінів від Vita Island, я відчуваю, що моє здоров'я стало значно краще. Якість продукту вражає, і я планую продовжувати його вживати в майбутньому. Рекомендую!"`,
        //
      ],
    },
    10: {
      name: `Марина`,
      text: [
        `"Завдяки вітамінам від Vita Island я відчуваю себе більш здоровою і енергійною кожного дня. Це дійсно прекрасний продукт, який допомагає підтримувати моє здоров'я на високому рівні. Дякую, Vita Island, за таку чудову можливість!"`,
        //
      ],
    },
  },
};
