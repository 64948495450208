import { createI18n } from "vue-i18n";
import { getLocale } from "@/utils/utils.js";
import ua from "./ua.js";
import en from "./en.js";
import ru from "./ru.js";
const i18n = createI18n({
  locale: getLocale(), // Установите язык по умолчанию
  messages: {
    ua: ua,
    en: en,
    ru: ru,
  },
  legacy: false,
  globalInjection: true,
});

export default i18n;
