import { createStore } from "vuex";
import { getLocale, getDeviceInfo, isSafari } from "@/utils/utils";

// Actions
import commonActions from "@/store/actions/actions";
import actionsApiRequests from "@/store/actions/api-requests";
const actions = {
  ...commonActions,
  ...actionsApiRequests,
};

// Mutations
import commonMutations from "@/store/mutations/mutations";
import mutationsApiRequests from "@/store/mutations/api-requests";
const mutations = {
  ...commonMutations,
  ...mutationsApiRequests,
};
// Getters
import getters from "@/store/getters/getters";

import supported_languages from "@/langs";
// const cartItem = {
//   productId: "1",
//   category: "2",
//   categoryName: "Колаген",
//   article: "1001",
//   productType: "2",
//   title: "Колаген",
//   label: "Покращує стан шкіри, волосся, нігтів і суглобів. Відчутне покращення вже у перші 7 днів",
//   fullPrice: "1263",
//   discountAmount: ["0", "5", "10"],
//   recommended: "0",
//   quantity: 3,
// };

export default createStore({
  state: {
    // Служебные данные
    api: `https://api.vitaisland.com.ua`,
    //  api: `https://tyrilui.fun/`,
    locale: getLocale(),
    device_info: getDeviceInfo(),
    is_safari: isSafari(),
    is_loader_active: false,
    is_header_active: false,
    is_cart_active: false,
    supported_languages: supported_languages,

    // Пользователь

    isFemale: true,
    //  products_user: [],
    //  categories_user: [],
    products_user: [
      {
        productId: "1",
        category: "2",
        categoryName: "Колаген",
        article: "1001",
        productType: "2",
        title: "Колаген",
        label: "Покращує стан шкіри, волосся, нігтів і суглобів. Відчутне покращення вже у перші 7 днів",
        fullPrice: "9999",
        discountAmount: ["0", "0", "0"],
        recommended: "0",
      },
      {
        productId: "4",
        category: "3",
        categoryName: "Вітамін D3",
        article: "2001",
        productType: "1",
        title: "D3 + K2",
        label: "Cприяє зміцненню кісток, імунній функції та засвоєнню кальцію",
        fullPrice: "9999",
        discountAmount: ["0", "0", "0"],
        recommended: "0",
      },
      {
        productId: "5",
        category: "3",
        categoryName: "Вітамін D3",
        article: "2002",
        productType: "1",
        title: "D3 + K2 (дитячий)",
        label: "Підтримує ріст, здоров'я кісток, настрій і імунітет",
        fullPrice: "9999",
        discountAmount: ["0", "0", "0"],
        recommended: "0",
      },
      {
        productId: "6",
        category: "1",
        categoryName: "Омега-3",
        article: "3001",
        productType: "1",
        title: "Омега-3",
        label: "Підтримує здоров'я серця, мозку та суглобів. Відчутне покращення вже у перші 7 днів ",
        fullPrice: "9999",
        discountAmount: ["0", "0", "0"],
        recommended: "0",
      },
      {
        productId: "7",
        category: "1",
        categoryName: "Омега-3",
        article: "3002",
        productType: "1",
        title: "Омега-3 (дитячий)",
        label: "Підтримує розвиток мозку, когнітивні функції та здоров’я імунітету",
        fullPrice: "9999",
        discountAmount: ["0", "0", "0"],
        recommended: "0",
      },
      {
        productId: "8",
        category: "5",
        categoryName: "Вітамінний комплекс",
        article: "4001",
        productType: "1",
        title: "Мультивітамін для чоловіків",
        label: "Підтримує енергію, імунітет, метаболізм і роботу м'язів",
        fullPrice: "9999",
        discountAmount: ["0", "0", "0"],
        recommended: "0",
      },
      {
        productId: "9",
        category: "5",
        categoryName: "Вітамінний комплекс",
        article: "4002",
        productType: "1",
        title: "Мультивітамін для жінок",
        label: "Підтримує енергію, імунітет, метаболізм і роботу м'язів",
        fullPrice: "9999",
        discountAmount: ["0", "0", "0"],
        recommended: "0",
      },
      {
        productId: "10",
        category: "4",
        categoryName: "Набори",
        article: "5001",
        productType: "3",
        title: "Комлекс для волосся та шкіри",
        label: "Зміцнення волосся, нігтів і тонусу шкіри",
        fullPrice: "9999",
        discountAmount: ["0", "0", "0"],
        recommended: "0",
      },
      {
        productId: "11",
        category: "4",
        categoryName: "Набори",
        article: "5002",
        productType: "3",
        title: "Комлекс підтримки імунітету",
        label: "Покращення стійкості проти хвороб і вірусів",
        fullPrice: "9999",
        discountAmount: ["0", "0", "0"],
        recommended: "0",
      },
      {
        productId: "12",
        category: "4",
        categoryName: "Набори",
        article: "5003",
        productType: "3",
        title: "Набір сімейний",
        label: "Підтримка енергії, імунітету, здоров'я волосся, нігтів і шкіри",
        fullPrice: "9999",
        discountAmount: ["0", "0", "0"],
        recommended: "0",
      },
    ],
    categories_user: [
      {
        id: "1",
        name: "Омега-3",
        name_en: "Omega-3",
      },
      {
        id: "2",
        name: "Колаген",
        name_en: "Collagen",
      },
      {
        id: "3",
        name: "Вітамін D3",
        name_en: "Vitamin D3",
      },
      {
        id: "4",
        name: "Набори",
        name_en: "Kits",
      },
      {
        id: "5",
        name: "Вітамінний комплекс",
        name_en: "Vitamin complex",
      },
    ],
    promocode: {},
    cart: [],
    is_cart_visible: false,
    is_preorder_popup_visible: false,
    is_order_popup_visible: false,
    is_one_click_order_popup_visible: false,
    // Админка
    is_auth: false,
    token_a: "",
    products_admin: [],
    categories_admin: [],
    promocodes: [],
    inst_videos: [],

    // Admin statistics
    sellers: [],
    id_nn: [],
    promo: [],
    utm_source: [],
    utm_medium: [],
    utm_campaign: [],
    utm_content: [],
    utm_term: [],
    adset: [],
  },
  getters,
  mutations,
  actions,
  modules: {},
});
