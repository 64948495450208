<template>
  <div class="lang-select">
    <div
      class="lang-select__head"
      @click="isSelectOpened = !isSelectOpened"
    >
      <p>
        {{ LOCALE == "ru" ? "Рус" : LOCALE == "en" ? "Eng" : "Укр" }}
      </p>
      <img
        :src="require(`../../assets/img/icons/arrow-down.svg`)"
        alt="arrow"
        :class="{ opened: isSelectOpened }"
      />
    </div>
    <div
      class="lang-select__body"
      v-show="isSelectOpened"
    >
      <router-link
        class="lang-select__item"
        v-for="lang in SUPPORTED_LANGUAGES"
        :key="lang"
        :to="localeLink(lang)"
        @click.prevent="changeLocale(lang)"
        :class="{ active: lang == LOCALE }"
      >
        {{ lang == "ru" ? "Рус" : lang == "en" ? "Eng" : "Укр" }}
      </router-link>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, ref, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
export default {
  name: "LangSelect",
  props: {},
  setup() {
    const store = useStore();
    const route = useRoute();
    const { locale } = useI18n();
    const LOCALE = computed(() => store.getters.LOCALE);
    const SUPPORTED_LANGUAGES = computed(() => store.getters.SUPPORTED_LANGUAGES);
    const isSelectOpened = ref(false);
    const changeLocale = (lang) => {
      if (LOCALE.value !== lang) {
        store.commit("SET_LOCALE", lang);
        locale.value = lang;
        isSelectOpened.value = false;
      }
    };
    const handleClickOutside = (e) => {
      if (!e.target.closest(`.lang-select__head`) && !e.target.closest(`.lang-select__body`)) {
        isSelectOpened.value = false;
      }
    };
    const localeLink = (lang) => ({
      name: route.name,
      query: {
        ...route.query,
      },
      params: { locale: lang },
    });
    onMounted(() => {
      if (document.querySelector(".lang-select")) document.addEventListener("click", handleClickOutside);
    });
    onBeforeUnmount(() => {
      if (document.querySelector(".lang-select")) document.removeEventListener("click", handleClickOutside);
    });
    return {
      LOCALE,
      isSelectOpened,
      changeLocale,
      localeLink,
      SUPPORTED_LANGUAGES,
    };
  },
};
</script>
<style lang="scss">
.lang-select {
  //   background-color: grey;
  position: relative;
  // .lang-select__head

  &__head {
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 5px;
    padding: 5px;
    p {
      font-variation-settings: "wght" 600;
    }
    img {
      width: 14px;
      height: auto;
      &.opened {
        transform: rotate(180deg);
      }
    }
  }

  // .lang-select__image

  &__image {
  }

  // .lang-select__body

  &__body {
    padding: 5px;
    position: absolute;
    z-index: 1;
    //  top: calc(100% + 5px);
    width: 100%;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    border-radius: 10px;
    @extend %box-shadow-small;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
  }

  // .lang-select__item

  &__item {
    user-select: none;
    text-align: center;
    padding: 6px;
    &.active {
      user-select: none;
      color: $mainColor;
    }
  }
}
</style>
