<template>
  <!-- <OrderPopup v-if="IS_ORDER_POPUP_VISIBLE && CART.length" /> -->
  <div
    class="cart"
    @click="handleClickOutside"
  >
    <div class="cart__wrapper">
      <div class="cart__body">
        <div class="cart__head">
          <p class="cart__title">{{ $t("userCart.1") }}</p>
          <img
            loading="lazy"
            @click="closeCart"
            :src="require(`../assets/img/icons/close.svg`)"
            alt="close"
          />
        </div>
        <div
          class="cart__list-wrapper"
          v-if="CART.length"
        >
          <ul class="cart__list">
            <CartItem
              v-for="item in CART"
              :key="item.productId"
              :props_data="item"
            />
          </ul>
        </div>
        <div
          class="cart__empty"
          v-else
        >
          {{ $t("userCart.2") }}
        </div>
        <div
          class="cart__bottom"
          v-if="CART.length"
        >
          <!-- <UserButton
            class="cart__button"
            @click="showOrderPopup"
          >
            <span>{{ $t("userCart.3") }}</span>
            <span
              class="cart__button-discount"
              v-if="totalDiscountPrice != totalFullPrice"
            >
              {{ totalDiscountPrice }} ₴
            </span>
            <span
              class="cart__button-full"
              :class="{ old: totalDiscountPrice != totalFullPrice }"
            >
              {{ totalFullPrice }} ₴
            </span>
          </UserButton> -->
          <router-link
            class="cart__button"
            :to="orderingLink"
            @click="closeCart"
          >
            <span>{{ $t("userCart.3") }}</span>
            <span
              class="cart__button-discount"
              v-if="totalDiscountPrice != totalFullPrice"
            >
              {{ totalDiscountPrice }} ₴
            </span>
            <span
              class="cart__button-full"
              :class="{ old: totalDiscountPrice != totalFullPrice }"
            >
              {{ totalFullPrice }} ₴
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, onUpdated } from "vue";
import { useRoute } from "vue-router";
import CartItem from "@/components/CartItem";
// import UserButton from "@/components/UI/UserButton.vue";
// import OrderPopup from "@/components/popups/OrderPopup";
import { calculateTotalFullPrice, calculateTotalDiscountPrice, queryParams } from "@/utils/utils";
export default {
  name: "UserCart",
  props: {},
  components: {
    CartItem,
    // UserButton,
    //  OrderPopup,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const LOCALE = computed(() => store.getters.LOCALE);
    const CART = computed(() => store.getters.CART);
    //  const IS_ORDER_POPUP_VISIBLE = computed(() => store.getters.IS_ORDER_POPUP_VISIBLE);

    const handleClickOutside = (e) => {
      if (!e.target.closest(".cart__body")) closeCart();
    };
    //  const showOrderPopup = () => {
    //    store.commit("SHOW_ORDER_POPUP");
    //  };
    const closeCart = () => {
      store.commit("CLOSE_CART");
    };
    const orderingLink = computed(() => ({
      name: "ordering",
      query: {
        ...queryParams(route.query, ["gender", "category", "order_id", "affiliation", "page"]),
      },
      params: { locale: LOCALE.value },
    }));

    const totalFullPrice = computed(() => calculateTotalFullPrice(CART.value));
    const totalDiscountPrice = computed(() => calculateTotalDiscountPrice(CART.value));
    onUpdated(() => {
      if (!CART.value.length) {
        setTimeout(() => {
          closeCart();
        }, 1000);
      }
    });
    return {
      CART,
      closeCart,
      handleClickOutside,
      // showOrderPopup,
      // IS_ORDER_POPUP_VISIBLE,
      totalFullPrice,
      totalDiscountPrice,
      orderingLink,
    };
  },
};
</script>
<style lang="scss">
.cart {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(1.5px);
  // .cart__wrapper

  &__wrapper {
    margin-left: auto;
    width: 100%;
    min-width: 250px;
    max-width: 550px;
    height: 100%;
  }

  // .cart__body

  &__body {
    position: relative;
    background-color: #fff;
    @media (max-width: 767px) {
      max-height: 60vh;
      //  min-height: 40vh;
      //  height: 100%;
    }
    @media (min-width: 768px) {
      max-height: 100vh;
      height: 100%;
    }
    display: grid;
    grid-template-rows: auto 1fr auto;
    > div {
      padding: 20px;
    }
    @extend %box-shadow-small;
  }

  // .cart__head

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    border-bottom: 1px solid $grey;
    img {
      width: 24px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  // .cart__title

  &__title {
    font-size: 1.4rem;
    font-variation-settings: "wght" 700;
  }

  // .cart__list-wrapper

  &__list-wrapper {
    max-height: 100%;
    overflow-y: auto;
    @extend %scroll;
    margin-right: 20px;
  }

  // .cart__list

  &__list {
  }

  // .cart__empty

  &__empty {
    font-family: $ff2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // .cart__bottom

  &__bottom {
    border-top: 1px solid $grey;
  }
  // юcart__button

  &__button {
    white-space: nowrap;
    cursor: pointer;
    padding: 12px 24px;
    border-radius: 100px;
    font-family: $ff1;

    @include fz(16);
    @media (min-width: 768px) {
      @include fz(18);
    }
    @media (min-width: 990px) {
      @include fz(20);
    }
    @media (min-width: 1024px) {
      @include fz(22);
    }
    @media (min-width: 1400px) {
      @include fz(24);
    }
    line-height: 1;
    font-variation-settings: "wght" 700;
    @extend %box-shadow-small;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: $mainColor;
    transition: all 0.3s ease;
    &.blue {
      background-color: $blue;
    }
    &:hover {
      @media (min-width: 768px) {
        transform: scale(1.02);
      }
      background-color: $mainColorDarken;
      &.blue {
        background-color: $blueDarken;
      }
    }
    &:active {
      opacity: 0.8;
      @media (min-width: 768px) {
        transform: scale(1.01);
      }
    }

    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 6px;
    column-gap: 12px;
    line-height: 1;
    span {
      line-height: 1;
      &:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 12px;
        position: relative;
        &:after {
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #fff;
        }
      }
    }
    &-full {
      white-space: nowrap;
      &.old {
        //   align-self: baseline;
        opacity: 0.9;
        font-size: 0.8em;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-5deg);
          width: 100%;
          height: 2px;
          background: #fff;
        }
      }
    }
    &-discount {
      white-space: nowrap;
      font-size: 1.1em;
    }
  }
}
</style>
